<template>
    <app-layer ref="app">
        <v-row justify="center" class="mt-5">
            <v-col cols="12" md="4">
              <data-container :loading="loading" :error="error">
                <v-card outlined
                :loading="loading"
                v-if="requestEmail"
                >
                    <v-card-title>
                        <h1 class="headline">Confirm your new email</h1>
                    </v-card-title>
                    <v-card-text>
                        <div class="my-5">
                            <v-form ref="emailVerification" >
                                <v-text-field outlined v-model="email" label="Email" :rules="[rules.required, rules.email]" hide-details="auto" dense></v-text-field>
                            </v-form>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            text
                            dark color="accent-4"
                            class="primary"
                            block
                            @click="verifyEmail"
                            :loading="loading"
                        >
                            Verify
                        </v-btn>
                    </v-card-actions>
                </v-card>
                <v-card outlined
                v-if="confirmGoogleDisconnect"
                >
                <v-card-text>
                     <v-alert
                         colored-border border="lefet"
                        type="warning"
                    >
                        You are about to link the email address {{email}} to your account, 
                        The Google account with email {{ googleProvider.email }} will be disconnected
                    </v-alert>
                </v-card-text>

                <v-card-actions>
                    <v-btn
                        text
                        dark color="accent-4"
                        class="primary"
                        block
                        @click="verify"
                        :loading="loading"
                    >
                        Agree, continue
                    </v-btn>
                </v-card-actions>
                </v-card>
              </data-container>
            </v-col>
        </v-row>
    </app-layer>
</template>

<script>
    import validation from '@/helper/formValidation';
    import { fb, auth } from '@/firebase';
    import AppLayer from '@/AppLayer';

    import profile from '@/domain/User/Mixins/profile2';
    import { mapActions, mapGetters } from 'vuex';
    import DataContainer from "@/components/DataContainer";

    export default {
        name: "EmailVerification",
        mixins: [profile],

        data(){
            return {
                rules: validation.rules,
                email: '',
                loading: false,
                requestEmail: false,
                confirmGoogleDisconnect: false,
                error: null,
            }
        },
        components: {
          DataContainer,
          AppLayer
        },
        computed:{

            ...mapGetters([
                'app_ready',
                'current_user'
            ]),

            returnPath(){
                return this.$router.resolve({name: this.$route.name, query: this.$route.query }).route.fullPath
            }
        },
        methods: {
            ...mapActions([
                'query',
                'mutate'
            ]),

            verify(){

                if(!this.current_user.profile.email_confirmation) {
                    this.$store.commit("SNACKBAR", {
                        status: true,
                        text: `There is no pending email confirmation for your account`,
                        color: 'error'
                    })
                    return;
                }

                if(!this.current_user.profile || this.current_user.profile.email_confirmation != this.email)
                {
                    this.$store.commit("SNACKBAR", {
                        status: true,
                        text: `Email address does not match pending confirmation email`,
                        color: 'error'
                    })
                    this.requestEmail = true;
                    return;
                }

                this.loading = true;
               Promise.all([this.unlinkOldEmail(), this.unlinkGoogle()])
                .then(() => {
                    const credential = fb.auth.EmailAuthProvider.credentialWithLink(this.email, window.location.href);
                    return auth.currentUser.linkWithCredential(credential)
                })
                .then(() => auth.currentUser.reload() )
                .then(() => {
                    window.localStorage.removeItem('emailForConnect')
                    return this.syncProfile({ email: this.email });
                })
                 .then(() => {
                   this.$store.commit("SNACKBAR", {
                     status: true,
                     text: `Email address connected successfully`,
                     color: 'success'
                   })
                   if(this.$route.query.redirect) {
                     this.$router.push({ path: this.$route.query.redirect })
                   } else {
                     this.$router.push({  name: 'account'})
                   }
                 })
                .catch(e => {
                    this.error = e;
                })
                .finally(() => {
                    this.loading = false;
                })
            },

            unlinkOldEmail(){
                if(this.emailProvider) return auth.currentUser.unlink(this.emailProvider.providerId);
                else return Promise.resolve();
            }, 

            unlinkGoogle(){
                if(this.googleProvider) return auth.currentUser.unlink(this.googleProvider.providerId)
                else return Promise.resolve();
            },
           
            verifyEmail(){
                if(this.$refs.emailVerification.validate()){
                    if(this.googleProvider) this.confirmGoogleDisconnect = true;
                    else this.verify();
                }
            },

        },

        watch: {
            app_ready: {
                immediate: true,
                handler(ready){
                    if(!ready) return;

                    if(!auth.currentUser)
                    {

                        this.$router.push({
                            name: 'signin',
                            query: {
                                redirect: this.returnPath
                            },
                            params: {
                                alert: {
                                    type: 'info',
                                    text: 'You need to signin first with your old credentials to continue your Email verification.'
                                }
                            }
                        })
                        return;
                    }

                    if(auth.isSignInWithEmailLink(window.location.href)) {
                        this.email = window.localStorage.getItem('emailForConnect');
                        if (!this.email) {
                            this.requestEmail = true;
                        }else{
                            if(this.googleProvider) this.confirmGoogleDisconnect = true;
                            else this.verify();
                        }
                    } else{
                        this.$router.push({
                            name: 'account'
                        })
                    }
                }
            }
        }
    }
</script>


