<template>
    <app-layer ref="app" :breadcrumbs="breadcrumbs" :header-space="0">
      <template #header>
        <v-row justify="center">
          <v-col md="10">
            <div class="d-flex flex-wrap">
              <h4 class="headline">Notifications</h4>
              <property-switch
                  class="ml-md-5 mt-2 mt-md-0"
                  v-if="$store.getters.mode === 'host'" />
            </div>
          </v-col>
        </v-row>
      </template>
        <v-row justify="center">
            <v-col md="10">
              <personal-notifications v-if="$store.getters.mode === 'guest'" />
              <property-notifications v-else-if="$store.getters.mode === 'host'" />
            </v-col>
        </v-row>
    </app-layer>
</template>

<script>
import AppLayer from '@/AppLayer';
import PersonalNotifications from '../Widgets/UserNotifications.vue';
import PropertyNotifications from '../Widgets/PropertyNotifications.vue';
import PropertySwitch from '../../Property/Components/PropertySwitch';

export default {
    name: 'Notifications',
    components: {
        AppLayer, PersonalNotifications, PropertyNotifications,
        PropertySwitch
    }, 
    
    data(){
      return {}
    },
    computed:{
      breadcrumbs(){
        return [
          {
            text: "Notifications",
            to: this.$route
          }
        ]
      }
    },
}
</script>
