<template>
    <app-layer ref="app" class="white">
      <v-row justify="center" style="min-height: 50vh" class="pt-16" >
        <v-col cols="12" md="4" order-md="last">
          <div class="px-2">
            <div class="mb-16 text-center">
              <router-link to="/">
                <img src="@/assets/img/gr-logo.png" width="190" alt="Guest Registration">
              </router-link>
            </div>
            <div class="text-center mb-10">
              <h1>Let's get started</h1>
              <p class="grey--text text-subtitle-2">Get started with Guest Registration by using any of the options</p>
            </div>
            <v-alert v-if="alert"
                     colored-border border="left"
                     :type="alert.type"
            >
              {{ alert.text }}
            </v-alert>
            <google-signin v-if="authProviders.includes('google') && providers.includes('google')"
                           class="my-2"
                           :btn="{
                  depressed: true,
                  block: true,
                  outlined: true,
                  large: true,
                  class: 'justify-space-between text-transform-unset'
                }"
                           @authenticated="authenticated"
                           @error="authError"
            >
              Continue with Google
              <template #btn-append>
                <v-icon>mdi-chevron-right</v-icon>
              </template>
            </google-signin>
            <email-signin v-if="authProviders.includes('email') && providers.includes('email')"
                          class="my-2"
                          :btn="{
                  depressed: true,
                  block: true,
                  outlined: true,
                  large: true,
                  class: 'justify-space-between text-transform-unset'
                }"
                          @authenticated="authenticated"
                          @error="authError"
            >
              Continue with Email
              <template #btn-append>
                <v-icon>mdi-chevron-right</v-icon>
              </template>
            </email-signin>
            <phone-signin v-if="authProviders.includes('phone') && providers.includes('phone')"
                        class="my-2"
                        :btn="{
                  depressed: true,
                  block: true,
                  outlined: true,
                  large: true,
                  class: 'justify-space-between text-transform-unset'
                }"
                        @authenticated="authenticated"
                        @error="authError"
          >
            Continue with Phone
            <template #btn-append>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </phone-signin>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="5" order-md="first">
          <v-sheet rounded color="#F0F6FF" class="py-md-16 rounded-lg px-md-4">
            <v-sheet rounded color="#141516" class="pa-2 rounded-lg my-md-16">
              <iframe
                  src="https://player.vimeo.com/video/357612124?h=c1e58e6c18"
                  width="100%"
                  height="300"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
              ></iframe>
            </v-sheet>
          </v-sheet>
        </v-col>
      </v-row>
    </app-layer>
</template>

<script>
    import AppLayer from '@/AppLayer';
    import GoogleSignin from '../Components/GoogleSignin.vue';
    import EmailSignin from '../Components/EmailSignin.vue';
    import PhoneSignin from '../Components/PhoneSignin.vue'
    import appHelper from "@/helper/app";

    export default {
        name: 'Signin',
        components:{
           AppLayer, GoogleSignin, EmailSignin, PhoneSignin
        },
        data(){
            return {};
        },

        computed: {
            redirect(){
                return this.$route.query.redirect ? this.$route.query.redirect : '/'
            },

            redirectUrl(){
                return  this.url(this.$router.resolve({path: this.redirect}).route.fullPath)
            },

            alert(){
                return this.$route.params.alert
            },

            providers(){
                if(this.$route.query.providers) return this.$route.query.providers.split(',')
                return ['google', 'email', 'phone'];
            },

            authProviders() {
              return appHelper.getAuthProviders()
            }
        },

        methods: {
            authenticated(auth) {
                // console.log(auth);
                if(auth.user) {
                  this.$store.dispatch('getIdToken')
                      .then(() => this.$store.dispatch('getAuthUser'))
                      .then(() => {
                          window.location.replace(this.redirectUrl);
                      })
                      .catch(e => {
                        this.authError(e)
                      })
                }
                else if(auth.provider) {
                  this.$store.commit('SNACKBAR', {
                    status: true,
                    text: `Ooops! Authentication via ${auth.provider} was not successful, try again`,
                    color: 'error',
                  })
                }
            },
            authError(error) {
                console.log('Authentication error--->', error);
                this.$store.commit('SNACKBAR', {
                    status: true,
                    text: error.message, 
                    color: 'error',
                })
            }
        }
    }
</script>
<style>

</style>
