<template>
    <app-layer ref="app" :breadcrumbs="breadcrumbs" class="white" :header-space="independentTab ? 5 : 20">
      <template #header>
        <div>
          <h4 v-if="!independentTab" class="headline mb-3">
            <span>Properties</span>
            <v-chip color="primary" small class="ml-2">{{ $store.getters.current_user.profile.properties.length }}</v-chip>
          </h4>
        </div>
      </template>
      <property-switch v-if="!independentTab" />
      <v-row justify="start">
          <v-col cols="12" md="8" lg="6">
            <v-alert
              v-if="$route.params.new"
              colored-border
              class="my-2"
              type="success"
              border="left"
            >
              Your property has been created
          </v-alert>
            <template v-if="property && tabs.length">
                <responsive-tabs
                  v-model="currentTab"
                  @change="tabChanged"
                  color="primary"
                  show-arrows
                  class="rounded"
                >
                  <template #tabs v-if="$store.getters.is_mobile">
                    <v-tab
                      v-for="tab in tabs" :key="tab.name"
                     :disabled="tab.disabled"
                      class="justify-start"
                    >
                      <v-icon flex>
                        {{ tab.icon }}
                      </v-icon>
                      <span>{{ tab.name }}</span>
                    </v-tab>
                  </template>
                  <template #items>
                    <v-tab-item v-for="tab in tabs" :key="tab.name"
                    class="px-sm-5">

                        <!-- Info settings -->
                        <template v-if="tab.route.params.tab === 'info' ">
                            <data-container v-if="property" :loading="loading">
                                <template v-slot:loading>
                                    <div  v-for="i in 4" :key="i">
                                        <v-skeleton-loader
                                            type="card"
                                            height="50"
                                            class="my-2"
                                        ></v-skeleton-loader>
                                    </div>
                                </template>
                                <info-tab :property="property" @property-updated="propertyUpdated">
                                    <template #heading>
                                        <h4 class="headline ml-3">Property info</h4>
                                    </template>
                                </info-tab>
                            </data-container>
                        </template>

                        <!-- Gateway settings -->
                        <template v-if="tab.route.params.tab === 'gateway'">
                            <gateway-tab :property="property">
                                <template #heading>
                                    <h4 class="headline ml-3">Gateways</h4>
                                </template>
                            </gateway-tab>
                        </template>

                        <template v-if="tab.route.params.tab === 'integrations'">
                            <integrations-tab :property="property">
                                <template #heading>
                                    <h4 class="headline ml-3">Integrations</h4>
                                </template>
                            </integrations-tab>
                        </template>

                        <!-- Charges -->
                        <template v-if="tab.route.params.tab=== 'charges'">
                            <charges-tab :property="property">
                                <template #heading>
                                    <h4 class="headline ml-3">Charges</h4>
                                </template>
                            </charges-tab>
                        </template>

                        <!-- Users -->
                        <template v-if="tab.route.params.tab === 'users'">
                            <property-users-tab :property="property">
                                <template #heading>
                                    <h4 class="headline ml-3">Users</h4>
                                </template>
                            </property-users-tab>
                        </template>

                        <!-- Reservation -->
                        <template v-if="tab.route.params.tab === 'reservation'">
                            <property-reservation-setting-tab :property="property">
                                <template #heading>
                                    <h4 class="headline ml-3">Reservation Settings</h4>
                                </template>
                            </property-reservation-setting-tab>
                        </template>

                        <!-- Reservation Instructions -->
                        <template v-if="tab.route.params.tab === 'instructions'">
                            <checkin-instructions-tab :property="property">
                                <template #heading>
                                    <h4 class="headline ml-3">Instruction Templates</h4>
                                </template>
                            </checkin-instructions-tab>
                        </template>

                        <!-- Reservation Agreements -->
                        <template v-if="tab.route.params.tab === 'agreements'">
                            <checkin-agreements-tab :property="property">
                                <template #heading>
                                    <h4 class="headline ml-3"> Agreements</h4>
                                </template>
                            </checkin-agreements-tab>
                        </template>

                        <!-- Reservation Questions -->
                        <template v-if="tab.route.params.tab === 'questions'">
                            <checkin-questions-tab :property="property">
                                <template #heading>
                                    <h4 class="headline ml-3"> Questions</h4>
                                </template>
                            </checkin-questions-tab>
                        </template>

                        <!-- Property ROoms -->
                        <template v-if="tab.route.params.tab === 'rooms'">
                          <property-rooms-tab :property="property">
                            <template #heading>
                              <h4 class="headline ml-3"> Rooms</h4>
                            </template>
                          </property-rooms-tab>
                        </template>

                        <!-- ID Verification -->
                        <template v-if="tab.route.params.tab === 'guest-info'">
                            <property-guest-info-setting-tab :property="property">
                                <template #heading>
                                    <h4 class="headline ml-3">Guest Info</h4>
                                </template>
                            </property-guest-info-setting-tab>
                        </template>

                      <!-- Property notification setting -->
                      <template v-if="tab.route.params.tab === 'notification'">
                        <property-notification-setting-tab :property="property">
                          <template #heading>
                            <h4 class="headline ml-3"> Notifications</h4>
                          </template>
                        </property-notification-setting-tab>
                      </template>

                        <!-- Property checkin points -->
                      <template v-if="tab.route.params.tab === 'check-in-point'">
                        <property-checkin-point-tab :property="property">
                          <template #heading>
                            <h4 class="headline ml-3"> Check-in Point</h4>
                          </template>
                        </property-checkin-point-tab>
                      </template>

                    </v-tab-item>
                  </template>
                </responsive-tabs>
            </template>
            <v-alert v-else type="error" colored-border border="left">
                No setting is available
            </v-alert>
          </v-col>
      </v-row>
    </app-layer>
</template>

<script>
import AppLayer from '@/AppLayer';
import DataContainer from '../../../components/DataContainer.vue';
import PropertySwitch from '../Components/PropertySwitch.vue';
import InfoTab from '../Components/PropertyForm.vue';
import ChargesTab from '../Settings/PropertyCharges.vue';
import IntegrationsTab from '../Settings/PropertyIntegrations.vue';
import CheckinInstructionsTab from '../Settings/PropertyCheckinInstructionTemplates';
import CheckinAgreementsTab from '../Settings/PropertyCheckinAgreements';
import CheckinQuestionsTab from '../Settings/PropertyCheckinQuestions';
import PropertyUsersTab from '../Settings/PropertyUsers.vue';
import PropertyReservationSettingTab from '../Settings/PropertyReservationSetting.vue';
import PropertyGuestInfoSettingTab from '../Settings/PropertyGuestInfo.vue';
import PropertyNotificationSettingTab from '../Settings/PropertyNotification.vue';
import PropertyCheckinPointTab from "@/domain/Property/Settings/PropertyCheckinPoint";
import GET_PROPERTY from '../Queries/getProperty';
import ResponsiveTabs from "@/components/ResponsiveTabs";
import PropertyRoomsTab from "@/domain/Property/Settings/PropertyRooms.vue";

export default {
    name: 'PropertySettings',
    components: {
      PropertyRoomsTab,
      ResponsiveTabs,
        AppLayer, DataContainer, PropertySwitch, InfoTab, ChargesTab, IntegrationsTab,
        CheckinInstructionsTab, CheckinAgreementsTab, CheckinQuestionsTab,
        PropertyUsersTab, PropertyReservationSettingTab,
        PropertyGuestInfoSettingTab, PropertyNotificationSettingTab,
        PropertyCheckinPointTab
    }, 
    data(){
        return {
          loading: false,
        }
    },

    computed:{

        property() {
            return this.$store.getters.current_user.property
        },

        propertyPermissions() {
            if(!this.property) return [];
            return this.property.auth_access?.permissions || []
        },

        tabs(){
            return [
                {
                    name: 'Info',
                    route: {name: this.$route.name, params: {tab: 'info'}},
                    icon: 'mdi-domain',
                    permission: 'property.info.update'
                },
                {
                    name: 'Integrations',
                    route: {name: this.$route.name, params: {tab: 'integrations'}},
                    icon: 'mdi-cog-box',
                    permission: 'property.integration.manage'
                },
                {
                    name: 'Charges',
                    route: {name: this.$route.name, params: {tab: 'charges'}},
                    icon: 'mdi-credit-card',
                    permission: 'property.charge.manage'
                },
                {
                    name: 'Users',
                    route: {name: this.$route.name, params: {tab: 'users'}},
                    icon: 'mdi-account-group',
                    permission: 'property.user.manage'
                },
                {
                    name: 'Reservation',
                    route: {name: this.$route.name, params: {tab: 'reservation'}},
                    icon: 'mdi-cog',
                    permission: 'property.reservation.setting'
                },
                {
                    name: 'Instructions',
                    route: {name: this.$route.name, params: {tab: 'instructions'}},
                    icon: 'mdi-information',
                    permission: 'property.instruction.manage'
                },
                {
                    name: 'Agreements',
                    route: {name: this.$route.name, params: {tab: 'agreements'}},
                    icon: 'mdi-handshake',
                    permission: 'property.agreement.manage'
                },
                {
                    name: 'Questions',
                    route: {name: this.$route.name, params: {tab: 'questions'}},
                    icon: 'mdi-account-question',
                    permission: 'property.question.manage'
                },
                {
                  name: 'Rooms',
                  route: {name: this.$route.name, params: {tab: 'rooms'}},
                  icon: 'mdi-bed',
                  permission: 'property.reservation.setting'
                },
                {
                    name: 'Guest Info',
                    route: {name: this.$route.name, params: {tab: 'guest-info'}},
                    icon: 'mdi-account-check',
                    permission: 'property.reservation.setting'
                },
                {
                    name: 'Notification',
                    route: {name: this.$route.name, params: {tab: 'notification'}},
                    icon: 'mdi-bell',
                    permission: null
                },
              {
                name: 'Check-in Point',
                route: {name: this.$route.name, params: {tab: 'check-in-point'}},
                icon: 'mdi-credit-card',
                permission: 'property.subscription.manage'
              }
          ].filter(tab => tab.permission == null || this.property.is_owner || this.propertyPermissions.includes(tab.permission))
        },

        id(){
            return this.$route.params.id
        },

      tab:{
            get: function() {
                return this.$route.params.tab
                    ?  this.$route.params.tab
                    : (this.tabs.length ? this.tabs[0].route.params.tab : null);
            },
            set: function(tab){}
        },

      currentTab: {
            get: function(){
                return this.tabs.findIndex(t => t.route.params.tab == this.tab)
            },
            set: function(tab){}
        },

      breadcrumbs() {
        return [
          {
            text: "Properties",
            to: { name: 'property.list' }
          },
          {
            text: this.property.name,
            to: { name: 'property.show', params: { id: this.property.id } }
          },
          {
            text: this.tabs[this.currentTab].name,
            to: this.$route
          }
        ]
      },

      independentTab() {
          return ['check-in-point'].includes(this.tabs[this.currentTab].route.params.tab)
      }

    },
    
    methods:{

        propertyUpdated(property){
            this.$store.dispatch('syncAuthUser');
        },

        tabChanged(tab){
            if(!this.tabs[tab].route) return;
            this.$router.push(this.tabs[tab].route)
        },
    },

}
</script>
