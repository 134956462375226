<template>
  <v-expansion-panels focusable v-model="panel">
    <v-expansion-panel>
      <v-expansion-panel-header expand-icon="mdi-menu-down">
        Instructions
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pt-3">

      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>

</template>

<script>

export default {
    name: "ReservationInstructionExpansion",
    components: {},
    data(){
        return {
            panel: null
        }
    },
    props: {
        reservation: Object
    }
}
</script>