import middleware from '../../router/middleware';
import multiguard from 'vue-router-multiguard';

import ReservationCreate from './Views/Create.vue';
import ReservationList from './Views/List.vue';
import ReservationShow from './Views/Show.vue';
import ReservationGuestCheckin from './Views/GuestCheckin';
import ReservationInstruction from './Views/Instruction.vue';
import ReservationCharges from './Views/Charges.vue';
import ReservationDamages from './Views/Damages.vue';
import ReservationIdVerification from './Views/IdVerification.vue';
import ReservationGuests from './Views/Guests.vue';
import ReservationDocuments from './Views/Documents.vue';
import ReservationFormSdk from './Widgets/SdkForm.vue';

export default [
    {
        path: '/create-reservations',
        name: 'reservation.create',
        component: ReservationCreate,
        beforeEnter: multiguard([middleware.auth])
    },

    {
        path: '/reservations/:reservation?',
        name: 'reservation.list',
        component: ReservationList,
        beforeEnter: multiguard([middleware.auth])
    },
    
    {
        path: '/r/:id',
        name: 'reservation.show',
        component: ReservationShow,
        meta: {
            hasMiniAuth: true,
        }
    },
    {
        path: '/r/:id/guest',
        name: 'reservation.guest.checkin',
        component: ReservationGuestCheckin,
        meta: {
            hasMiniAuth: true,
        }
    },
    {
        path: '/r/:id/instruction',
        name: 'reservation.instruction',
        component: ReservationInstruction,
        meta: { hasMiniAuth: true }
    },
    {
        path: '/r/:id/charges',
        name: 'reservation.charges',
        component: ReservationCharges,
        meta: { hasMiniAuth: true }
    },
    {
        path: '/r/:id/damages',
        name: 'reservation.damages',
        component: ReservationDamages,
        meta: { hasMiniAuth: true }
    },
    {
        path: '/r/:id/guests',
        name: 'reservation.guests',
        component: ReservationGuests,
        meta: { hasMiniAuth: true }
    },
    {
        path: '/r/:id/id-verification',
        name: 'reservation.id-verification',
        component: ReservationIdVerification,
        meta: { hasMiniAuth: true }
    },
    {
        path: '/r/:id/documents',
        name: 'reservation.documents',
        component: ReservationDocuments,
        meta: { hasMiniAuth: true }
    },
    {
        path: '/sdk',
        name: 'reservation.sdk.form',
        component: ReservationFormSdk,
    }
]