<template>
    <div style="min-height:100%">
        <slot name="notice" />
        <v-snackbar v-model="error.show"
        :timeout="-1" 
        color="red" 
        top rounded transition="fade-transition">
            <v-row  justify="space-between" align="start">
                <v-col :cols="error.retry ? 9 : 10" class="my-0 py-0">
                  {{ errorMessage }}
                </v-col>
                <v-col :cols="error.retry ? 3 : 2" class="my-0 py-0">
                  <v-row justify="end">
                    <v-btn  dark x-small text @click="retryError" v-if="error.retry">
                      Retry
                    </v-btn>
                    <v-btn icon x-small dark text @click="closeError" >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
            </v-row>
        </v-snackbar>
        <v-snackbar v-model="snackbar.status" 
            :timeout="snackbar.timeout ? snackbar.timeout : 5000"
            :color="snackbar.color"
            top rounded transition="fade-transition"
            >
            <v-row justify="space-around" align="start">
                <v-col cols="11" class="my-0 py-0">
                  {{ snackbar.text ? snackbar.text.replace('GraphQL error:', '').trim() : "" }}
                </v-col>
                <v-col cols="1" class="my-0 py-0">
                    <v-btn icon x-small dark text @click="closeAlert" >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-snackbar>
        <div v-if="$slots['header']" class="primary lighten-4 primary--text text--darken-4 px-3" :style="`padding-bottom: ${headerSpace}px`">
          <v-breadcrumbs v-if="breadcrumbItems.length > 1" :items="breadcrumbItems">
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
          <slot name="header" />
        </div>
        <div :class="`px-1 px-sm-2`"
             :style="`${$slots['header'] ? `margin-top: -${headerSpace}px` : ''}`">
            <slot/>
        </div>
        
    </div>
</template>

<script>
    import {mapMutations, mapGetters} from 'vuex';

    export default {
      name: "AppLayer",
      components: {},
      data(){
          return {
          }
      },
      props: {
          breadcrumbs: Array,
          headerSpace: { type: Number, default: () => 80 }
      },
      computed:{
          ...mapGetters([
              'app_ready',
              'error',
              'current_user',
              'profile_loaded',
              'snackbar'
          ]),
        errorMessage() {
            let message = this.error.message;
            const exception = this.error.exception;
            if(exception) {
                message += ' '+exception.message.replace('GraphQL error:', '').trim()
            }
            return message;
        },
        breadcrumbItems() {
            return [
              {
                text: "Home",
                to: { path: '/' }
              }
            ].concat(this.breadcrumbs || [])
        }
      },
      methods: {
          ...mapMutations([
              'TOAST_ERROR',
              'SET_APP_STATE',
              'SET_APP_PROCESS',
              'SNACKBAR'
          ]),

          setState(ready, process = ''){
              this.SET_APP_STATE(ready);
              this.SET_APP_PROCESS(process);
          },

          alert(text, color = 'primary'){
              this.SNACKBAR({
                  color, text,
                  status: true,
              })
          },

          closeAlert(){
              this.SNACKBAR({
                  status: false,
                  color: this.$store.getters.snackbar.color
              })
          },

          toastError({message, retry, exception}){
              this.TOAST_ERROR({
                  show: true,
                  retry,
                  message,
                  exception
              })
          },

          async retryError(){

              const retry = this.error.retry;
              this.TOAST_ERROR({
                show: false,
                message: 'Retrying...',
                retry: null,
                exception: null,
              });
              await retry()
          },

          closeError(){
              this.TOAST_ERROR({
                  show: false,
                  color: "error"
              });
          },
      },
    }
</script>