<template>
  <!-- App.vue -->
  <v-app>
    <nav-drawer
        v-if="authenticated && app_ready && app_layout === 'full'"
        @signout="signUserOut" />
    <v-app-bar
      app
      color="primary"
      dark
      v-if="app_ready && app_layout === 'full'"
      elevation="0"
    >
      <v-app-bar-nav-icon
          v-if="$vuetify.breakpoint.mobile && authenticated && app_ready && app_layout === 'full'"
          @click="$store.state.navDrawer = !$store.state.navDrawer"
         ></v-app-bar-nav-icon>
      <router-link to="/" class="white--text text-decoration-none">
        <v-toolbar-title dark>
          <img v-if="$vuetify.breakpoint.mdAndDown"
               src="@/assets/img/gr-logo-white.png" height="50px"
          />
          <span v-else>Guest Registration</span>
        </v-toolbar-title>
      </router-link>
      <v-spacer></v-spacer>
      <div class="mr-n6">
        <mode-switch v-if="$store.getters.mode" />
      </div>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main v-if="app_ready">
      <router-view></router-view>
    </v-main>
    <v-main v-else>
      <div  class="d-flex justify-center align-center" style="height: 80vh">
          <div v-if="!error" class="text-center">
              <img src="@/assets/img/logo.png" width="100px" class="loader" />
              <p>{{app_process}}</p>
          </div>
          <div v-else class="text-center">
            <div><v-icon>alert-circle</v-icon></div>
            <p>{{ error }}</p>
            <div v-if="updateExists">
              <h2>This might be due to outdated version, please update the application</h2>
              <v-btn @click="refreshApp()" color="primary" text> Update</v-btn>
            </div>
            <v-btn v-else  @click="setUser()" color="primary" text> Retry</v-btn>
          </div>
      </div>
      
    </v-main>

    <v-snackbar v-if="app_layout === 'full'" top right :value="updateExists" :timeout="-1" color="primary">
      <v-row justify="space-around" align="start">
        <v-col cols="9" class="my-0 py-0">
          An update is available
        </v-col>
        <v-col cols="3" class="my-0 py-0">
          <v-btn text small dark @click="refreshApp" >
            Update
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>

    <v-footer v-if="!isFromSdk" app>
      <small><a :href="site" class="text-decoration-none">2023. Guest Registration</a></small>
      <v-spacer></v-spacer>
      <v-btn x-small @click="updateApplication" title="Reload app" icon><v-icon>mdi-refresh</v-icon></v-btn>
    </v-footer>

  </v-app>

</template>

<script>

import {mapActions, mapMutations, mapGetters} from 'vuex'
import CryptoJS from 'crypto-js';
import NavDrawer from '@/components/NavDrawer.vue';
import { fb, auth } from './firebase';
import authState from './plugins/auth';
import helper from '@/helper';
import appHelper from '@/helper/app';
import update from './mixins/update';
import config from './config';
import UPDATE_USER_DEVICE from './domain/User/Mutations/updateUserDevice';
import ModeSwitch from "@/components/ModeSwitch";

export default {
  name: 'App',
  mixins: [update],
  components: {
    ModeSwitch,
    NavDrawer
  },
  data(){
    return {
      site: "http://guestregistration.com",
      error: null,
    }
  },

  computed:{
    ...mapGetters([
      'app_ready',
      'app_process',
      'authenticated',
      'profile_loaded',
      'app_layout',
      'current_user',
      'is_mobile',
      'is_admin',
      'system',
    ]),

    plainLayoutRoutes() {
      return ["signin", "oauth.zapier", "reservation.sdk.form"]
    },

    isFromSdk() {
      return this.$route.query?.source === "sdk" || this.$route.name === 'reservation.sdk.form';
    },

    layout() {
      return this.plainLayoutRoutes.includes(this.$route.name) || this.isFromSdk
      ? 'plain' : 'full'
    }
  },

    methods:{

      ...mapActions([
          'getIdToken',
          'getAuthUser',
          'getSystemParams',
          'signout',
          'query',
          'mutate'
      ]),
      
      ...mapMutations([
          'TOAST_ERROR',
          'SET_APP_STATE',
          'SET_USER_AUTH',
          'SET_ACTIVE_PROPERTY',
          'SNACKBAR',
          'UNSET_CURRENT_USER',
          'SET_APP_LAYOUT',
          'SET_MOBILE',
          'SET_MODE'
      ]),

      setUser(){
          this.error = null;
          this.SET_APP_STATE(false);
          if( window.localStorage.getItem('gr-user') 
              && auth.currentUser 
              && window.localStorage.getItem('token-expires') > helper.nowTimestamp()
              && this.profile_loaded
          )
          {
            this.SET_APP_STATE(true);
            return;
          }
          else if(!auth.currentUser){
            this.signout().then(() => {
              this.SET_APP_STATE(true);
            })
            return;
          }
          this.getIdToken()
          .then(user =>  {
            if(user){
                this.SET_USER_AUTH(auth.currentUser);
                return this.getAuthUser();
              }
              return Promise.resolve(null)
          })
          .then(user => {
              if(user){ 
                if ('Notification' in window) {
                  if(Notification.permission === "granted"){
                    this.getNotificationToken();
                  }else{
                      Notification.requestPermission().then(permission => {
                          if (permission === "granted") {
                            this.getNotificationToken();
                          }
                      });
                  }
                }
                this.SET_APP_STATE(true);
              }
              else if(auth.currentUser){
                // Miniauth component would handle to profile set up
                if(!this.$route.meta.hasMiniAuth){
                  this.$router.push({
                    name: 'account',
                    query: {
                      redirect: this.$router.resolve(this.$router.currentRoute).href
                    }
                  })
                }
                this.SET_APP_STATE(true);
              } else {
                this.signUserOut()
              }
          })
          .catch(e => {
            if(this.updateExists) {
              this.refreshApp();
            } else {
              this.error = e.message;
            }
          })
          .finally(() => {
              this.bootIntercom();
          })
      },
      
      getNotificationToken(){
        if(fb.messaging.isSupported()){
          const messaging = fb.messaging();
          messaging.getToken({vapidKey: config.firebase.VapidKey}).then(async (currentToken) => {
          if(currentToken) await this.updateDeviceToken(currentToken);
          });
          messaging.onMessage((payload) => {
              new Notification(payload.notification.title,{
                body: payload.notification.body,
                icon: payload.notification.icon,
                click_action: payload.notification.click_action
              });
              this.$store.dispatch("getAuthUser");
          });
        }
      },

      async updateDeviceToken(token) {
        await this.mutate({
          mutation: UPDATE_USER_DEVICE,
          variables: {
            device_id: appHelper.getDeviceId(),
            data: {
              device_name: appHelper.getBrowserInfo().Platform,
              device_ip: await appHelper.getIpAddress(),
              notification_token: token
            }
          }
        })
      },

      bootIntercom() {

        if(config.intercom.app_id && auth.currentUser && this.profile_loaded) {
          this.$intercom.boot({
            user_id: auth.currentUser.uid,
            name: [this.current_user.profile.name.first_name, this.current_user.profile.name.last_name].join(' '),
            email: this.current_user.profile.email,
            user_hash: CryptoJS.HmacSHA256(auth.currentUser.uid, config.intercom.secret_key).toString(),
            hide_default_launcher: false
          })
        }

      },

      signUserOut(){
        this.SET_APP_STATE(false)
        this.signout()
        .then(() => {
            if(this.$route.name !== 'signin'){
              this.$router.push({ name: 'signin' });
            }
            this.$intercom.shutdown()
        }).finally(() => {
          this.SET_APP_STATE(true);
        })
      },
    },

    mounted() {
      if(this.isFromSdk){
        this.SET_APP_STATE(true);
        return
      }
      this.getSystemParams().then(() => {
        const critical = appHelper.checkForCriticalUpdate()
        if(critical) {
          if(appHelper.useServiceWorker()) {
            this.updateCritical = critical;
            this.refreshApp()
          } else this.updateApplication();
          return Promise.reject(
              "Cannot start application, critical update is required. Hold on while application is rebooted"
          )
        } else return authState();
      })
      .then(() => {
        if(!this.app_ready) {
          this.setUser()
        }
      })
      .catch(e => {
        console.log(e);
      });
    },

    created() {
    this.SET_MOBILE( screen.width < 768);
    window.addEventListener('resize', (e) => {
      this.SET_MOBILE( screen.width < 768)
    })
  },

    watch: {
      $route: {
        immediate: true,
        handler() {
          this.$store.commit( 'SET_APP_LAYOUT', this.layout)
        }
      }
    }
}
</script>

<style>
  .theme--light.v-application {
    background-color: #f7f7f7 !important;
  }
   /* .theme--light.v-application {
        background-image: url('./assets/img/signin-bg.png') !important;
        background-position: center center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-attachment: fixed !important;
    } */
  .required label::after {
    content: "*";
  }

  .loader {
    animation: fadeInfadeOut 1.5s linear 0s infinite;
  }

  .striped tbody tr:nth-of-type(odd) {
    background: #F7FBFF;
  }
  .striped tbody tr:nth-of-type(even) {
    background: #FFFFFF;
  }

  .theme--light.v-data-table.striped > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table.striped > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row)
  {
    border: none;
  }
  .theme--light.v-data-table.striped > .v-data-table__wrapper > table > tbody > tr:nth-of-type(odd):hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper)
  {
    background: #F7FBFF;
  }
  .theme--light.v-data-table.striped > .v-data-table__wrapper > table > tbody > tr:nth-of-type(even):hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper)
  {
    background: #FFFFFF;
  }
  @keyframes fadeInfadeOut {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
  .text-transform-unset {
    text-transform: unset !important;
  }
  .box-shadow-unset {
    box-shadow: unset !important;
    -webkit-box-shadow: unset !important;
  }
</style>
