<template>
  <app-layer ref="app">
    <template #header>
      <v-row justify="center">
        <v-col md="10"> <h4 class="headline">Recent Checkins</h4>  </v-col>
      </v-row>
    </template>
      <v-row justify="center">
        <v-col md="10">
          <section class="my-2">
            <v-sheet v-if="!reservations.length" rounded class="py-16">
              <p class="grey--text text-center">No reservation</p>
            </v-sheet>
            <section v-else>
              <v-row >
                <v-col v-for="(reservation, r) in reservations" :key="r"
                cols="12" sm="6" md="4">
                  <reservation-card  :_reservation="reservation"  />
                </v-col>
              </v-row>
            </section>
            <div class="my-3">
              <router-link :to="{name: 'reservation.list', params: {reservation: 'personal'}}" class="text-decoration-none"> All reservations</router-link>
            </div>
          </section>
          <section v-if="!properties.length" class="my-2">
            <v-sheet  rounded class="py-16">
              <div class="text-center">
                <p class="grey--text text-center">Become a host, create your property</p>
                <v-btn :to="{name: 'property.create'}" color="primary">
                  Create property
                </v-btn>
              </div>
            </v-sheet>
          </section>
        </v-col>
      </v-row>
  </app-layer>
</template>

<script>
import { mapGetters } from 'vuex';
import AppLayer from '@/AppLayer.vue';
import ReservationCard  from '@/domain/Reservation/Components/Reservation';

export default {
  name: 'GuestHome',
  components: {
    AppLayer, ReservationCard
  },
  computed: {
    ...mapGetters([
      'current_user',
    ]),

    reservations(){
      return this.current_user.profile.reservations 
      ? this.current_user.profile.reservations
              .map(reservation => reservation.reservation)
              // .filter(reservation => reservation.active)
              // .reverse()
              .slice(0, 3)
      : [];
    },

    properties(){
      return this.current_user.profile.properties
          ? this.current_user.profile.properties.map(property => property.property).slice(0, 3)
          : [];
    }
  }
}
</script>
