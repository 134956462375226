<template>
  <section>
    <data-container :loading="loading">
      <slot name="header" />
      <dotted-spinner-loader v-if="processing" style="position: absolute; left: 50%" />
      <v-form ref="form" :disabled="processing">
        <v-row>
          <v-col cols="6">
            <v-text-field
                v-model="form.first_name"
                dense
                outlined
                label="First name"
                :rules="[rules.required]"
                hide-details="auto"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
                v-model="form.last_name"
                dense
                outlined
                label="Last name"
                :rules="[rules.required]"
                hide-details="auto"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
                dense
                outlined
                v-model="form.email"
                label="Email"
                :rules="[rules.required]"
                hide-details="auto">
              <template #append>
                <google-connect-badge
                    class="mt-n1" :size="30"
                    :provider="googleProvider"
                    @connect="verifyGoogleConnect"
                    @disconnect="verifyGoogleDisconnect" />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <phone-number
                :value="form.phone"
                @input="v => form.phone = v || null"
                dense
                outlined
                :include-meta="false"
                :disabled="phoneVerificationSent"
                :rules="[rules.required]"
            >
              <template #append>
                <phone-connect-badge
                    :provider="phoneProvider && phoneProvider.phoneNumber === trimedPhone ? phoneProvider : null"
                    :size="30"
                    class="ml-n2" />
              </template>
            </phone-number>
          </v-col>
        </v-row>
      </v-form>
      <error-handler :error="formError" @retry="submit" />
      <template v-if="alert">
        <v-alert
            :type="alert.type" prominent
            :dismissible="true">{{ alert.text }}</v-alert>
        <v-btn v-if="alert.type === 'error'" text color="primary" @click="contactForm.show = true" >Contact Support</v-btn>
      </template>
      <slot name="actions" v-bind="{ loading: loading || processing, submitting, canSubmit, submit }"></slot>
    </data-container>
    <div id="recaptcha-container"></div>
    <confirmation-dialog
        ref="confirmation"
        @confirmed="confirmation.onConfirm ? confirmation.onConfirm() : () => {}">
      <div class="text-center">{{ confirmation.text }}</div>
      <template #title>{{ confirmation.title }}</template>
      <template #confirm="{ confirm }">
        <v-btn depressed color="success darken-1" @click="confirm">
          {{ confirmation.btnText || "Confirm" }}
        </v-btn>
      </template>
    </confirmation-dialog>
    <v-dialog
    v-model="phoneVerificationSent"
    persistent
    width="300"
    >
      <v-card>
        <v-card-text class="py-5">
          <phone-otp
              :phone="form.phone"
              @change-phone="changePhoneVerificationNumber"
              @resend="resendPhoneVerification"
              @input="submitPhoneVerificationOtp" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="contactForm.show"
        persistent
        width="400"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>Contact Support</h4>
          <v-btn icon @click="contactForm.show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <contact-form
              :prefill="contactForm.form"
              @submitted="contacFormSubmitted" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import profile from "@/domain/User/Mixins/profile2";
import PhoneNumber from "@/components/Utilities/PhoneNumber";
import DataContainer from "@/components/DataContainer";
import ErrorHandler from "@/components/ErrorHandler";
import ConfirmationDialog from "@/components/Utilities/ConfirmationDialog";
import PhoneOtp from "@/domain/Auth/Components/PhoneOtpInput";
import GoogleConnectBadge from "@/domain/Auth/Components/GoogleConnectBadge";
import PhoneConnectBadge from "@/domain/Auth/Components/PhoneConnectBadge";
import ContactForm from "@/widgets/ContactForm";
import DottedSpinnerLoader from "@/components/Loaders/DottedSpinner.vue";
import {mapGetters} from "vuex";

export default {
  name: 'PersonalInfoForm',
  components:{
    DottedSpinnerLoader,
    ContactForm,
    GoogleConnectBadge, PhoneOtp, ConfirmationDialog, PhoneConnectBadge, ErrorHandler, DataContainer, PhoneNumber },
  mixins: [profile],
  data(){
    return {
      confirmation: {
        title: null,
        btnText: null,
        text: null,
        onConfirm: () => {}
      },
      contactForm: {
        show: false,
        form: {
          category: "Account",
          subject: "Unable to update account information",
          message: null,
          metadata: []
        }
      },
    };
  },
  computed: {
    ...mapGetters(['system']),
  },
  methods: {

    setForm(){
      if(this.current_user.profile){
        this.form = {
          phone: this.current_user.profile.phone,
          email: this.current_user.profile.email,
          first_name: this.current_user.profile.first_name,
          last_name: this.current_user.profile.last_name,
          image: this.profileAvatar || this.googleAvatar
        }
      } else {
        this.form.email = this.current_user.auth.email;
        this.form.phone = this.current_user.auth.phoneNumber;
        if(this.current_user.auth.displayName) {
          const names = this.current_user.auth.displayName.split(' ');
          this.form.first_name = names[0] || '';
          this.form.last_name = names[1] || '';
        }
        this.form.image = this.googleAvatar;
      }
    },

    async submission() {
      if(!this.$refs.form.validate()) return null;
      try {
        if(this.system.verify_email_from_profile === "true") {
          if(!this.verifyEmail()) return null;
        } else await this.checkIfUserEmailIsTaken(this.form.email)
        if(this.system.verify_phone_from_profile === "true") {
          if(!this.verifyPhone()) return null;
        } else await this.checkUserPhoneIsTaken(this.trimedPhone)
        const user = await this.saveProfile();
        this.$emit("saved", user);
        return user;
      } catch (e) {
        throw e
      }
    },

    verifyEmail() {
      if(
            (this.googleProvider && this.googleProvider.email !== this.form.email)
          || (this.emailProvider && this.emailProvider.email !== this.form.email)
          || (!(this.googleProvider || this.emailProvider) && this.form.email)
      ) {
        this.confirmation.title = "Verification Required"
        this.confirmation.btnText = "Verify Email";
        this.confirmation.text = !this.emailProvider || !this.googleProvider
            ? "This email is not verified yet, it needs to be verified"
            : "Your email has changed, the new one needs to be verified";
        this.confirmation.onConfirm = this.emailConnect
        this.$refs.confirmation.open();
        return false
      }
      return true;
    },

    verifyPhone() {
      if(
          (this.phoneProvider && this.phoneProvider.phoneNumber !== this.trimedPhone)
          || (!this.phoneProvider && this.trimedPhone)
      ) {
        this.confirmation.title = "Verification Required"
        this.confirmation.btnText = "Verify Phone";
        this.confirmation.text = !this.phoneProvider
            ? "This phone number is not verified yet, it needs to be verified"
            : "You changed your phone number, the new one needs to be verified";
        this.confirmation.onConfirm = this.phoneConnect;

        this.$refs.confirmation.open();
        return false
      }
      return true;
    },

    verifyGoogleConnect() {
      this.googleConnect().then(google => {
        this.$emit("update", this.form);
        this.$store.commit("SNACKBAR", {
          status: true,
          text: "Google account connected successfully as "+google.displayName,
          color: "success"
        })
      })
    },

    verifyGoogleDisconnect() {
        if(!this.emailProvider) {
          this.confirmation = {
            title: "Email Verification Required",
            text: "You need to verify your email before disconnecting Google account",
            btnText: "Verify",
            onConfirm: this.emailConnect
          }
          this.$refs.confirmation.open();
          return;
        }
        this.confirmation = {
          title: "Disconnect Google",
          text: "Are you sure you want to disconnect the Google account",
          btnText: "Disconnect",
          onConfirm: () => {
            this.providerDisconnect(this.googleProvider.providerId)
              .then(() => {
                this.$emit("update", this.form);
                this.$store.commit("SNACKBAR", {
                  status: true,
                  text: "Google account disconnected",
                  color: "success"
                })
              })
          }
      }
        this.$refs.confirmation.open();
    },

    contacFormSubmitted() {
      this.contactForm.show = false;
      this.alert = null;
      this.setForm();
    }

  },
  mounted() {
    if(this.$attrs.form) {
      this.form = {...this.$attrs.form};
    }
  }
}
</script>
