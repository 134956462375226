var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{staticClass:"mx-2 text--darken-4",staticStyle:{"max-width":"350px"},attrs:{"items":_vm.propertiesSwitch,"item-text":"name","item-value":"id","item-disabled":function (property) { return !(property.is_owner || (property.auth_access && property.auth_access.active)); },"dense":"","value":_vm.current_user.property.id,"outlined":"","label":"select property","background-color":"white"},on:{"change":_vm.switchProperty},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var property = ref.item;
var index = ref.index;
var select = ref.select;
var parent = ref.parent;
return [(parent.multiple)?_c('v-chip',{staticClass:"ma-1",attrs:{"pill":"","close":""},on:{"click:close":function($event){return parent.value.splice(index, 1)}}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-img',{attrs:{"src":property.image}})],1),_vm._v(" "+_vm._s(property.name)+" ")],1):[_c('v-avatar',{attrs:{"size":"30"}},[_c('v-img',{attrs:{"src":property.image}})],1),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(property.name))])]]}},{key:"item",fn:function(ref){
var property = ref.item;
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":property.image}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(property.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(property.full_address))])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }