import gql from 'graphql-tag';

export default gql`
query getPropertyNotificationSetting($property_id: ID!) {
    getPropertyNotificationSettings(property_id: $property_id) {
       allow {
            type
            channels
       }
       blocked {
            type
            channels
       }
       email
    }
}
`
