var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.reservations,"search":_vm.search,"multi-sort":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{name: 'property.reservation.show', params: {id: item.id, _reservation: item}}}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('reservation-status',{attrs:{"reservation":item}})]}},{key:"item.guests",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.guests.length)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('reservation-host-options',_vm._g({attrs:{"reservation":item}},_vm.$listeners)),_c('reservation-host-actions',_vm._g({attrs:{"reservation":item}},_vm.$listeners))],1)]}},{key:"item.timestamp.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentFromTimestamp(item.timestamp.created_at).format('YYYY-MM-DD hh:mm a'))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }