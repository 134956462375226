<template>
  <section>
    <div class="d-flex align-center my-3">
      <h3 class="">Checkin Information</h3>
      <v-spacer></v-spacer>
      <v-btn
          v-if="checkin && $refs.contract"
          color="primary"
          class="mx-2"
          small outlined
          @click="$refs.contract.open()">Checkin Contract</v-btn>
    </div>
    <data-container :loading="loading">
        <v-alert v-if="error" type="error" colored-border border="left">
            {{ error.message }}
            <v-btn small text color="primary" @click="getReservationCheckin">Retry</v-btn>
        </v-alert>

        <template v-if="checkin">
            <confirmation-dialog ref="approvalConfirmation" @confirmed="approveCheckin">
                <div class="mt-5">
                    <p>Are you sure you want to approve the checkin?</p>
                </div>
            </confirmation-dialog>

            <confirmation-dialog ref="checkoutConfirmation" @confirmed="checkoutReservation">
              <div class="mt-5">
                <p>Are you sure you want to checkout the reservation?</p>
              </div>
            </confirmation-dialog>

            <reservation-checkin-contract
                ref="contract"
                :checkin="checkin"
                :property="property"
                :reservation="reservation"
                :pdfable="true"
            />

          <v-expansion-panels focusable v-model="panel">
            <v-expansion-panel  v-if="reservation.guests.length">
              <v-expansion-panel-header expand-icon="mdi-menu-down">
                Guests
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-3">
                <v-subheader>Primary Guest</v-subheader>
                <v-card outlined>
                  <v-card-text>
                    <v-list v-if="user">
                      <v-list-item >
                        <v-list-item-content class="py-0">
                          <small class="grey--text mb-1">Booking Name</small>
                          <p>{{ checkin.reservation.name }}</p>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content class="py-0">
                          <small class="grey--text mb-1">Account Name</small>
                          <p>{{ [user.name.first_name, user.name.last_name].join(' ') }}</p>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-alert v-else
                             border="left"
                             colored-border
                             type="error"
                             class="mt-5">
                      User not found
                    </v-alert>
                    <user-identity-verification v-if="checkin.verification" flat :verification="checkin.verification">
                      <template #upload-actions="{ closeID }" v-if="reservation.active && checkin.verification.upload && !checkin.verification.upload.verified_at" >
                        <verify-user-uploaded-id
                            :user="user"
                            :property="property"
                            :reservation="checkin.reservation"
                            :verification="checkin.verification"
                            :btn="{small: true, class:'mx-1', color: 'primary', text: true}"
                            @verified="(id) => {
                                  closeID();
                                  reservationIDUploadVerificationVerified(id)
                                } "
                        >
                          Verify ID
                        </verify-user-uploaded-id>
                        <verify-user-uploaded-id
                            v-if="checkin.verification.upload && !checkin.verification.upload.verified_at && !checkin.reservation.approved && hasPermissionToApprove && canApprove"
                            :user="user"
                            :property="property"
                            :reservation="checkin.reservation"
                            :verification="checkin.verification"
                            :btn="{small: true, class:'mx-1', color: 'success', text: true}"
                            @verified="(id) => {
                                  closeID()
                                  reservationIDUploadVerificationVerified(id)
                                  $refs.approvalConfirmation.open()
                                }"
                            small text
                        > Verify Id and Approve checkin</verify-user-uploaded-id>
                      </template>
                    </user-identity-verification>
                    <v-alert v-if="!reservation.guests.find(g => g.user_id == reservation.user_id)"
                             border="left"
                             colored-border
                             type="error"
                             class="mt-5">
                      {{ checkin.reservation.name }} is not expected to stay in the property
                    </v-alert>
                  </v-card-text>
                </v-card>
                <template v-if="reservation.guests.length">
                  <v-subheader>Other Staying Guests</v-subheader>
                  <reservation-guests :reservation="reservation" @guests="guests => reservation.guests = guests">
                    <template v-if="reservation.active" #guest-id-upload-actions="{ guest, closeID }">
                      <verify-user-uploaded-id
                          v-if="guest.verification.upload && !guest.verification.upload.verified_at"
                          :user="guest.user"
                          :property="property"
                          :reservation="checkin.reservation"
                          :verification="guest.verification"
                          :btn="{small: true, class:'mx-1', color: 'primary', text: true}"
                          @verified="(id) => {
                                  closeID();
                                  guestUploadedIDVerified(guest, id)
                                } "
                      >
                        Verify ID
                      </verify-user-uploaded-id>
                    </template>
                  </reservation-guests>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header expand-icon="mdi-menu-down">
                Credit card
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-3">
                <template v-if="checkin.checkin.credit_card">

                  <template v-if="checkin.checkin.credit_card.stripe" >
                    <stripe-credit-card v-if="checkin.checkin.credit_card.stripe.card"
                                        :card="checkin.checkin.credit_card.stripe.card">
                      <template v-if="reservation.approved && reservation.active && hasPermissionToManageCharge" #actions="{ card }">
                        <stripe-extra-charge
                            :reservation="checkin.reservation"
                            :user="user"
                            :property="property"
                            :source="checkin.checkin.credit_card.stripe"
                            @charged="(payment) => checkin.payments && checkin.payments.stripe ? checkin.payments.stripe.push(payment) : null"
                        />
                      </template>
                    </stripe-credit-card>

                    <stripe-payment-method
                        v-if="checkin.checkin.credit_card.stripe.payment_method"
                        :method="checkin.checkin.credit_card.stripe.payment_method">
                      <template v-if="reservation.approved && reservation.active && hasPermissionToManageCharge" #actions="{ method }">
                        <stripe-extra-charge
                            :reservation="checkin.reservation"
                            :user="user"
                            :property="property"
                            :source="checkin.checkin.credit_card.stripe"
                            @charged="(payment) => checkin.payments && checkin.payments.stripe ? checkin.payments.stripe.push(payment) : null"
                        />
                      </template>
                    </stripe-payment-method>
                  </template>

                  <paystack-credit-card v-if="checkin.checkin.credit_card.paystack"
                                        :card="checkin.checkin.credit_card.paystack"
                  >
                    <template v-if="reservation.approved && reservation.active && hasPermissionToManageCharge" #actions="attrs">
                      <paystack-extra-charge
                          :reservation="checkin.reservation"
                          :credit-card="attrs.card"
                          :user="user"
                          :property="property"
                          @transaction="() => $refs.payments.getPayments()"
                      />
                    </template>
                  </paystack-credit-card>

                  <reservation-payments
                      class="mt-3"
                      outlined
                      :property="property"
                      :reservation="checkin.reservation"
                      :payments="checkin.payments"
                      :can-refund="hasPermissionToManageCharge"
                      :can-capture="hasPermissionToManageCharge"
                      ref="payments"
                  />
                </template>
                <template v-else>
                  <p class="text-center grey--text py-5">No credit card</p>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header expand-icon="mdi-menu-down">
                Charges
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-3">
                <reservation-charges
                    :reservation="reservation"
                    :property="property"
                    :_payments="checkin.payments"
                    :refresh="refreshCharges">
                  <template v-if="reservation.approved && reservation.active && hasPermissionToManageCharge" v-slot:options="props" >
                    <stripe-charge-refund
                        :property="property"
                        :reservation="reservation"
                        v-bind="props"
                        @refunded="(refund) => props.updateStripePayment(refund.charge)" />
                    <stripe-charge-capture
                        :property="property"
                        :reservation="reservation"
                        v-bind="props"
                        @captured="(charge) => props.updateStripePayment(charge)" />
                  </template>
                </reservation-charges>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header expand-icon="mdi-menu-down">
                Questions
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="checkin" class="pt-3">
                <v-list v-if="checkin.checkin.questions &&  checkin.checkin.questions.length" dense>
                  <v-list-item v-for="(question, q) in checkin.checkin.questions" :key="q">
                    <v-list-item-icon>
                      <v-icon>mdi-account-question</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ question.question }}
                      </v-list-item-title>
                      <template v-if="question.response">
                        <v-list-item-subtitle>
                          Ans: {{ question.response.option  }}
                          <span class="ml-3" v-if="question.response.agreement">
                              <v-icon>mdi-attachment</v-icon> <strong>{{ question.response.agreement.agreement }}</strong>
                              <a class="ml-2" v-if="question.response.agreement.link" :href="question.response.agreement.link" target="_blank">Read agreement</a>
                        </span>
                        </v-list-item-subtitle>
                        <template v-if="question.response.attachments">
                          <template v-if="question.response.attachments.questions && question.response.attachments.questions.length">
                            <div class="ml-5">
                              <template v-for="(followUpQuestion, fq) in question.response.attachments.questions">
                                <v-list-item-title :key="`follow-up-${fq}`">
                                  {{ followUpQuestion.question }}
                                </v-list-item-title>
                                <v-list-item-subtitle :key="`follow-up-response-${fq}`">
                                  Ans: {{ followUpQuestion.response.option  }}
                                </v-list-item-subtitle>
                              </template>
                            </div>
                          </template>
                        </template>
                      </template>

                      <v-list-item-subtitle v-else>
                        No response
                      </v-list-item-subtitle>

                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <div v-else class="my-5 grey--text text-center">
                  No question responded to for this reservation
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header expand-icon="mdi-menu-down">
                Agreements
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="checkin" class="pt-3">
                <v-list v-if="checkin.checkin.agreements &&  checkin.checkin.agreements.length">

                  <property-agreement v-for="(agreement, i) in checkin.checkin.agreements" :key="i" :agreement="agreement" />

                  <!-- <v-list-item v-for="(agreement, i) in checkin.checkin.agreements" :key="i">
                      <v-list-item-icon>
                          <v-icon>mdi-handshake</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                          <v-list-item-title>
                              {{ agreement.agreement }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="agreement.link && agreement.link !== ''">
                              <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                      <a
                                          target="_blank"
                                          :href="agreement.link"
                                          @click.stop
                                          v-on="on"
                                      >
                                          Read agreement
                                      </a>
                                  </template>
                                  Opens in new window
                              </v-tooltip>
                          </v-list-item-subtitle>
                      </v-list-item-content>
                  </v-list-item> -->
                </v-list>
                <div v-else class="my-5 grey--text text-center">
                  No agreement for the reservation
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header expand-icon="mdi-menu-down">
                Instruction
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="text-center mt-5" v-if="reservation.instruction">
                  {{reservation.instruction}}
                </div>
                <div class="text-center mt-5 grey--text" v-else>
                  No checkin instruction
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header expand-icon="mdi-menu-down">
                Damages
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="checkin" class="pt-3 pt-3">
                <reservation-damages
                    :reservation="reservation"
                    :is-host="true"
                    class="mx-n6"
                    ref="damages"
                >
                  <template #options="{ damage, damageUpdated, damageDeleted }">
                    <reservation-damage-host-options
                        v-if="!damage.resolution"
                        :reservation="reservation"
                        :damage="damage"
                        @damage-updated="dmg => damageUpdated(dmg)"
                        @damage-deleted="dmg_id => damageDeleted(dmg_id)"
                    />
                  </template>
                  <template #footer-actions="{ createDamageReport }">
                    <v-card-actions class="mt-2">
                      <v-spacer></v-spacer>
                      <v-btn
                          color="primary"
                          @click="createDamageReport"
                          small depressed>
                        <v-icon>mdi-plus</v-icon>
                        New Damage Report
                      </v-btn>
                    </v-card-actions>
                  </template>
                </reservation-damages>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header expand-icon="mdi-menu-down">
                Payment Requests
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="checkin" class="pt-3 pt-3">
                <reservation-payment-requests
                    :reservation="reservation"
                    :is-host="true"
                    class="mx-n6"
                    ref="paymentRequests"
                >
                  <template #options="{ request, requestUpdated, requestDeleted }">
                    <reservation-payment-request-host-options
                      :reservation="reservation"
                      :request="request"
                      @payment-request-updated="req => requestUpdated(req)"
                      @payment-request-deleted="req => requestDeleted(req)"
                    />
                  </template>
                  <template #footer-actions="{ createRequest }">
                    <v-card-actions class="mt-2">
                      <v-spacer></v-spacer>
                      <v-btn
                          color="primary"
                          @click="createRequest"
                          small depressed>
                        <v-icon>mdi-plus</v-icon>
                        New Payment Request
                      </v-btn>
                    </v-card-actions>
                  </template>
                </reservation-payment-requests>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header expand-icon="mdi-menu-down">
                Document Requests
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="checkin" class="pt-3 pt-3">
                <reservation-document-requests
                    :reservation="reservation"
                    class="mx-n6"
                    ref="documentRequests"
                >
                  <template #options="{ request, requestUpdated, requestDeleted }">
                    <reservation-document-request-host-options
                        :property="property"
                        :reservation="reservation"
                        :request="request"
                        @document-request-updated="req => requestUpdated(req)"
                        @document-request-deleted="req => requestDeleted(req)"
                    />
                  </template>
                  <template #footer-actions="{ createRequest }">
                    <v-card-actions class="mt-2">
                      <v-spacer></v-spacer>
                      <v-btn
                          color="primary"
                          @click="createRequest"
                          small depressed>
                        <v-icon>mdi-plus</v-icon>
                        New Document Request
                      </v-btn>
                    </v-card-actions>
                  </template>
                </reservation-document-requests>
              </v-expansion-panel-content>
            </v-expansion-panel>

          </v-expansion-panels>

          <div class="mt-3" v-if="reservation.active">
              <template v-if="!checkin.reservation.approved && hasPermissionToApprove">
                <reservation-checkin-approval-rule-check :reservation="reservation" :checkin="checkin" @rules="checkinApprovalRulesCheckResult" />
                <v-btn
                    color="success"
                    @click="$refs.approvalConfirmation.open()"
                    :loading="approval.loading"
                    :disabled="!canApprove"
                    class="mx-2"
                >
                  <v-icon class="mr-2">mdi-check-circle</v-icon>  Approve checkin
                </v-btn>
              </template>
              <template v-if="checkin.reservation.approved && checkoutPassed && !checkin.reservation.checkedout && hasPermissionToUpdate">
                <v-btn
                    color="success"
                    @click="$refs.checkoutConfirmation.open()"
                    :loading="checkout.loading"
                    class="mx-2"
                >
                  <v-icon class="mr-2">mdi-check-circle</v-icon> Checkout
                </v-btn>
            </template>
            </div>

        </template>
    </data-container>
  </section>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

import GETRESERVATION_CHECKIN from '../Queries/getReservationCheckin';
import APPROVERESERVATION_CHECKIN from '../Mutations/approveReservationCheckin';

import DataContainer from '../../../components/DataContainer'

import StripeChargeCapture from '../Components/Payment/StripeChargeCapture.vue';
import StripeChargeRefund from '../Components/Payment/StripeChargeRefund.vue';
import StripeExtraCharge from '../Components/Payment/StripeExtraCharge.vue'

import PaystackExtraCharge from '../Components/Payment/PaystackExtraCharge.vue'

import ReservationCharges from './Checkin/ReservationCharges.vue';
import StripeCreditCard from '../../../components/Utilities/StripeCreditCard.vue';
import PaystackCreditCard from '../../../components/Utilities/PaystackCreditCard.vue'
import ConfirmationDialog from '@/components/Utilities/ConfirmationDialog.vue';
import ReservationPayments from '../Components/Payment/ReservationPayments.vue'
import UserIdentityVerification from '../../User/Components/IdentityVerification';
import ReservationCheckinContract from './Checkin/ReservationCheckinContract';
import ReservationGuests from './ReservationGuests.vue';
import PropertyAgreement from '../../Property/Components/PropertyAgreement.vue'
import VerifyUserUploadedId from '../../User/Components/VerifyUserUploadedId.vue';
import ReservationCheckinApprovalRuleCheck from "@/domain/Reservation/Components/ReservationCheckinApprovalRuleCheck";
import reservationMixin from '../../Reservation/Mixins/reservation';
import ReservationDamages from "@/domain/Reservation/Widgets/ReservationDamages";
import ReservationDamageHostOptions from "@/domain/Reservation/Components/ReservationDamageHostOptions";
import StripePaymentMethod from "@/components/Utilities/StripePaymentMethod.vue";
import ReservationPaymentRequests from "@/domain/Reservation/Widgets/ReservationPaymentRequests.vue";
import ReservationPaymentRequestHostOptions
  from "@/domain/Reservation/Components/ReservationPaymentRequestHostOptions.vue";
import ReservationDocumentRequests from "@/domain/Reservation/Widgets/ReservationDocumentRequests.vue";
import ReservationDocumentRequestHostOptions
  from "@/domain/Reservation/Components/ReservationDocumentRequestHostOptions.vue.vue";

export default {
    name: "PropertyReservationCheckin",
    mixins: [reservationMixin],
    components: {
      ReservationDocumentRequestHostOptions,
      ReservationDocumentRequests,
      ReservationPaymentRequestHostOptions,
      ReservationPaymentRequests,
      StripePaymentMethod,
      ReservationDamageHostOptions,
      ReservationDamages,
        DataContainer, ConfirmationDialog,

        UserIdentityVerification, ReservationCharges, ReservationPayments,  ReservationCheckinContract, ReservationGuests, PropertyAgreement,
        
        StripeCreditCard, StripeChargeCapture, StripeChargeRefund, StripeExtraCharge,
        PaystackCreditCard, PaystackExtraCharge,

        VerifyUserUploadedId, ReservationCheckinApprovalRuleCheck
    },
    data(){

        return {
            panel: 0,
            checkin: null,
            loading: false,
            approval: {
                loading: false,
                error: null,
            },
            refreshCharges: false,
            error: null,
            approvalRulesPassed: false,
        }
    },

    computed: {

        user(){
            return this.checkin ? this.checkin.user : null
        },

        canApprove(){
            return this.user !== null && this.approvalRulesPassed;
        },

        checkout() {
          return {
            loading: this.actionsLoading.includes('checkingout'),
          }
        }
    },

    props: {
        reservation: Object,
    },

    methods: {
      ...mapActions([
          'query',
          'mutate'
      ]),
      ...mapMutations([
          'TOAST_ERROR'
      ]),

      getReservationCheckin(){
          this.loading = true;
          this.error = null;
          this.query({
              query: GETRESERVATION_CHECKIN,
              variables: {
                  id: this.reservation.id
              }
          })
          .then(response => {
              this.checkin = response.data.getReservationCheckin;
              if(!this.checkin.user){
                  this.$store.commit('SNACKBAR', {
                      status: true,
                      text: "The user account that checked in no longer exist",
                      color: "error"
                  })
                  return;
              }
          })
          .catch(e => {
              this.error = e
          })
          .finally(() => {
              this.loading = false;
          })
      },

      approveCheckin(){
            this.approval.loading = true;
            this.approval.error = null;

            this.mutate({
                mutation: APPROVERESERVATION_CHECKIN,
                variables: {id: this.checkin.reservation.id}
            })
            .then(response => {
                this.checkin.reservation = response.data.approveReservationCheckin;
                this.$store.commit('SNACKBAR', {
                    status: true,
                    text: "Checkin Approved",
                    color: "success"
                })
                this.$emit('approved', response.data.approveReservationCheckin);
            })
            .catch(e => {
               this.TOAST_ERROR({
                    show: true,
                    retry: () => this.approveCheckin(),
                    message: 'Could not approve reservation checkin. ',
                    exception: e
                });
                this.$emit('error', e);
            })
            .finally(() => {
                this.approval.loading = false;
            })
        },

      checkinApprovalRulesCheckResult(rules) {
          this.approvalRulesPassed = rules.every(rule => rule.pass);
      },

      reservationIDUploadVerificationVerified(id) {
        this.checkin.verification = {
          ...this.checkin.verification, ...Object.assign(this.checkin.verification.upload, id)
        }
      },

      guestUploadedIDVerified(guest, id) {
          this.reservation.guests = this.reservation.guests.map(g => {
            if(g.id === guest.id)  Object.assign(g.verification.upload, id);
            return g;
          })
      }
    },

    watch: {
        reservation: {
            immediate: true,
            handler(reservation){
                if(reservation) this.getReservationCheckin();
            }
        }
    }
}

</script>