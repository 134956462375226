<template>
    <property>
        <template  v-slot:default="property">
            <v-row justify="center">
                <v-col md="6">
                    <h2>Property Rules</h2>
                    <v-card flat>
                        <v-card-text v-if="property.rules" v-html="property.rules"></v-card-text>
                        <v-card-text v-else>No rules set</v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </property>
</template>

<script>
    import Property from './Show';
    export default {
        name: 'PropertyRules',
        components: {
            Property
        },
        data(){
            return {
                property: null
            }
        },
    }
</script>