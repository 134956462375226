<template>
    <app-layer ref="app" :breadcrumbs="breadcrumbs">
      <template #header>
        <v-row justify="center">
          <v-col cols="12" md="8">
            <div class="pb-7">
              <h4 class="headline">Create Property</h4>
            </div>
          </v-col>
        </v-row>
      </template>
      <v-row justify="center">
          <v-col cols="12" md="8">
            <property-form @property-created="propertyCreated" />
          </v-col>
      </v-row>
    </app-layer>
</template>

<script>
import AppLayer from '@/AppLayer';
import PropertyForm from '../Components/PropertyForm.vue';

export default {
    name: 'CreateProperty',
    components: {
        AppLayer, PropertyForm
    }, 
    data(){
        return {
            property: null,
        }
    },

    computed:{
      breadcrumbs(){
        return [
          {
            text: "Properties",
            to: { name: 'property.list' }
          },
          {
            text: "Create New",
            to: this.$route
          }
        ]
      }
    },
    
    methods:{
        propertyCreated(property){
            this.property = property;
            this.$router.push({name: 'property.settings', params: {id: this.property.id, tab: 'check-in-point', new: true }})
        }
    },
}
</script>
