<template>
    <v-dialog 
        v-model="dialog"
        scrollable
        transition="dialog-bottom-transition"    
        persistent
      >
      <reservation-form :property="property" :reservation="reservation"  />
    </v-dialog>
</template>

<script>
import ReservationForm from './ReservationForm.vue';

export default {
    name: "ReservationFormDialog",
    components: {
       ReservationForm
    },

    data(){
        return {
            dialog: false,
        }
    },
    props: {
        property: Object,
        reservation: Object
    },

    computed: {

    },

    methods: {

        open(){
            this.dialog = true;
        },

        close(){
            this.dialog = false;
        },

    },

    watch: {
        
    }
}
</script>