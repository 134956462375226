var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-container',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return _vm._l((4),function(i){return _c('div',{key:i},[_c('v-skeleton-loader',{staticClass:"my-2",attrs:{"type":"heading"}}),_c('v-skeleton-loader',{staticClass:"my-2",attrs:{"type":"card, button","height":"60"}}),_c('v-divider')],1)})},proxy:true}])},[(_vm.charges && _vm.charges.length)?_c('v-form',{ref:"chargePaymentForm"},[_vm._t("label",null,null,  { allChargesPaid: _vm.allChargesPaid } ),_vm._l((_vm.allCharges),function(charge){return _c('div',{key:charge.id},[_c('reservation-charge',{staticClass:"my-1",attrs:{"charge":charge,"payment":_vm.chargePayment(charge),"reservation":_vm.reservation,"guests":_vm.guests,"property":_vm.property,"credit-card":_vm.creditCard,"listItem":{class: 'px-0'},"capture-pre-authorize":_vm.capturePreAuthorize},on:{"calculate":_vm.calculateCharges},scopedSlots:_vm._u([{key:"options",fn:function(props){return [_vm._t("options",null,null,Object.assign({}, props, {updateStripePayment: _vm.updateStripePayment}))]}},{key:"default",fn:function(props){return [_vm._t("default",null,null,props),(_vm.canPay && !_vm.chargePayment(charge))?[(props.customMultipliers && props.customMultipliers.length)?[_c('div',{staticClass:" d-flex flex-wrap"},_vm._l((props.customMultipliers),function(multiplier,i){return _c('v-text-field',{key:i,attrs:{"outlined":"","dense":"","type":"number","label":multiplier.unit,"rules":[
                              function (v) { return !!v || "Enter valid number"; },
                              function (v) { return v  >= multiplier.min || ("Minimum is " + (multiplier.min)); },
                              function (v) { return v  <= multiplier.max || ("Maximum is " + (multiplier.max)); } ],"value":props.paymentVariables.metadata.custom_multipliers[i].quantity},on:{"input":function (v) {
                              props.paymentVariables.metadata.custom_multipliers[i].quantity = parseInt(v);
                              _vm.calculateCharges();
                          }}})}),1)]:_vm._e(),(_vm.reservation.setting.payment_gateway === 'stripe')?_c('reservation-stripe-single-payment',{attrs:{"property":_vm.property,"reservation":_vm.reservation,"payload":props.paymentVariables,"charge":charge,"total":_vm.chargeTotal(props.paymentVariables)/100,"currency":_vm.currency,"source-from":"payment-method","available-source":_vm.submittedStripeSource && _vm.submittedStripeSource.payment_method ? _vm.submittedStripeSource.payment_method : null},on:{"transaction":_vm.newStripeTransaction,"credit-card":_vm.stripeCreditCardReceived},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var startPayment = ref.startPayment;
                          var paying = ref.loading;
return [_c('v-btn',{attrs:{"loading":paying,"color":"primary","depressed":"","small":""},on:{"click":startPayment}},[_vm._v(" "+_vm._s(props.paymentVariables.capture ? 'Pay' : 'Authorize')+" "+_vm._s(_vm._f("money_value")(_vm.chargeTotal(props.paymentVariables),_vm.currency))+" ")])]}}],null,true)}):_vm._e(),(_vm.reservation.setting.payment_gateway === 'paystack')?_c('reservation-paystack-single-payment',{staticClass:"my-2",attrs:{"property":_vm.property,"reservation":_vm.reservation,"charge":charge,"payload":props.paymentVariables,"total":_vm.chargeTotal(props.paymentVariables)/100,"currency":_vm.currency,"available-authorization":_vm.submittedPaystackAuthorization},on:{"credit-card":_vm.paystackCreditCardReceived,"transaction":_vm.newPaystackTransaction},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var startPayment = ref.startPayment;
                          var paying = ref.loading;
return [_c('v-btn',{attrs:{"loading":paying,"color":"primary","depressed":"","small":""},on:{"click":startPayment}},[_vm._v(" "+_vm._s(props.paymentVariables.capture ? 'Pay' : 'Authorize')+" "+_vm._s(_vm._f("money_value")(_vm.chargeTotal(props.paymentVariables),_vm.currency))+" ")])]}}],null,true)}):_vm._e()]:_vm._e()]}}],null,true)})],1)})],2):_c('div',{staticClass:"text-center grey--text py-5"},[_vm._v(" No charge for the reservation ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }