var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.preview)?_c('property-question-list',{attrs:{"questions":_vm.selected},scopedSlots:_vm._u([{key:"list-item-content",fn:function(ref){
var question = ref.question;
return [_vm._t("list-item-content",null,null,{ question: question })]}}],null,true)}):_vm._e(),(_vm.changeable)?_c('div',[_c('v-autocomplete',_vm._g(_vm._b({attrs:{"loading":_vm.loading,"items":_vm.filteredItems,"no-data-text":("" + (_vm.loading ? 'Please wait...': "No question."))},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"disabled":!_vm.allowAttachment && _vm.withAttachments.map(function (q) { return q.id; }).includes(item.id)}},on),[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":_vm.selected.map(function (s) { return s.id; }).includes(item.id)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.question))]),_c('v-list-item-subtitle',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.response_type !== 'options'),expression:"item.response_type !== 'options'"}]},[_vm._v(_vm._s(item.response_type))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.response_type === 'options'),expression:"item.response_type === 'options'"}]},[_vm._v(_vm._s(item.options.length)+" options")])]),(!_vm.allowAttachment && _vm.withAttachments.map(function (q) { return q.id; }).includes(item.id))?_c('v-list-item-subtitle',{staticClass:"red--text"},[_c('small',[_vm._v("Can not use question with attachment")])]):_vm._e()],1)],1)]}}],null,false,4088980109),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},'v-autocomplete',_vm.$attrs,false),_vm.$listeners)),(_vm.createNew)?_c('div',{staticClass:"mt-n5 d-flex justify-end"},[_c('v-dialog',{attrs:{"max-width":"550","scrollable":"","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","title":"create new question","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add New Question ")],1)]}}],null,false,392446545),model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_c('h4',[_vm._v("New Question")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.createDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('property-question-form',{staticClass:"mt-2",attrs:{"property":_vm.property,"allowAttachment":_vm.allowAttachment,"allowedCurrency":_vm.allowedCurrency,"parent-question":_vm.parentQuestion},on:{"created":_vm.questionCreated,"cancel":function($event){_vm.createDialog = false}}})],1)],1)],1)],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }