<template>
    <div>
        <slot name="heading" />

        <template v-if="property && property.active">
            <v-alert
            v-if="!paymentIntegrations.length"
            prominent
            border="left"
            colored-border
            type="error">
                <p>No payment integration yet, you won't be able manage charges</p>
                <v-btn :to="{name: 'property.settings', params: {id: property.id, tab: 'integrations'}}" color="info" text>Go to integrations</v-btn>
            </v-alert>
            <data-container v-else  :loading="loading">
                <template v-slot:loading>
                    <div  v-for="i in 4" :key="i">
                        <v-skeleton-loader
                            type="card"
                            height="50"
                            class="my-2"
                        ></v-skeleton-loader>
                    </div>
                </template>

                <div class="d-flex justify-end my-3">
                    <v-btn color="primary"  title="New charge" @click="createNewCharge = true"><v-icon dark> mdi-plus</v-icon> Create New</v-btn>
                </div>

                <div v-if="createNewCharge" class="my-1">
                    <h2 class="headline">New charge</h2>
                    <property-charge-form
                    class="my-2"
                    :disabled="!paymentIntegrations.length"
                    :property="property" 
                    @created="chargeCreated"
                    @cancel="createNewCharge = false"
                    />
                </div>
                <template v-if="charges.length">
                    <property-charge
                    v-for="charge in charges" :key="charge.id" 
                    :property="property"
                    :charge="charge"
                    :disabled="!paymentIntegrations.length"
                    @deleted="chargeDeleted"
                    class="my-1" />
                </template>
                <template v-else>
                    <div class="py-5 text-center">
                        <p class="grey--text">No charge yet</p>
                    </div>
                    <template v-if="!createNewCharge">
                        <div class="text-center">
                            <v-btn color="primary" @click="createSecurityDeposit = !createSecurityDeposit" class="my-1">Set security deposit</v-btn>
                        </div>
                        <div v-if="createSecurityDeposit"  class="my-1">
                            <p>Set security deposit charge</p>
                            <h4 class="">Create Security deposit</h4>
                            <property-charge-form class="mt-2" :property="property" :prefill="security_deposit.form" :read-only="security_deposit.read_only" :title="security_deposit.form.title" @created="chargeCreated"  />
                        </div>
                    </template>
                </template>

            </data-container>
        </template>

    </div>

</template>
<script>
import DataContainer from '../../../components/DataContainer.vue';

import PropertyChargeForm from '../Components/PropertyChargeForm.vue';
import PropertyCharge from '../Components/PropertyCharge.vue';
import GET_PROPERTY_CHARGES from '../Queries/getPropertyCharges';

export default {
    name: "PropertyCharges",
    components: {
        DataContainer,
        PropertyChargeForm,
        PropertyCharge
    },

    props: {
        property: Object
    },

    data(){
        return {
            loading: false,
            stripe_authorization: null,
            createSecurityDeposit: false,
            createNewCharge: false,
            security_deposit: {
                form: {
                    title: 'Security Deposit',
                    description: 'Initial security deposit to be paid at checkin in',
                    enable: false,
                    amount: 100,
                    type: 'Instant',
                    currency: this.property.default_currency,
                },
                read_only: [
                    'title'
                ]
            },
            charges: [],
        }
    },  

    computed: {
        paymentIntegrations() {
            const integrations = this.property.integrations && this.property.integrations ? this.property.integrations : [];
            return integrations.filter(integration => ['stripe', 'paystack'].includes(integration));
        },
    },

    methods: {
        getPropertyCharges(){
            this.loading = true;
           
             this.$store.dispatch('query', {
                query: GET_PROPERTY_CHARGES,
                variables: {
                    property_id: this.property.id
                }
            })
            .then(response => {
                this.charges = response.data.getPropertyCharges
            })
            .catch(e => {
                this.$store.commit('TOAST_ERROR', {
                    show: true,
                    retry: () =>  this.getPropertyCharges(),
                    message: 'Could not get property charges ',
                    exception: e
                })
            })
            .finally(() => {
                this.loading = false;
            })
        },

        chargeCreated(charge){
            this.charges.unshift(charge);
            this.createNewCharge = false;
        },

       chargeDeleted(charge) {
            const index = this.charges.findIndex(c => c.id === charge.id);
            if(index >= 0) this.charges.splice(index, 1);
        }

    },

    watch: {
        property: {
            immediate: true,
            handler(property){
                if(property && property.active) this.getPropertyCharges()
                else this.loading = false;
            }
        }
    }
}
</script>