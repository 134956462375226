import gql from 'graphql-tag';

export default gql`
    query getAuth{
        getAuth {
            authentication {
                uid
                name
                email
                is_admin
                sign_in_provider
                admin {
                    uid
                    level
                    active
                }
            }
            user {
                id
                email,
                email_confirmation,
                phone,
                name {
                    first_name
                    last_name
                }
                first_name
                last_name
                full_name
                phone_meta{
                    country_code
                    phone_number
                }
                verification{
                    document
                    selfie
                }
                guest_info {
                    gender
                    phone
                    address
                    email
                    age
                }
                properties{
                    id
                    name
                    image
                    address
                    property {
                        id
                        user_id
                        name
                        rules
                        terms
                        email
                        phone
                        image
                        phone_meta {
                            country_code
                            phone_number
                            complete_phone
                        }
                        full_address
                        address {
                            street
                            city
                            country
                            postal_code
                        }
                        subscription {
                            status
                            trial_start
                            trial_end
                            current_period_start
                            current_period_end
                        }
                        active
                        is_owner
                        auth_access {
                            role
                            active
                            permissions
                        }
                        default_currency
                        integrations
                        stripe_connected
                        reservations {
                            id
                            name
                            checkin_date
                            checkout_date
                        }
                        checkin_points
                        status
                        notifications {
                            id
                            type
                            title
                            text
                            time
                            timestamp
                            read_at
                            metadata {
                                key
                                value
                            }
                        }
                        timestamp {
                            created_at
                            updated_at
                        }
                    }
                }

                reservations{
                    id
                    name
                    property_id
                    property_name
                    property_address
                    property_image
                    checkin_date
                    checkout_date
                }
                
                q_reservations {
                    id
                    user_id
                    name
                    balance
                    currency
                    room
                    booking_reference
                    booking_channel {
                        id
                        label
                    }
                    status
                    active
                    checkedin
                    already_checkedin
                    checkedin_at
                    approved
                    approved_at
                    checkin_date
                    checkout_date
                    cancelled
                    cancelled_at
                    checkedout
                    checkedout_at
                    instruction
                    checkin_url
                    property_id
                    property{
                        id
                        name
                        address
                        image
                    }
                }
                
                notifications {
                    id
                    type
                    title
                    text
                    time
                    timestamp
                    read_at
                    metadata {
                        key
                        value
                    }
                }
            }
        }
    }`;