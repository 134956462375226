var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('confirmation-dialog',{ref:"actionConfirmation",on:{"confirmed":function($event){return _vm.confirmation.confirmed()}}},[_c('div',{staticClass:"text-center"},[_c('p',[_vm._v(_vm._s(_vm.confirmation.text))])])]),_c('v-menu',{attrs:{"origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',_vm.$attrs.activator,false),on),[_c('v-icon',_vm._b({},'v-icon',_vm.$attrs.activatorIcon,false),[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[(_vm.hasPermissionToEdit)?_c('v-list-item',{on:{"click":function($event){return _vm.$router.replace({ name:'property.reservation.show', params: { id: _vm.reservation.id }, query: {edit: 1}})}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-pen")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Edit Reservation")])],1)],1):_vm._e(),(_vm.hasPermissionToEdit)?_c('v-list-item',{attrs:{"disabled":_vm.reservation.cancelled || _vm.reservation.approved},on:{"click":function (e) {
              _vm.confirmation = {
                text: 'Are you sure you want to cancel the reservation',
                confirmed: _vm.cancelReservation
              }
              _vm.$refs.actionConfirmation.open()
          }}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cancel Reservation")])],1)],1):_vm._e(),(_vm.hasPermissionToDelete)?_c('v-list-item',{attrs:{"disabled":_vm.reservation.already_checkedin},on:{"click":function (e) {
                      _vm.confirmation = {
                        text: 'Are you sure you want to delete the reservation',
                        confirmed: _vm.deleteReservation
                      }
                      _vm.$refs.actionConfirmation.open()
                    }}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Delete Reservation")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }