<template>
  <guest-home v-if="mode === 'guest'" />
  <host-home v-else-if="mode === 'host'" />
  <dashboard-home v-else-if="mode === 'admin'" />
</template>

<script>
import { mapGetters } from 'vuex';
import DashboardHome from "@/domain/Dashboard/Views/Home";
import HostHome from "@/views/HostHome";
import GuestHome from "@/views/GuestHome";

export default {
  name: 'Home',
  components: {
    GuestHome,
    HostHome,
    DashboardHome,
  },
  computed: {
    ...mapGetters([
      'current_user',
      'mode'
    ])
  }
}
</script>
