var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('confirmation-dialog',{ref:"actionConfirmation",on:{"confirmed":function($event){return _vm.confirmation.confirmed()}}},[_c('div',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(_vm.confirmation.text)}})]),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.requestFormDialog),callback:function ($$v) {_vm.requestFormDialog=$$v},expression:"requestFormDialog"}},[_c('reservation-document-request-form',_vm._g({attrs:{"reservation":_vm.reservation,"request":_vm.request,"role":_vm.requestFormRole},on:{"cancel":function($event){_vm.requestFormDialog = false},"document-request-updated":function($event){_vm.requestFormDialog = false}}},_vm.$listeners))],1),_c('reservation-document-review-form',_vm._g({ref:"documentReview",attrs:{"reservation":_vm.reservation,"request":_vm.request,"status":_vm.status}},_vm.$listeners)),_c('v-menu',{attrs:{"origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',_vm.$attrs.activator,false),on),[_c('v-icon',_vm._b({},'v-icon',_vm.$attrs.activatorIcon,false),[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[(!_vm.request.submission)?_c('v-list-item',{on:{"click":function () { _vm.requestFormRole = 'update'; _vm.requestFormDialog = true }}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pen")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Edit Request")])],1)],1):_vm._e(),(_vm.request.submission && !(_vm.request.review && _vm.request.review.status === 'accepted'))?_c('v-list-item',{on:{"click":function () { _vm.status = 'accepted' }}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Accept Document")])],1)],1):_vm._e(),(_vm.request.submission && !(_vm.request.review && _vm.request.review.status === 'accepted'))?_c('v-list-item',{on:{"click":function () { _vm.status = 'rejected' }}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Reject Document")])],1)],1):_vm._e(),_c('v-list-item',{on:{"click":function (e) {
                      _vm.confirmation = {
                        text: ("Are you sure you want to delete the request <br/> <strong>" + (_vm.request.title) + "</strong>"),
                        confirmed: _vm.deleteRequest
                      }
                      _vm.$refs.actionConfirmation.open()
                    }}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Delete Request")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }