<template>
    <data-container :loading="loading" :error="getVerificationError" @retry="getVerification">
      <v-card outlined v-bind="$attrs" :loading="continuing">
        <v-card-text v-if="dataVerification">
          <div class="d-flex flex-wrap align-items-center justify-space-between">
            <div class="text-uppercase">Verification Type: {{ dataVerification.type  }}</div>
            <v-chip
                class="ma-2"
                :color="`${statuses[dataVerification.status] ? statuses[dataVerification.status].color : 'info'}`"
                text-color="white"
                small
            >
              <span>{{  statuses[dataVerification.status] ? statuses[dataVerification.status].tag : dataVerification.status }}</span>
            </v-chip>
          </div>
          <p>Updated {{ lastUpdated }}</p>
          <div v-if="isMine && dataVerification.status == 'requires_input'" >
            <v-btn @click="continueVerificationSession" text small color="primary" :loading="continuing">Complete ID Verification</v-btn>
            <error-handler :error="continueVerificationError" @retry="continueVerificationSession" />
          </div>
          <v-btn v-if="dataVerification.status !== 'verified' && manualCheck" class="ma-1"
                 small text color="primary"
                 @click="getVerification"
                 :loading="loading"
                  >
            <v-icon size="18">mdi-refresh</v-icon> check status again
          </v-btn>
          <template v-if="dataVerification.report">
            <slot name="verification" v-bind="{ verification: dataVerification }" />
            <v-btn class="ma-1" small text color="primary" @click="$refs.report.open()">View Verification</v-btn>
          </template>
          <verification-report ref="report" :verification="verification" />
        </v-card-text>
        <v-card-text v-else>
          <p class="text-center grey--text">No verification available</p>
        </v-card-text>
      </v-card>
      <stripe-id-gateway ref="stripeId" />
    </data-container>
</template>

<script>
import moment from 'moment';
import VerificationReport from './VerificationReport';
import GET_USER_STRIPE_VERIFICATION from '../Queries/getUserStripeVerification';
import GET_STRIPE_VERIFICATION_SESSSION from '../Queries/getStripeVerificationSession';
import StripeIdGateway from "@/components/Utilities/StripeIdGateway";
import ErrorHandler from "@/components/ErrorHandler";
import DataContainer from "@/components/DataContainer";

export default {
    name: "UserStripeVerification",
    components: {
      DataContainer,
      ErrorHandler,
      StripeIdGateway,
       VerificationReport
    },
    
    data(){
        return {
            loading: false,
            continuing: false,
            dataVerification: null,
            trial: 0,
            session: null,
            getVerificationError: null,
            continueVerificationError: null,
        }
    },

    props: {
        verification: Object,
        poll: {
            default: true
        },
        manualCheck: {
            default: false
        }
    },

    computed: {
        isMine(){
            if(this.verification && this.verification.metadata) {
                return this.verification.metadata.user_id === this.$store.getters.current_user.profile.id
            } 
            return false;
        },

        lastUpdated() {
            if(!this.verification || !this.verification.last_updated) return null;
            return moment.unix(this.verification.last_updated).fromNow();
        },

      statuses() {
          return {
            'requires_input': {
              tag: 'Abandoned',
              color: 'warning'
            },
            'verified': {
              tag: 'Verified',
              color: 'success'
            }
          }
      }
    }, 

    methods: {
      getVerification() {
            this.getVerificationError = null;
            this.loading = true;
            this.$store.dispatch('query', {
                query: GET_USER_STRIPE_VERIFICATION,
                variables: {
                    verification_id: this.verification.id
                }
            })
            .then(response => {
                this.dataVerification = response.data.getUserStripeVerification;
                this.$emit('updated', this.dataVerification);
                this.$store.commit('SNACKBAR', {
                    status: true,
                    text: `Verification status updated`,
                    color: 'info'
                });
            })
            .catch(e => {
                this.getVerificationError = e;
            })
            .finally(() => {
                this.loading = false;
            })
        },

      continueVerificationSession() {
        this.continuing = true;
        this.continueVerificationError = null;
        this.$store.dispatch('query', {
            query: GET_STRIPE_VERIFICATION_SESSSION,
            variables: {
              id: this.verification.session
            }
          })
          .then(response => {
            this.session = response.data.getStripeVerificationSession;
            return this.$refs.stripeId.verify(this.session.client_secret)
          })
          .then(result => {
            this.$emit('result', result);
          })
          .catch(e => {
            this.continueVerificationError = e;
          })
          .finally(() => {
            this.continuing = false;
          })
      }
    },
    
    watch: {
        verification: {
            immediate: true,
            handler(verification) {
                this.dataVerification = verification;
            }
        }
    }
}
</script>