<template>
    <integration-setup
        :open="open"
        title="Mailgun"
        :subtitle="`Mailgun integration for ${ property.name }`"
        :fetch-query="getQuery"
        @fetched="mailgunFetched"
        @close="open = false"
    >
      <template #header-action>
        <v-switch
            v-model="form.enabled"
            inset
        >
        </v-switch>
      </template>
            <v-alert v-if="error" type="error">
                {{  error.message }}
            </v-alert>
            <v-form ref="form">
                <v-text-field
                    outlined dense
                    label="API key"
                    :rules="[rules.required]"
                    type="text"
                    v-model="form.api_key"
                    :disabled="!edit"
                ></v-text-field>
                <v-text-field
                    outlined dense
                    label="Domain"
                    :rules="[rules.required]"
                    type="text"
                    v-model="form.domain"
                    :disabled="!edit"
                ></v-text-field>
                <v-text-field
                    outlined dense
                    label="From Address"
                    :rules="[rules.required]"
                    type="text"
                    v-model="form.from_address"
                    :disabled="!edit"
                ></v-text-field>
            </v-form>
        <template  v-if="edit" #actions>
            <v-btn color="primary" :loading="saving" @click="save" depressed>Save changes</v-btn>
        </template>
        
    </integration-setup>
</template>

<script>
import gql from 'graphql-tag';
import formValidation from '@/helper/formValidation';
import IntegrationSetup from "@/domain/Property/Integrations/Setup";


export default {
    name: "MailgunIntegration",
    components: { IntegrationSetup },
    data(){
        return {
            open: false,
            edit: true,
            saving: false,
            mailgun: null,
            form: {},
            error: null,
            rules: formValidation.rules,
            getQuery: {}
        }
    },
    props: {
        property: Object,
    },
    methods: {
      mailgunFetched(response) {
        this.mailgun = response.getPropertyIntegrations.mailgun;
        this.setForm();
      },
      setForm() {
        const credentials = this.mailgun || {};
        this.form = {
          enabled: credentials.enabled,
          api_key: credentials.api_key,
          domain: credentials.domain,
          from_address: credentials.from_address
        }
      },
      save() {
            if(!this.$refs.form.validate()) return;
            this.saving = true;
            this.$store.dispatch('mutate', {
                mutation: gql`
                mutation updatePropertyMailgunIntegration($property_id: ID!, $data: PropertyMailgunIntegrationInput!) {
                    updatePropertyMailgunIntegration(property_id: $property_id, data: $data) {
                        enabled
                        api_key
                        domain
                        from_address
                    }
                }
                `,
                variables: {
                    property_id: this.property.id,
                    data: this.form
                }
            })
            .then(response => {
                this.error = null;
                this.mailgun = response.data.updatePropertyMailgunIntegration;
                if(this.mailgun) {
                    this.$store.commit("SNACKBAR", {
                        status: true,
                        text: "Mailgun updated",
                        color: "success"
                    });
                    // this.edit = false;
                    this.$emit('updated');
                }
            })
            .catch(e => {
                this.error = e;
            })
            .finally(() => {
                this.saving = false;
            })
        },
    },

  watch: {
    property: {
      immediate: true,
      handler(property) {
        if(!property) this.getQuery = null;
        this.getQuery = {
          query: gql`
            query getPropertyIntegrations($property_id: ID!){
              getPropertyIntegrations(property_id: $property_id){
                  mailgun {
                         enabled
                         api_key
                         domain
                         from_address
                     }
                  }
              }`,
          variables: {
            property_id: property.id
          }
        }
        }
      }
    }
}
</script>