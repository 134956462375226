<template>

      <profile-avatar
          v-if="provider"
          :size="size"
          :profile="{ first_name: provider.displayName, image: provider.photoURL }"
          style="cursor: pointer"
          :title="`Connected to Google account as ${provider.displayName}`"
          @click="$emit('disconnect')"
      ></profile-avatar>

      <profile-avatar
          v-else
          :size="size"
          style="cursor: pointer"
          title="Connect Google"
          color="primary"
          @click="$emit('connect')"
      >
        <template #icon>
          <v-icon class="white--text" :size="Math.ceil(size/2)">mdi-google</v-icon>
        </template>
      </profile-avatar>
</template>
<script>
import ProfileAvatar from "@/components/ProfileAvatar";

export default {
  name: "GoogleConnectBadge",
  components: { ProfileAvatar},
  props: {
    provider: Object,
    size: {
      default: () => 40
    },
  },
  computed: {
    sizeRatio() {
      return {
        progressCircular: Math.ceil(this.size)/2,
        editBadgeOffset: Math.ceil(this.size)/4
      }
    },
  },
}
</script>