import gql from 'graphql-tag';

export default gql`
    query getUserNotifications($notification_ids:[ID], $mark_as_read: Boolean){
        getUserNotifications(notification_ids: $notification_ids, mark_as_read: $mark_as_read ){
            id
            type
            title
            text
            time
            timestamp
            read
            read_at
            metadata {
                key
                value
            }
        }
    }`;