<template>
  <v-dialog
      v-model="dialog"
      width="500"
      scrollable
      persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="$attrs.activator"
          v-on="on"
      >
        <slot><v-icon>mdi-plus</v-icon> Add Property </slot>
      </v-btn>
    </template>
    <property-form :user-id="user" @property-created="propertyCreated" @error="handleError">
      <template #heading>
        <div style="width: 100%">
          <h4>New Property</h4>
<!--          <dashboard-user-select-->
<!--              label="Property owner"-->
<!--              v-model="user"-->
<!--              :rules="[(value) => !!value || 'Select a user']"-->
<!--              outlined dense-->
<!--              clearable-->
<!--              class="mt-3"-->
<!--          />-->
        </div>
      </template>
      <template #actions="{ submit, loading }">
        <v-btn color="red" @click="dialog = false" text>Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn
            @click.prevent="dummySubmit"
            color="primary"
            elevation="0"
            :loading="loading"
        >Create Property</v-btn>
      </template>
    </property-form>
  </v-dialog>
</template>

<script>
import PropertyForm from "@/domain/Property/Components/PropertyForm";
// import DashboardUserSelect from "@/domain/Dashboard/Components/UserSelect";
export default {
  name: "CreateProperty",
  components: {
    // DashboardUserSelect,
    PropertyForm
  },
  data() {
    return {
      dialog: false,
      user: null,
    }
  },
  methods: {
    dummySubmit() {
      this.$store.commit('SNACKBAR', {
        status: true,
        text: "Can't create property from dashboard yet",
        color: "info"
      })
    },
    propertyCreated(property) {
      this.dialog = false;
      const system = this.$store.getters.dashboard;
      system.properties.unshift(property);
      this.$store.commit("SET_DASHBOARD_DATA", system);
    },
    handleError(e){
      this.$store.commit("SNACKBAR", {
        status: true,
        color: 'error',
        text: `Something went wrong creating the property`
      })
    }
  }
}
</script>