var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.tableHeaders,"items":_vm.reservations,"search":_vm.search},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{name: 'dashboard.reservation.show', params:{ id: item.id }}}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.channel",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("nullable")((item.booking_channel ? item.booking_channel.name : null)))+" ")]}},{key:"item.guests",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("nullable")((item.guests ? item.guests.length : null)))+" ")]}},{key:"item.property",fn:function(ref){
var item = ref.item;
return [(item.property && item.property.id)?_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{name: 'dashboard.property.show', params:{id: item.property.id}}}},[_vm._v(" "+_vm._s(item.property.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("nullable")((item.property ? item.property.name : null)))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('reservation-status',{attrs:{"reservation":item}})]}},{key:"item.timestamp.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentFromTimestamp(item.timestamp.created_at).format('MMM D, YYYY'))+" ")]}},{key:"item.checkin_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentFromDate(item.checkin_date).format('MMM D, YYYY'))+" ")]}},{key:"item.checkout_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentFromDate(item.checkout_date).format('MMM D, YYYY'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('reservations-table-admin-options',{attrs:{"reservation":item}})]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }