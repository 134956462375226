import gql from 'graphql-tag';

export default gql`
    query getUserStripeVerification($user_id: ID, $verification_id: ID!){
        getUserStripeVerification(user_id: $user_id, verification_id: $verification_id) {
            id
            property_id
            session
            report
            status
            url
            type
            metadata {
                user_id
                property_id
                reservation_id
                stripe_account
            }
        }
    }`;