var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.tableHeaders,"items":_vm.history,"search":_vm.search},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentFromTimestamp(item.created_at).format("lll"))+" ")]}},{key:"item.property.name",fn:function(ref){
var item = ref.item;
return [(item.property)?[_c('v-avatar',{staticClass:"mr-1",attrs:{"size":"40"}},[_c('img',{attrs:{"alt":item.property.name,"src":item.property.image}})]),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{name: 'dashboard.property.show', params: {id: item.property.id}}}},[_vm._v(" "+_vm._s(item.property.name)+" ")])]:_c('span',[_vm._v(" "+_vm._s(_vm._f("nullable")(null))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusColor[item.status.toLowerCase()],"small":"","dark":"","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.purchased_by.full_name",fn:function(ref){
var item = ref.item;
return [(item.purchased_by)?[_c('v-avatar',{staticClass:"mr-1",attrs:{"size":"40"}},[_c('img',{attrs:{"alt":item.purchased_by.full_name,"src":item.purchased_by.image}})]),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{name: 'dashboard.user.show', params: {id: item.purchased_by.id}}}},[_vm._v(" "+_vm._s(item.purchased_by.full_name)+" ")])]:_c('span',[_vm._v(_vm._s(_vm._f("nullable")(null)))])]}},{key:"item.property.checkin_points",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("nullable")((item.property ? item.property.checkin_points : null)))+" ")]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }