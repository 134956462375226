<template>
    <div>
        <slot name="heading" />
        <data-container v-if="property && property.active" :loading="loading">
            <template v-slot:loading>
                <div  v-for="i in 4" :key="i">
                    <v-skeleton-loader
                        type="card"
                        height="50"
                        class="my-2"
                    ></v-skeleton-loader>
                </div>
            </template>

            <div class="d-flex justify-end my-3">
                <v-btn color="primary"  title="Add new question" @click="createNew = !createNew"><v-icon dark> mdi-plus</v-icon> Create New</v-btn>
            </div>

            <property-checkin-question-form
            v-if="createNew"
            :property="property"
            :allow-attachment="false"
            @created="questionCreated"
            @cancel="createNew = false"
            class="my-1">
              <template #option-attachment>
                <small class="grey--text">You can attach charges, agreement and question when question is saved </small>
              </template>
            </property-checkin-question-form>

            <div  v-if="questions.length">
                <property-checkin-question v-for="question in questions"
                :key="question.id"
                :question="question"
                :property="property"
                :active="lastCreated && question.id === lastCreated.id"
                @removed="questionRemoved" class="my-1" >
                  <template #parent-question="{ id }">
                    <small v-show="!!id">Follow up question to {{ id }}</small>
                  </template>
                </property-checkin-question>
            </div>
            <div  v-else class="py-5 text-center">
                <p class="grey--text">No question</p>
            </div>

        </data-container>
    </div>

</template>
<script>
import DataContainer from '../../../components/DataContainer.vue';
import PropertyCheckinQuestion from '../Components/PropertyCheckinQuestion.vue';
import PropertyCheckinQuestionForm from '../Components/PropertyQuestionForm.vue';
import GET_PROPERTY_CHECKIN_QUESTIONS from '../Queries/getPropertyCheckinQuestions';
import formValidation from '@/helper/formValidation';

export default {
    name: "PropertyCheckinQuestions",
    components: {
        DataContainer, PropertyCheckinQuestion,
        PropertyCheckinQuestionForm
    },

    props: {
        property: Object
    },

    data(){
        return {
            loading: false,
            saving: false,
            rules: formValidation.rules,
            questions: [],
            collapseAll: false,
            createNew: false,
            lastCreated: null,
        }
    },  

    methods: {
        getQuestions(){
            this.loading = true;
             this.$store.dispatch('query', {
                query: GET_PROPERTY_CHECKIN_QUESTIONS,
                variables: {
                    property_id: this.property.id
                }
            })
            .then(response => {
                this.questions  = (response?.data?.getPropertyCheckinQuestions || [])
                    .filter(q => !q.parent_question);
            })
            .catch(e => {
                this.$store.commit('TOAST_ERROR', {
                    show: true,
                    retry: () => this.getQuestions(),
                    message: 'Could not get property checkin questions ',
                    exception: e
                })
            })
            .finally(() => {
                this.loading = false;
            })
        },

        addNewQuestion(){
            this.questions.push({
                question: null,
                options: [],
                required: false,
            });
        },

        questionCreated(question) {
            this.questions.unshift(question);
            this.createNew = false;
            this.lastCreated = question;
        },

        questionRemoved(question) {
            const index = this.questions.findIndex(q => q.id === question.id);
            if(index >= 0) this.questions.splice(index, 1);
        }
    },

    watch: {
        property: {
            immediate: true,
            handler(property){
                if(property && property.active) this.getQuestions()
                else this.loading = false;
            }
        }
    }
}
</script>