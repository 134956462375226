var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.preview)?_c('property-agreement-list',{attrs:{"agreements":_vm.selected},scopedSlots:_vm._u([{key:"list-item-content",fn:function(ref){
var agreement = ref.agreement;
return [_vm._t("list-item-content",null,null,{ agreement: agreement })]}}],null,true)}):_vm._e(),_vm._t("after-selected",null,null,{ selected: _vm.selected }),(_vm.changeable)?_c('div',[_c('v-autocomplete',_vm._g(_vm._b({attrs:{"loading":_vm.loading,"items":_vm.items,"no-data-text":("" + (_vm.loading ? 'Please wait...': "No agreement."))},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":_vm.selected.map(function (s) { return s.id; }).includes(item.id)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.agreement))]),(item.link)?_c('v-list-item-subtitle',[_vm._v(_vm._s(item.link))]):_vm._e()],1)],1)]}}],null,false,3057762995),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},'v-autocomplete',_vm.$attrs,false),_vm.$listeners)),(_vm.createNew)?_c('div',{staticClass:"mt-n5 d-flex justify-end"},[_c('v-dialog',{attrs:{"max-width":"550","scrollable":"","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","title":"create new agreement","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add New Agreement ")],1)]}}],null,false,1517725041),model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_c('h4',[_vm._v("New Agreement")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.createDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('property-agreement-form',{staticClass:"mt-2",attrs:{"property":_vm.property},on:{"created":_vm.agreementCreated,"cancel":function($event){_vm.createDialog = false}}})],1)],1)],1)],1):_vm._e()],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }