<template>
    <div>
        <slot name="heading" />
        <data-container :loading="loading" class="mt-5">
          <v-card outlined>
            <v-card-title>
              Information to collect
            </v-card-title>
            <v-card-text>
              <div class="d-flex flex-wrap">
                <v-switch
                    v-for="info in fields" :key="info.value"
                    v-model="setting.guest_info"
                    inset
                    :label="info.label"
                    :value="info.value"
                    multiple
                    class="px-5"
                >
                </v-switch>
              </div>
            </v-card-text>
          </v-card>

          <v-card outlined class="my-3">
            <v-card-title>
              ID Verification
            </v-card-title>
            <v-card-text>
              <v-radio-group
                  v-model="setting.id_verification_provider"
                  column
              >
                <v-radio value="upload">
                  <template #label>
                    <v-card flat>
                      <v-card-title class="text-sm">Upload ID</v-card-title>
                      <v-card-text>
                        Guest will be asked to upload an image of their ID
                      </v-card-text>
                    </v-card>
                  </template>
                </v-radio>

                <v-radio value="stripe"
                         :disabled="true"
                >
                  <template #label>
                    <v-card disabled flat>
                      <v-card-title>Connected Stripe</v-card-title>
                      <v-card-text>
                        ID verification is carried from your connected Stripe account
                        <v-alert
                            colored-border
                            border="left"
                            type="error">
                          Not available yet
                        </v-alert>
                      </v-card-text>
                    </v-card>
                  </template>
                </v-radio>

                <v-radio value="smile"
                         :disabled="!property.integrations.includes('smile')"
                >
                  <template #label>
                    <v-card disabled flat>
                      <v-card-title>Smile Identity</v-card-title>
                      <v-card-text v-if="!property.integrations.includes('smile')">
                        <v-alert
                            colored-border
                            border="left"
                            type="error">
                          Smile is not not enabled for {{ property.name }}
                        </v-alert>
                      </v-card-text>
                    </v-card>
                  </template>
                </v-radio>

                <v-radio value="global">
                  <template #label>
                    <v-card flat>
                      <v-card-title>Global</v-card-title>
                      <v-card-text>
                        ID verification is carried out for you by Guest Registration
                      </v-card-text>
                    </v-card>
                  </template>
                </v-radio>

              </v-radio-group>
            </v-card-text>
          </v-card>
            <!-- <smile-id /> -->

            <v-btn color="primary" @click="updateSetting" :loading="saving">Save change</v-btn>
        </data-container>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import DataContainer from '@/components/DataContainer.vue';
import SmileId from '@/components/Utilities/SmileID.vue';

import GET_PROPERTY_RESERVATION_SETTING from '../Queries/getPropertyReservationSetting';
import UPDATE_PROPERTY_RESERVATION_SETTING from '../Mutations/updatePropertyReservationSetting';

export default {
    name: "PropertyGuestInfoSetting",
    components:{
        DataContainer, 
        // SmileId
    },
    data() {
        return {
            loading: false,
            saving: false,
            setting: {},
            fields: [
                {
                    label: 'Gender',
                    value: 'gender'
                },
                {
                    label: 'Address',
                    value: 'address'
                },
                {
                    label: 'Phone',
                    value: 'phone'
                },
                {
                    label: 'Email',
                    value: 'email'
                },
                {
                    label: 'Age',
                    value: 'age'
                },
            ],
        }
    },
    props: {
        property: Object
    },

    methods: {
        ...mapActions([
            'query',
            'mutate'
        ]),

        getSetting() {
            this.loading = true;
            this.query({
                query: GET_PROPERTY_RESERVATION_SETTING,
                variables: {
                    property_id: this.property.id
                }
            })
            .then(response => {
                this.setting = response.data.getPropertyReservationSetting ?? {}
            })
            .catch(e => {
                this.$store.commit('TOAST_ERROR', {
                    show: true,
                    retry: () => this.getSetting(),
                    message: 'Could not get setting.',
                    exception: e
                })
            })
            .finally(() => {
                this.loading = false;
            });
        },

        updateSetting() {
            this.saving = true;
            this.mutate( {
                mutation: UPDATE_PROPERTY_RESERVATION_SETTING,
                variables: {
                    property_id: this.property.id,
                    data: this.setting,
                }
            })
            .then(response => {
                this.setting = response.data.updatePropertyReservationSetting ?? {};

                this.$store.commit("SNACKBAR", {
                    status: true,
                    text: "Settings updated",
                    color: "success"
                })
            })
            .catch(e => {
                this.$store.commit('TOAST_ERROR', {
                    show: true,
                    retry: () => this.getSetting(),
                    message: 'Could not update setting.',
                    exception: e
                })
            })
            .finally(() => {
                this.saving = false;
            });
        }
    },

    watch: {
        property: {
            immediate: true,
            handler(property){
                if(property) this.getSetting();
                else this.loading = true;
            }
        },
    }
}
</script>