<template>
    <div>
        <property-user-form
        ref="propertyUserInvite"
        :property="property"
        @created="userCreated"
        />
        <div class="d-flex justify-space-between">
            <div><slot name="heading" /></div>
            <!-- invite -->
            <v-btn color="primary"
            @click="$refs.propertyUserInvite.open()"
            ><v-icon class="mr-2">mdi-account-plus</v-icon> Add user</v-btn>
        </div>
        
        <data-container v-if="property" :loading="loading">
            <v-list v-if="users.length" three-line>
                <template v-for="(user, index) in users">
                    <property-user :key="user.id" :property="property" :user="user" @user-removed="userRemoved" />
                    <v-divider :key="index" v-if="index < (users.length - 1)"></v-divider>
                </template>
            </v-list>
            <div v-else class="pa-5 text-center">
                No user
            </div>
        </data-container>
    </div>
</template>

<script>
import DataContainer from '../../../components/DataContainer.vue';
import PropertyUserForm from '../Components/PropertyUserForm.vue';
import PropertyUser from '../Components/PropertyUser.vue';
import GET_PROPERTY_USER from '../Queries/getPropertyUsers';

export default {
    name: "PropertyUsers",
    components: {
        DataContainer, PropertyUserForm, PropertyUser
    },
    props: {
        property: Object,
    },
    data(){
        return {
            loading: true,
            users: [],
        }
    },
    computed: {
        propertyPermissions() {
            return this.permissions && this.permissions.property ? this.permissions.property : []
        },

        reservationPermissions() {
            return this.permissions && this.permissions.reservation ? this.permissions.reservation : []
        }
    },

    methods: {
        getPropertyUsers() {
            this.loading = true;
           
            this.$store.dispatch('query', {
                query: GET_PROPERTY_USER,
                variables: {
                    property_id: this.property.id
                }
            })
            .then(response => {
                this.users = response.data.getPropertyUsers
            })
            .catch(e => {
                this.$store.commit('TOAST_ERROR', {
                    show: true,
                    retry: () => this.getPropertyUsers(),
                    message: 'Could not get property users',
                    exception: e
                })
            })
            .finally(() => {
                this.loading = false;
            })
        },
        userCreated(user) {
            this.users.push(user);
            this.$refs.propertyUserInvite.close();
        },
        userRemoved(user) {
            this.users.splice(this.users.findIndex(u => u.id === user.id), 1);
        }
    },

    watch: {
        property: {
            immediate: true,
            handler(property){
                if(property) this.getPropertyUsers();
                else this.loading = false;
            }
        }
    }
}
</script>