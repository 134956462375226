<template>
    <app-layer ref="app" :breadcrumbs="breadcrumbs">
        <template #header>
          <v-row justify="center">
            <v-col cols="12" md="8">
              <h4 class="headline">Account</h4>
              <v-chip small color="primary" outlined>
                {{ current_user.auth.uid }}
              </v-chip>
            </v-col>
          </v-row>
        </template>
        <v-row justify="center">
            <v-col cols="12" md="8">
              <responsive-tabs
                      v-model="currentTab"
                      @change="tabChanged"
                      color="primary"
                      show-arrows>
                      <template #tabs >
                        <v-tab v-for="tab in tabs" :key="tab.name" :disabled="tab.disabled" class="justify-start">
                          <v-icon flex>
                            {{ tab.icon }}
                          </v-icon>
                          <span>{{ tab.name }} </span>
                        </v-tab>
                      </template>

                      <template #items>
                        <v-tab-item class="pa-5">
                          <user-profile
                              :key="tabsVersions.profile"
                              :form="profileForm"
                              @update="f => {
                                profileForm = f;
                                tabsVersions.profile++
                              }"
                              @saved="profileUpdated">
                            <template #actions="{ loading, submitting, submit, canSubmit }">
                              <v-card-actions class="mt-5">
                                <v-spacer></v-spacer>
                                <v-btn
                                    :loading="submitting"
                                    :disabled="loading || !canSubmit"
                                    color="primary"
                                    @click="submit()"
                                >
                                  Save Changes
                                </v-btn>
                              </v-card-actions>
                            </template>
                          </user-profile>
                        </v-tab-item>

                        <v-tab-item class="pa-5">
                          <user-personal-info @submitted="accountReport({  message: 'Information updated', type: 'success' })"  />
                        </v-tab-item>

<!--                        <v-tab-item class="pa-5">-->
<!--                          <account-connects @report="accountReport" @auth-updated="(credentials) => syncAuthWithProfile(credentials)" @notification="sendAccountPushNotification" />-->
<!--                        </v-tab-item>-->
                      </template>
                    </responsive-tabs>
            </v-col>
        </v-row>
    </app-layer>
</template>

<script>
    import AppLayer from '@/AppLayer';
    import UserProfile from '../Components/PersonalInfoForm';
    import UserPersonalInfo from '../Settings/PersonalInfo.vue'
    import ResponsiveTabs from '@/components/ResponsiveTabs';

    import PUSH_NOTIFICATION from '@/graphql/mutation/pushNotification'
    import profile from '../Mixins/profile2';
    // import AccountConnects from "@/domain/Auth/Components/AccountConnects";

    export default {
        name: "UserAccount",
        mixins: [profile],
        components: {
          // AccountConnects,
            AppLayer, UserProfile, UserPersonalInfo,
          ResponsiveTabs
        },
        data(){
            return {
              vertical: false,
              tabsVersions: {
                  profile: 1
              },
              profileForm: null,
            }
        },
        computed: {
            tabs(){
                return [
                    {
                        name: 'Profile',
                        route: {name: this.$route.name, params: {tab: 'profile'}},
                        disabled: false,
                        icon: 'mdi-account'
                    },
                     {
                        name: 'Personal info',
                        route: {name: this.$route.name, params: {tab: 'personal-info'}},
                        disabled: false,
                        icon: 'mdi-check-decagram'
                    },
                    // {
                    //     name: 'Authentication',
                    //     route: {name: this.$route.name, params: {tab: 'authentication'}},
                    //     disabled: false,
                    //     icon: 'mdi-shield-account'
                    // },
                ]
            },

            tab:{
                get: function() {
                    return this.$route.params.tab ?  this.$route.params.tab  : 'profile';
                },
                set: function(tab){}
            },

            currentTab: {
                get: function(){
                    return this.tabs.findIndex(t => t.route.params.tab == this.tab)
                },
                set: function(tab){}
            },

          breadcrumbs(){
            return [
              {
                text: "Account",
                to: this.$route
              },
              {
                text: this.tabs[this.currentTab].name,
                to: this.$route
              }
            ]
          }
        },

        methods: {
            accountReport(report){
                this.$store.commit("SNACKBAR", {
                    status: true,
                    text: report.message,
                    color: report.type
                })
            },
            
            sendAccountPushNotification(payload){
                this.mutate({
                    variables: {
                        user_id: this.current_user.auth.uid,
                        ...payload
                    },
                    mutation: PUSH_NOTIFICATION
                })
                .then(response => {
                    //console.log(response);
                })
                .catch(e => {
                    //console.log(e);
                })
            },

            tabChanged(tab){
                if(!this.tabs[tab].route) return;
                this.$router.push(this.tabs[tab].route)
            },

            profileUpdated() {
              this.accountReport({  message: 'Profile updated', type: 'success' });
              if(this.$route.query.redirect) {
                this.$router.push({ path: this.$route.query.redirect });
              }
            }

        },
    }
</script>


