var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('reservation-status-filter',{attrs:{"chip":{small: true},"display":"tile"},model:{value:(_vm.reservationStatusFilter),callback:function ($$v) {_vm.reservationStatusFilter=$$v},expression:"reservationStatusFilter"}}),_c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"items":_vm.filteredHistory,"search":_vm.search,"height":"410"},scopedSlots:_vm._u([{key:"item.booking.name",fn:function(ref){
var item = ref.item;
return [(item.reservation)?_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{attrs:{"color":"primary","size":"30"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.reservation.name.substring(0,1)))])]),_c('div',{staticClass:"ml-2"},[_vm._v(_vm._s(item.reservation.name))])],1):_c('span',[_vm._v(_vm._s(_vm._f("nullable")(null)))])]}},{key:"item.checkin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("nullable")(( item.reservation ? item.reservation.checkin_date : null)))+" ")]}},{key:"item.points",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("nullable")(Math.abs(item.points)))+" ")]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [(item.reservation)?_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"color":"primary","icon":"","to":{name: 'property.reservation.show', params: { id: item.reservation.id }, query: { edit: 1 }}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pen")])],1),_c('v-btn',{attrs:{"color":"primary","icon":"","to":{name: 'property.reservation.show', params: { id: item.reservation.id }}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)],1):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.reservation)?_c('reservation-status',{attrs:{"reservation":item.reservation}}):_c('span',[_vm._v(_vm._s(_vm._f("nullable")(null)))])]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }