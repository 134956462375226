<template>
      <profile-avatar
          v-if="provider"
          :size="size"
          color="primary"
          title="Phone verified"
          @click="$emit('disconnect')"
      >
        <template #icon>
          <v-icon :size="Math.ceil(size/2)" class="white--text">mdi-phone </v-icon>
        </template>
      </profile-avatar>
      <profile-avatar
          v-else
          :size="size"
          color="error"
          title="Phone not verified"
          @click="$emit('connect')"
      >
        <template #icon>
          <v-icon :size="Math.ceil(size/2)" class="white--text">mdi-phone</v-icon>
        </template>
      </profile-avatar>
</template>
<script>

import ProfileAvatar from "@/components/ProfileAvatar";

export default {
  name: "PhoneConnectBadge",
  components: {ProfileAvatar},
  props: {
    provider: Object,
    size: {
      default: () => 40
    },
  },
  computed: {
    sizeRatio() {
      return {
        progressCircular: Math.ceil(this.size)/2,
        editBadgeOffset: Math.ceil(this.size)/4
      }
    },
  },
}
</script>