<template>
    <user-personal-info v-if="!capture"
    :property="reservation.setting.id_verification_provider === 'global' ? null : property"
    :existing-verification="verification.info ? verification : null"
    @submitted="personalInfoSubmitted"
    @verification="personalInfoSubmitted"  />
    <data-container v-else :loading="loading">
        <v-alert
        v-if="alert"
        colored-border
        border="left"
        :color="alert.color"
        >
            {{ alert.message }}
        </v-alert>

        <v-btn small text color="primary" 
        class="my-1"
        @click="capture = false"> 
            <v-icon>mdi-account-edit</v-icon> Update Info
        </v-btn>

        <template v-if="provider == 'stripe'" >
            <stripe-verification
            :verification="verification.stripe"
            class="my-1"
            @result="onStripeVerificationResult"
            >
            </stripe-verification>

          <div class="d-flex">
            <v-btn v-if="verification.stripe && !['requires_input', 'verified'].includes(verification.stripe.status)"
                   class="mx-1"
                   @click="getPropertyUserIdVerification"
                   color="primary" small text>
              Check status again
            </v-btn>
            <run-identity-verification
                v-if="!verificationSubmission.stripe"
                :property="property"
                :use-connected-account="reservation.setting.id_verification_provider == 'stripe'"
                :metadata="{ reservation_id: reservation.id }"
                :redirect="url(callbackUrl)"
                class="mx-1"
                @processing="() => canContinue = false"
                @result="onStripeVerificationResult"
            >
             Start Verification
            </run-identity-verification>
          </div>

        </template>

        <template v-else-if="provider === 'smile'">
            <smile-verification
            :property="property"
            :reservation="reservation"
            :result="verificationSubmission.smile ? verification.smile : null"
            :id-info="info"
            @submitted="smileVerificationSubmitted" />
        </template>

        <template v-else-if="provider == 'upload'">
            <template v-if="verificationSubmission.upload">
                <uploaded-id  @uploaded="IdUploaded" :upload="verification.upload" :can-upload="true">
                view uploaded ID
                </uploaded-id>
            </template>
            <template v-else>
                <upload-id  @uploaded="IdUploaded">Upload ID</upload-id>
            </template>
        </template>

      <div class="d-flex justify-end mt-5">
            <v-btn
                color="primary"
                @click="confirmVerification"
                :loading="confirming"
                :disabled="!(verificationSubmission[provider] && canContinue)"
                depressed
            >Continue</v-btn>
        </div>
    </data-container>
</template>

<script>
import gql from 'graphql-tag';
import { mapActions } from 'vuex';
import formValidation from '@/helper/formValidation';
import StripeVerification from '../../../User/Components/StripeVerification.vue';
import RunIdentityVerification from '../../../User/Components/RunIdentityVerification.vue';
import SmileVerification from '../../../User/Components/SmileVerification.vue';
import DataContainer from '../../../../components/DataContainer.vue';
import UserPersonalInfo from '../../../User/Settings/PersonalInfo.vue';
import UploadedId from '../../../User/Components/UploadedID.vue';
import UploadId from '../../../User/Components/UploadUserID.vue';
import session from "@/domain/Reservation/Mixins/session";

export default {
    name: "ReservationIDVerification",
    mixins: [session],
    components: {
        StripeVerification, 
        RunIdentityVerification,
        SmileVerification,
        DataContainer,
        UserPersonalInfo,
        UploadedId,
        UploadId
    },
    data() {
        return {
            loading: false,
            submitting: false,
            rules: formValidation.rules,
            capture: false,
            info: null,
            verification: {
                info: null,
                stripe: null,
                smile: null,
                upload:null
            },
            confirming: false,
            provider: 'upload',
            alert: null,
            canContinue: true,
        }
    },
    props: {
        reservation: Object,
        property: Object,
        callbackUrl: String,
        mustBeVerified: Boolean,
        confirmFirst: Boolean,
    },

    computed: {
        stripe() {
            return this.verification ? this.verification.stripe : null;
        },

        smile() {
            return this.verification ? this.verification.smile : null;
        },

        reservationIdProvider() {
            return this.reservation && this.reservation.setting ? this.reservation.setting.id_verification_provider : null
        },

        verificationSubmission() {
            return {
                stripe: this.verification && this.verification.stripe,
                smile: this.verification && this.verification.smile,
                upload: this.verification && this.verification.upload && this.verification.upload.url
            };
        },

        verificationStatus() {
            const submission = this.verificationSubmission;
            return {
                stripe: submission.stripe 
                    && this.verification.stripe.status 
                    && this.verification.stripe.status == 'verified',
                smile: submission.smile 
                    && this.verification.smile.response_code
                    && !["1020", "1021"].includes(this.verification.smile.response_code),
                upload: submission.upload
            }
        }
    },

    methods: {
        ...mapActions([
            'query', 'mutate'
        ]),

        personalInfoSubmitted(verification) {
            this.loading = true;
            this.queryVerification().then(v => {
                if(v.info) this.capture = true;
            }).finally(() => {
              this.loading = false;
            })
            this.createSessionActivity({
              title: "Personal Info Submitted",
              description: "Personal information for ID verification submitted"
            })
        },

        queryVerification() {
            return new Promise((resolve, reject) => {
                this.query({
                    query: gql `
                    query getUserIdVerification($user_id: ID, $property_id: ID) {
                        getUserIdVerification(user_id: $user_id, property_id: $property_id) {
                            user_id
                            property_id
                            info {
                                first_name
                                last_name
                                country
                                id_type
                                id_number
                                dob
                                phone_number
                            }
                            stripe {
                                property_id
                                session
                                report
                                status 
                                url
                                type
                                metadata {
                                    user_id
                                    property_id
                                    reservation_id
                                    stripe_account
                                }
                                last_updated
                            }
                            upload {
                                url
                                verified_by {
                                    property {
                                        id
                                        name
                                    }
                                    user {
                                        id
                                        name {
                                            first_name
                                            last_name
                                        }
                                    }
                                }
                                verified_at
                            }
                            smile {
                                result_id
                                result_text
                                result_code
                                last_updated
                                result {
                                    ResultCode
                                    ResultText
                                    Actions {
                                        Liveness_Check
                                        Register_Selfie
                                        Selfie_Provided
                                        Verify_ID_Number
                                        Human_Review_Compare
                                        Return_Personal_Info
                                        Selfie_To_ID_Card_Compare
                                        Human_Review_Update_Selfie
                                        Human_Review_Liveness_Check
                                        Selfie_To_ID_Authority_Compare
                                        Update_Registered_Selfie_On_File
                                        Selfie_To_Registered_Selfie_Compare
                                    }
                                }
                            }
                        }
                    }
                    `,
                    variables: {
                        user_id: this.$store.getters.current_user.profile.id,
                        property_id: this.reservation.setting.id_verification_provider == 'global' ? undefined : this.property.id,
                    }
                })
                .then(response => {
                    const verification = response.data.getUserIdVerification;
                    if(verification) {
                        this.verification = verification;
                        this.info = verification.info
                    }
                    resolve(verification);
                })
                .catch(e => reject(e))
            })
        },

        getPropertyUserIdVerification() {
            this.loading = true;
            this.queryVerification()
            .finally(() => {
                this.loading = false;
            })
        },

        setUserIdVerification(verification) {
            return this.mutate({
                mutation: gql `
                mutation setUserIdVerification($user_id: ID, $property_id: ID, $data: UserIdVerificationInput) {
                    setUserIdVerification(user_id: $user_id, property_id: $property_id, data: $data) {
                        property_id
                        info {
                            first_name
                            last_name
                            country
                            id_type
                            id_number
                            dob
                            phone_number
                        }
                        stripe {
                            property_id
                            session
                            report
                            status 
                            url
                            type
                            metadata {
                                user_id
                                property_id
                                reservation_id
                                stripe_account
                            }
                        }
                        upload {
                            url
                            verified_by {
                                property {
                                    id
                                    name
                                }
                                user {
                                    id
                                    name {
                                        first_name
                                        last_name
                                    }
                                }
                            }
                            verified_at
                        }
                        smile {
                            result_id
                            result_text
                            result_code
                            last_updated
                            result {
                                ResultCode
                                ResultText
                                Actions {
                                    Liveness_Check
                                    Register_Selfie
                                    Selfie_Provided
                                    Verify_ID_Number
                                    Human_Review_Compare
                                    Return_Personal_Info
                                    Selfie_To_ID_Card_Compare
                                    Human_Review_Update_Selfie
                                    Human_Review_Liveness_Check
                                    Selfie_To_ID_Authority_Compare
                                    Update_Registered_Selfie_On_File
                                    Selfie_To_Registered_Selfie_Compare
                                }
                            }
                        }
                    }
                }
                `,
                variables: {
                    user_id: this.$store.getters.current_user.profile.id,
                    property_id: this.reservation.setting.id_verification_provider === 'global' ? undefined : this.property.id,
                    data: verification
                }
            })
        },

        smileVerificationSubmitted(result) {
            this.verification.smile = result;
        },

        onStripeVerificationResult(result) {
            this.createSessionActivity({
              title: "Stripe Verification Result",
              description: "Stripe verification completed and result received. "+JSON.stringify(result)
            })
            this.confirmVerification()
        },

        confirmVerification() {
            this.createSessionActivity({
              title: "ID verification confirmation",
              description: "Verifying submitted ID"
            })
            this.confirming = true
            this.queryVerification()
            .then((verification) => {
                if(!this.mustBeVerified) {
                    this.$emit('completed', true);
                }
                let verified = false;
                if(this.provider == 'stripe') {
                  this.createSessionActivity({
                    title: "Stripe ID Verification Confirmation",
                    description: `Stripe verification status is ${this.verificationSubmission.stripe?.status}`
                  })
                  verified = this.verificationStatus.stripe
                }

                if(this.provider == 'smile') {
                  this.createSessionActivity({
                    title: "Smile ID Verification Confirmation",
                    description: `Smile verification response code is ${this.verificationSubmission.smile?.response_code}`
                  })
                  verified = this.verificationStatus.smile;
                }

                if(this.provider == 'upload') {
                  this.createSessionActivity({
                    title: "ID Upload Confirmation",
                    description: `An ID file was uploaded at ${this.verificationSubmission.upload}`
                  })
                  verified = this.verificationStatus.upload;
                }

                this.$emit('verified', verified)

                if(!verified) {
                    if(this.provider == 'upload' 
                    && !(verification && verification.upload && verification.upload.url)) {
                        this.alert = {
                            message: 'The ID upload was  not completed, please try again',
                            color: 'error'
                        }
                    } else if(verification && verification.upload && verification.upload.url) {
                        this.alert = {
                            message: 'We have not been able to confirm your ID verification at the moment, ' +
                                'Your uploaded ID will be used for now',
                            color: 'info'
                        }
                    } else {
                      this.alert = {
                        message: 'We have not been able to confirm your ID verification at the moment, ' +
                            'please upload an image of your ID for check later',
                        color: 'warning'
                      }
                    }
                    this.provider = 'upload';
                }               
            })
            .finally(() => {
                this.confirming = false
            })
        },

        IdUploaded(upload) {
          this.loading = true;
           this.setUserIdVerification({
               property_id: this.reservation.setting.id_verification_provider === 'global' ? undefined : this.property.id,
               upload: {
                   url: upload.file_url,
                   verified_by: null,
                   verified_at: null,
               }
           })
           .then(response => this.confirmVerification())
           .finally(() => {
             this.loading = false
           })
        },
    },

    mounted() {
        if(this.confirmFirst) this.confirmVerification()
    },

    watch: {
        verification: {
            immediate: false,
            handler(v){
                this.$emit('verification', v);
            }
        },

        reservation: {
            immediate: true,
            handler(reservation) {
                if(reservation && reservation.setting) {
                    if(reservation.setting.id_verification_provider == 'global') {
                        this.provider = 'stripe'
                    } else {
                        this.provider = reservation.setting.id_verification_provider
                    }
                }
            }
        }
    }
}
</script>