import gql from 'graphql-tag';

export default gql`
    query getReservation($id: ID!){
        getReservation(id: $id){
            id
            user_id
            name
            phone
            email
            balance
            currency
            room
            booking_reference
            booking_channel {
                id
                label
            }
            active
            status
            checkedin
            already_checkedin
            checkedin_at
            approved
            approved_at
            approved_by {
                id
                email
                name {
                    first_name
                    last_name
                }
            }
            checkedout
            checkedout_at
            checkedout_by {
                id
                email
                name {
                    first_name
                    last_name
                }
            }
            cancelled
            cancelled_at
            cancelled_by {
                id
                email
                name {
                    first_name
                    last_name
                }
            }
            checkin_date
            checkout_date
            instruction
            checkin_url
            guest_checkin_url
            property_id
            property{
                id
                name
                address
                image
                property {
                    id
                    user_id
                    name
                    rules
                    terms
                    email
                    phone
                    image
                    phone_meta {
                        country_code
                        phone_number
                        complete_phone
                    }
                    full_address
                    address {
                        street
                        city
                        country
                        postal_code
                    }
                    integrations
                    subscription {
                        status
                        trial_start
                        trial_end
                        current_period_start
                        current_period_end
                    }
                    active
                    default_currency
                    stripe_connected
                }
            }
            guests{
                id
                name
                type
                user_id
                can_verify_id
                checkedin_at
                user {
                    id
                    email
                    name {
                        first_name
                        last_name
                    }
                }
                info {
                    age
                    gender
                    phone
                    email
                    address
                }
                verification{
                    property_id
                    info {
                        first_name
                        last_name
                        country
                        id_type
                        id_number
                        dob
                        phone_number
                    }
                    stripe {
                        type
                        status
                        session
                        report
                        metadata {
                            user_id
                            property_id
                            reservation_id
                        }
                        last_updated
                    }
                    upload {
                        url
                        verified_at
                        verified_by {
                            property {
                                id
                                name
                            }
                            user {
                                id
                                name {
                                    first_name
                                    last_name
                                }
                            }
                        }
                    }
                    smile {
                        result_id
                        result_text
                        result_code
                        last_updated
                        result {
                            ResultCode
                            ResultText
                            Actions {
                                Liveness_Check
                                Register_Selfie
                                Selfie_Provided
                                Verify_ID_Number
                                Human_Review_Compare
                                Return_Personal_Info
                                Selfie_To_ID_Card_Compare
                                Human_Review_Update_Selfie
                                Human_Review_Liveness_Check
                                Selfie_To_ID_Authority_Compare
                                Update_Registered_Selfie_On_File
                                Selfie_To_Registered_Selfie_Compare
                            }
                        }
                    }
                }
            }
            charges{
                id
                title
                amount
                description
                type
                enable
                optional
                currency
                unit
                multipliers {
                    min
                    max
                    unit
                }
            }
            agreements {
                id
                agreement
                link
                text
            }

            # Questions start
            questions {
                id
                question
                response_type
                required
                options {
                    option
                    attachments {
                        agreements {
                            id
                            agreement
                            link
                        }
                        charges {
                            id
                            type
                            title
                            amount
                            description
                            enable
                            optional
                            currency
                            unit
                            multipliers {
                                min
                                max
                                unit
                            }
                        }
                        questions {
                            id
                            question
                            response_type
                            required
                            options {
                                option
                            }
                        }
                    }
                }

            }
            # Questions end


            user {
                id
                email
                full_name
                name {
                    first_name
                    last_name
                }
            }
            require_id_verification
            require_credit_card
            allow_additional_guest
            auto_checkin_approval
            setting {
                payment_gateway
                id_verification_provider
                checkin_approval_rules
                max_period_for_charge_authorization
            }
            send_checkin_mail
            checkin_mail
            host_actions {
                key
                value
            }
            created_by {
                id
                email
                name {
                    first_name
                    last_name
                }
            }
            timestamp {
                created_at
                updated_at
            }
        }
    }`;