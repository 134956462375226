<template>
    <div>
        <v-row>
          <v-col cols="12" class="d-flex align-center">
            <h4 class="headline">Properties</h4>
            <v-chip color="primary" small class="ml-2">{{ $store.getters.current_user.profile.properties.length }}</v-chip>
            <transfer-property-credit
                :activator="{outlined: true, color: 'primary', 'class':'ml-3', small: true }"
                @transfer="checkinPointTransferred"
            ></transfer-property-credit>
          </v-col>
          <v-col cols="8" class="pb-0">
            <property-switch />
          </v-col>
          <v-col cols="4" class="pb-0">
            <period-filter default-filter="Today" v-model="periodFilter" outlined dense />
          </v-col>
          <v-col cols="12" class="d-flex py-0">
            <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Search"
                dense
                outlined
                class="white"
            >
              <template #append>
                <v-btn text small color="primary">
                  <v-icon>mdi-filter-outline</v-icon>
                  <span class="d-none d-md-inline">Filter</span>
                </v-btn>
              </template>
            </v-text-field>
            <buy-property-credit
                :activator="{outlined: true, color: 'secondary', 'class':'ma-1' }"
                @purchase="checkinPointsPurchased"
            ></buy-property-credit>
          </v-col>
        </v-row>

      <property-checkin-point-history
      :period-filter="periodFilter"
      :property="property"
      :search="search"
      >
        <template #head>
          <div class="d-flex align-center">
            <h4 class="headline">Checkin Points</h4>
            <v-chip color="primary" small class="ml-2">{{ property.checkin_points }}</v-chip>
          </div>
        </template>
      </property-checkin-point-history>
    </div>
</template>

<script>
import {mapActions, mapMutations} from 'vuex';
import PropertySwitch from "@/domain/Property/Components/PropertySwitch";
import PeriodFilter from "@/components/PeriodFilter";
import BuyPropertyCredit from "@/domain/Property/Components/BuyPropertyCredit";
import gql from "graphql-tag";
import TransferPropertyCredit from "@/domain/Property/Components/TransferPropertyCredit";
import PropertyCheckinPointHistory from "@/domain/Property/Components/PropertyCheckinPointHistory";

export default {
    name: "PropertyCheckinPoint",
    components:{
      PropertyCheckinPointHistory,
      TransferPropertyCredit,
      BuyPropertyCredit,
      PeriodFilter,
      PropertySwitch,
    },
    data() {
        return {
            loading: false,
            search: null,
            history: [],
            statusFilter: [],
            periodFilter: null
        }
    },
    computed: {
      property() {
        return this.$store.getters.current_user.property
      },
      pointUsage() {
        return this.history.filter(h => h.type === 'checkin').map(h => {
          if(h.reservation) {
            h.reservation = {
              ...h.reservation,
              property: {
                id: this.property.id,
                property: this.property
              }
            }
          }
          return h;
        })
        .filter(h => h.reservation && this.statusFilter.length ? this.statusFilter.includes(h.reservation.status) : true);
      }
    },
    methods: {
        ...mapActions([
            'query',
            'mutate'
        ]),
      ...mapMutations([
          'TOAST_ERROR'
      ]),

      getCheckinPointHistory() {
          this.loading = true;
          this.query({
            query: gql`
              query getPropertyCheckinPointHistory($property_id: ID!) {
                getPropertyCheckinPointHistory(property_id: $property_id) {
                  title
                  opening_balance
                  closing_balance
                  reference
                  points
                  type
                  description
                  created_at
                  reservation {
                    id
                    name
                    checkin_date
                    status
                    checkedin_at
                    approved
                    approved_at
                    cancelled
                    cancelled_at
                    property_id
                    timestamp {
                      created_at
                      updated_at
                    }
                  }
                  from_property {
                    id
                    name
                    image
                  }
                  to_property {
                    id
                    name
                    image
                  }
                }
              }
            `,
            variables: {
              property_id: this.property.id
            }
          })
          .then(response => {
            this.history = response.data.getPropertyCheckinPointHistory || []
          })
          .catch(e => {
            this.TOAST_ERROR({
              show: true,
              message: "Couldn't get checkin point history.",
              retry: () => this.getCheckinPointHistory(),
              exception: e
            })
          })
          .finally(() => {
            this.loading = false;
          })
      },

      checkinPointTransferred(transfer) {
        this.$store.dispatch("syncAuthUser");
      },

      checkinPointsPurchased(purchase) {
          this.history.unshift(purchase);
          this.$store.dispatch("syncAuthUser");
      }
    },
    watch: {
        property: {
            immediate: true,
            handler(property){
                if(property) this.getCheckinPointHistory();
                else this.loading = true;
            }
        },
      periodFilter: {
        immediate: true,
        handler(filter) {
            if(filter){
              this.history = this.history.filter(h => this.momentFromTimestamp(h.created_at).isBetween(filter.start, filter.end))
            }
        }
      }
    }
}
</script>