<template>

    <v-dialog
        v-model="view"
        max-width="700"
        scrollable
        >
            <template v-slot:activator="{ on }">
                <v-btn
                color="primary"
                dark
                v-on="on"
                small
                text
                >
                <slot> View ID </slot>
                </v-btn>
            </template>

            <v-card>
                <div class="pa-3">
                    <h4>Uploaded ID</h4>
                    <small v-if="verifiedAt" class="grey--text">
                        <v-icon color="success">mdi-check-circle</v-icon> 
                        verified {{ verifiedAt.fromNow() }} <span v-if="verifiedBy">by {{ verifiedBy }}</span>
                    </small>
                </div>

                <v-card-text v-if="upload" style="max-height: 500px;">
                    <img :src="upload.url" width="100%">
                </v-card-text>

                <v-card-text v-else>
                    <p class="text-center">No uploaded ID</p>
                </v-card-text>

                <v-card-actions>
                    <v-btn @click="view = false" color="red" text>Close</v-btn>
                    <v-spacer></v-spacer>
                    <slot name="actions" v-bind="{ close }" />
                    <upload-id  v-if="canUpload" v-on="$listeners">
                        {{ upload && upload.url ? 'Re upload ID' : 'Upload ID' }}
                    </upload-id>
                </v-card-actions>

            </v-card>
     </v-dialog>

</template>

<script>
import moment from 'moment'
import UploadId from './UploadUserID.vue';
export default {
    name:"UserUploadedID",
    components: { UploadId },
    data() {
        return {
            view: false
        }
    },

    props: {
        upload: Object,
        canUpload: Boolean,
    },
    computed: {

        verifiedAt() {
            return (this.upload && this.upload.verified_at) ? moment.unix(this.upload.verified_at) : null
        },

        verifiedBy() {
          let verifier = "";
          if(this.upload && this.upload.verified_by) {
            verifier = this.upload.verified_by.property ? this.upload.verified_by.property.name : verifier;
            verifier += this.upload.verified_by.user
                ? ` (${[this.upload.verified_by.user.name.first_name, this.upload.verified_by.user.name.last_name].join(" ")})`
                : "";
          }
          return verifier
        }

    },
  methods: {
      close() {
        this.view = false;
      }
  }
}
</script>