var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-layer',{ref:"app",staticClass:"white"},[_c('v-row',{staticClass:"pt-16",staticStyle:{"min-height":"50vh"},attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4","order-md":"last"}},[_c('div',{staticClass:"px-2"},[_c('div',{staticClass:"mb-16 text-center"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/img/gr-logo.png"),"width":"190","alt":"Guest Registration"}})])],1),_c('div',{staticClass:"text-center mb-10"},[_c('h1',[_vm._v("Let's get started")]),_c('p',{staticClass:"grey--text text-subtitle-2"},[_vm._v("Get started with Guest Registration by using any of the options")])]),(_vm.alert)?_c('v-alert',{attrs:{"colored-border":"","border":"left","type":_vm.alert.type}},[_vm._v(" "+_vm._s(_vm.alert.text)+" ")]):_vm._e(),(_vm.authProviders.includes('google') && _vm.providers.includes('google'))?_c('google-signin',{staticClass:"my-2",attrs:{"btn":{
              depressed: true,
              block: true,
              outlined: true,
              large: true,
              class: 'justify-space-between text-transform-unset'
            }},on:{"authenticated":_vm.authenticated,"error":_vm.authError},scopedSlots:_vm._u([{key:"btn-append",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}],null,false,2478485642)},[_vm._v(" Continue with Google ")]):_vm._e(),(_vm.authProviders.includes('email') && _vm.providers.includes('email'))?_c('email-signin',{staticClass:"my-2",attrs:{"btn":{
              depressed: true,
              block: true,
              outlined: true,
              large: true,
              class: 'justify-space-between text-transform-unset'
            }},on:{"authenticated":_vm.authenticated,"error":_vm.authError},scopedSlots:_vm._u([{key:"btn-append",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}],null,false,2478485642)},[_vm._v(" Continue with Email ")]):_vm._e(),(_vm.authProviders.includes('phone') && _vm.providers.includes('phone'))?_c('phone-signin',{staticClass:"my-2",attrs:{"btn":{
              depressed: true,
              block: true,
              outlined: true,
              large: true,
              class: 'justify-space-between text-transform-unset'
            }},on:{"authenticated":_vm.authenticated,"error":_vm.authError},scopedSlots:_vm._u([{key:"btn-append",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}],null,false,2478485642)},[_vm._v(" Continue with Phone ")]):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"5","order-md":"first"}},[_c('v-sheet',{staticClass:"py-md-16 rounded-lg px-md-4",attrs:{"rounded":"","color":"#F0F6FF"}},[_c('v-sheet',{staticClass:"pa-2 rounded-lg my-md-16",attrs:{"rounded":"","color":"#141516"}},[_c('iframe',{attrs:{"src":"https://player.vimeo.com/video/357612124?h=c1e58e6c18","width":"100%","height":"300","frameborder":"0","allow":"autoplay; fullscreen; picture-in-picture","allowfullscreen":""}})])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }