var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[(_vm.header)?_c('v-card-title',{staticClass:"headline"},[_c('span',[_vm._v("Payments")])]):_vm._e(),_c('v-card-text',{staticClass:"px-1"},[_c('data-container',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return _vm._l((4),function(i){return _c('v-skeleton-loader',{key:i,attrs:{"type":"list-item-three-line, divider"}})})},proxy:true}])},[(_vm.stripePayments.length)?_c('virtual-scroll',{attrs:{"height":500,"item-height":150,"items":_vm.stripePayments},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var payment = ref.item;
var i = ref.index;
return [_c('stripe-payment',{attrs:{"payment":payment,"credit-card":payment.source,"can-refund":_vm.canRefund,"charge":_vm.getCharge(payment),"dialog":_vm.selected === i,"three-line":""},on:{"dialog-closed":function($event){_vm.selected = null}},scopedSlots:_vm._u([{key:"list-action",fn:function(){return [_c('v-btn',{attrs:{"icon":"","title":"view payment"},on:{"click":function($event){_vm.selected = i}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]},proxy:true},{key:"item-subtitles",fn:function(ref){
var payment = ref.payment;
return [(!payment.metadata.charge_id)?_c('v-list-item-subtitle',[_c('v-icon',{attrs:{"color":"warning","small":""}},[_vm._v(" mdi-information ")]),_c('small',[_vm._v("This charge was not set for the reservation initially")])],1):_vm._e(),(payment.metadata.refundable == 'true')?_c('v-list-item-subtitle',[_c('v-icon',{attrs:{"color":"warning","small":""}},[_vm._v(" mdi-information ")]),_c('small',[_vm._v("Charge refundable")])],1):_vm._e()]}},(_vm.reservation.approved && _vm.reservation.active)?{key:"dialog-actions",fn:function(){return [_c('div',{staticClass:"d-flex"},[(_vm.canRefund)?_c('stripe-refund',{attrs:{"property":_vm.property,"reservation":_vm.reservation,"payment":payment},on:{"refunded":_vm.stripePaymentRefunded}}):_vm._e(),(_vm.canCapture)?_c('stripe-capture',{attrs:{"property":_vm.property,"reservation":_vm.reservation,"charge":_vm.getCharge(payment) || { title: "Custom charge" },"payment":payment},on:{"captured":function (charge) { return _vm.updateStripePayment(charge); }}}):_vm._e()],1)]},proxy:true}:null],null,true)}),_c('v-divider')]}}],null,false,2733357158)}):(_vm.paystackChargePayments.length)?_c('virtual-scroll',{attrs:{"height":500,"item-height":150,"items":_vm.paystackChargePayments},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var payment = ref.item;
var i = ref.index;
return [_c('paystack-payment',{attrs:{"payment":payment,"three-line":""}})]}}])}):_c('div',{staticClass:"py-5 grey--text text-center"},[_vm._v(" No payment for the reservation with the card ")])],1)],1),(_vm.reservation.approved && _vm.reservation.active)?_c('v-card-actions',[(_vm.canRefund && _vm.outstandingStripeCharges.length && _vm.outstandingStripeChargesTotal > 0)?_c('v-btn',{attrs:{"loading":_vm.refunding,"color":"primary","text":"","depressed":""},on:{"click":_vm.refundOutstandingCharges}},[_vm._v(" Refund all outstanding charges - "+_vm._s(_vm.outstandingStripeCharges[0].currency.toUpperCase())+" "+_vm._s(_vm.outstandingStripeChargesTotal / 100)+" ")]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }