
import gql from 'graphql-tag';
import { auth } from './../firebase';
import _apollo from './../apollo';
import helper from './../helper';
import GET_AUTH_USER from '../domain/User/Queries/getAuthUser';
import GET_SYSTEM_DATA from '../domain/Dashboard/Queries/getSystemData';
import GET_SYSTEM_PARAMS from '../graphql/query/getSystemParam'

const actions = {

    getIdToken({commit}, user){
        return new Promise((resolve, reject) => {
            const auth_user = user ? user : auth.currentUser
            if(auth_user){
                auth_user.getIdTokenResult()
                .then(tokenResult => {
                    window.localStorage.setItem('gr-user', tokenResult.token);
                    window.localStorage.setItem('token-expires', tokenResult.expirationTime);
                    commit('SET_USER_AUTH', auth_user)
                    resolve(auth_user)
                })
                .catch(e => {
                    window.localStorage.removeItem('gr-user');
                    window.localStorage.removeItem('token-expires');    
                    reject(e)
                })
            }else{
                window.localStorage.removeItem('gr-user');
                window.localStorage.removeItem('token-expires');    
                resolve(null)
            }
        })
    },

    getAuthUser({ commit, dispatch, getters }) {
        return new Promise((resolve, reject) => {
            dispatch('query', {
                query: GET_AUTH_USER
            }).then(response => {
                const auth = response.data.getAuth;
                const user = auth.user ? auth.user : null;
                if(!user) {
                    resolve(null);
                    return;
                }
                user.auth = auth.authentication;
                user.properties = (user.properties || [])
                user.reservations = (user.q_reservations && user.q_reservations.length ? user.q_reservations : [])
                    .map(reservation => {
                        return { id: reservation.id, reservation }
                    });
                commit('SET_USER_PROFILE', user);
                commit('SET_USER_NOTIFICATIONS', user.notifications || []);
                const lastActivePropertyId = window.localStorage.getItem('property');
                let activeProperty = null;

                if(lastActivePropertyId && user.properties.find(p => p.id === lastActivePropertyId)) activeProperty = user.properties.find(p => p.id === lastActivePropertyId);
                else activeProperty = user.properties.length ? user.properties[0] : null;

                if(activeProperty) commit('SET_ACTIVE_PROPERTY', activeProperty.property);

                user.properties.forEach(property => {
                    commit('SET_PROPERTY_NOTIFICATIONS', { id: property.id, notifications: property.property.notifications || [] })
                })

                let mode = "guest";
                const lastMode = window.localStorage.getItem('mode');
                if(lastMode && ["guest","host","admin"].includes(lastMode)) {
                    mode = lastMode === "host" && !user.properties.length ? mode : lastMode;
                } else if(user.properties.length) {
                    mode = "host";
                }
                commit('SET_MODE', mode);
                resolve(user);
            })
            .catch(e => {
                reject(e)
            });
        })

    },

    getSystemParams({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            dispatch('query', {
                query: GET_SYSTEM_PARAMS
            }).then(response => {
                const params = helper.convertMetaKeyValueToObj(response.data.getSystemParams || []);
                commit("SET_SYSTEM_PARAMS", params)
                resolve(params)
            }).catch(e => reject(e))
        })
    },

    query({commit, dispatch}, {query, variables}){
        let error = null;
        const runQuery = () => {
            return _apollo((e) => {
                error = e;
            }, (e) => {
                    error = e;
                }
            ).then(apollo => {
                return apollo.client.query({
                    query, variables
                })
            })
        }

        return new Promise((resolve, reject) => {
            runQuery().then(response => {
                resolve(response)
            })
            .catch(e => {
                //refresh token and retry
                if(error && error.response && error.response.status === 401) {
                    console.log("Retrying after refreshing ID token...");
                    dispatch('getIdToken').then(() => runQuery())
                    .then(response => resolve(response))
                    .catch(e => reject(e))
                }
                console.log("error---->", error)
                if(error) return reject(error.result ? error.result : error);
                return reject(e);
            })

        });
        
    },
    
    mutate({commit, dispatch}, {mutation, variables}){
        let error = null;
        const runMutation = () => {
            return _apollo((e) => {
                    error = e.result ? e.result : null;
                }, (e) => {
                    error = e;
                }
            ).then(apollo => {
                return apollo.client.mutate({
                    mutation, variables
                })
            })
        }
        return new Promise((resolve, reject) => {
            runMutation().then(response => resolve(response))
            .catch(e => {
                //refresh token and retry
                if(error && error.response && error.response.status === 401) {
                    console.log("Retrying after refreshing ID token...");
                    dispatch('getIdToken').then(() => runMutation())
                        .then(response => resolve(response))
                        .catch(e => reject(e))
                }
                if(error.result) return reject(error.result)
                return reject(e);
            })

        })
    },

    syncAuthUser({ dispatch }) {
        return dispatch('getAuthUser');
    },

    getDashboardData({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            dispatch('query', {
                query: GET_SYSTEM_DATA
            }).then(response => {
                const data = response.data.getSystemData;
                commit("SET_DASHBOARD_DATA", data);
                resolve(data);
            })
            .catch(e => {
                reject(e)
            });
        })
    },

    /**
     * sign out the cureently authenticated user
     */
    signout({commit}){
       return new Promise((resolve, reject) => {
            auth.signOut()
            .then(() => {
                window.localStorage.removeItem('gr-user');
                window.localStorage.removeItem('token-expires');
                commit('SET_MODE', null)
                commit('UNSET_CURRENT_USER')
                resolve()
            })
            .catch(e => {
                reject(e)
            })
       }) 

      }
}

export default actions