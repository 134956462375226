import gql from 'graphql-tag';

export default gql`
    query getReservationCheckin($id: ID!){
        getReservationCheckin(id: $id){
            checkin {
                checkedin_at
                name {
                    first_name
                    last_name
                }
                agreements {
                    id
                    agreement
                    link
                }
                questions {
                    id
                    question
                    response {
                        option
                        attachments {
                            agreements {
                                id
                                agreement
                                link
                            }
                            charges {
                                id
                                type
                                title
                                amount
                                description
                                enable
                                optional
                                currency
                                unit
                                multipliers {
                                    min
                                    max
                                    unit
                                }
                            }
                            questions {
                                id
                                question
                                response_type
                                required
                                options {
                                    option
                                }
                                response {
                                    option
                                }
                            }
                        }
                    }
                }
                credit_card {
                    stripe {
                        customer
                        card {
                            id
                            brand
                            customer
                            exp_month
                            exp_year
                            last4
                            name
                        }
                        payment_method {
                            id
                            type
                            card {
                                brand
                                exp_month
                                exp_year
                                last4
                            }
                        }
                    }
                    paystack {
                        card_type
                        authorization_code
                        exp_month
                        exp_year
                        last4
                        reusable
                    }
                    
                }
                signature
            }
            reservation{
                id
                user_id
                name
                active
                checkedin
                checkedin_at
                checkedout
                checkedout_at
                already_checkedin
                approved
                approved_at
                booking_reference
                booking_channel {
                    id
                    label
                }
                balance
                checkin_date
                checkout_date
                instruction
                currency
                property {
                    id
                    name
                    address
                    image
                }
                guests {
                    name
                    gender
                    type
                }
                charges {
                    id
                    title
                    amount
                    description
                    type
                    enable
                    optional
                    currency
                }
            }
            
            user{
                id
                email
                phone
                name{
                    first_name
                    last_name
                }
                phone_meta{
                    country_code
                    phone_number
                }
            }
            verification{
                user_id
                property_id
                stripe {
                    type
                    status
                    session
                    report
                    metadata {
                        user_id
                        property_id
                        reservation_id
                    }
                    last_updated
                }
                upload {
                    url
                    verified_at
                    verified_by {
                        property {
                            id
                            name
                        }
                        user {
                            id
                            name {
                                first_name
                                last_name
                            }
                        }
                    }
                }
                smile {
                    result_id
                    result_text
                    result_code
                    last_updated
                    result {
                        ResultCode
                        ResultText
                        Actions {
                            Liveness_Check
                            Register_Selfie
                            Selfie_Provided
                            Verify_ID_Number
                            Human_Review_Compare
                            Return_Personal_Info
                            Selfie_To_ID_Card_Compare
                            Human_Review_Update_Selfie
                            Human_Review_Liveness_Check
                            Selfie_To_ID_Authority_Compare
                            Update_Registered_Selfie_On_File
                            Selfie_To_Registered_Selfie_Compare
                        }
                    }
                }
            }
            payments {
                stripe {
                    id
                    currency
                    amount
                    status
                    metadata {
                        user_id
                        reservation_id
                        charge_id
                        property_id
                        refundable
                        base_multiplier {
                            unit
                            quantity
                        }
                        custom_multipliers {
                            unit
                            quantity
                        }
                    }
                    captured
                    refunded
                    amount_captured
                    amount_refunded
                    receipt_url
                    net_captured
                    description
                    payment_intent
                    payment_method
                    source {
                        id
                        name
                        brand
                        exp_month
                        exp_year
                        last4
                        customer
                    }
                }

                paystack {
                    amount
                    currency
                    reference
                    status
                    metadata {
                        user_id
                        property_id
                    }
                    charges {
                        property_id
                        amount
                        currency
                        description
                        receipt_email
                        capture
                        metadata {
                            user_id
                            reservation_id
                            charge_id
                            property_id
                            base_multiplier {
                                unit
                                quantity
                            }
                            custom_multipliers {
                                unit
                                quantity
                            }
                        }
                    }
                    authorization {
                        authorization_code
                        card_type
                        last4
                        exp_month
                        exp_year
                        reusable
                        bank
                    }
                }
            
            }
        }
    }`;