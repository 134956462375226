<template>
    <integration-setup
        :open="open"
        title="Ezee"
        :subtitle="`Ezee integration for ${ property.name }`"
        :fetch-query="getQuery"
        @fetched="ezeeFetched"
        @close="open = false"
    >
      <template #header-action>
        <v-switch
            v-model="form.enabled"
            inset
        >
        </v-switch>
      </template>
      <v-form ref="form">
        <v-text-field
            outlined dense
            label="Hotel code"
            :rules="[rules.required]"
            type="text"
            v-model="form.hotel_code"
            :disabled="!edit"
        ></v-text-field>
        <v-text-field
            outlined dense
            label="Auth code"
            :rules="[rules.required]"
            type="text"
            v-model="form.auth_code"
            :disabled="!edit"
        ></v-text-field>
      </v-form>
      <template v-if="ezee && ezee.last_report">
          <small class="grey--text">Last Report</small>
          <div>
            <span v-if="lastPoll">{{ lastPoll.format('MMMM Do YYYY, hh:mm a') }}, {{ lastPoll.fromNow() }}</span>
            <span v-else>Never polled</span>
          </div>
          <v-alert
              color="primary"
              border="left"
              colored-border
              icon="mdi-information"
          >
            {{ ezee.last_report }}
          </v-alert>
        </template>
      <template #actions>
        <v-btn v-if="edit" color="primary" :loading="saving" @click="save" depressed>Save changes</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="ezee.enabled && ezee.auth_code && ezee.hotel_code" color="primary" text @click="poll" :loading="polling">
         <v-icon>mdi-sync</v-icon> Poll Now
        </v-btn>
      </template>
    </integration-setup>
</template>

<script>
import gql from 'graphql-tag';
import moment from 'moment';
import formValidation from '@/helper/formValidation';
import helper from '@/helper';
import IntegrationSetup from "@/domain/Property/Integrations/Setup";

export default {
    name: "Ezee",
    components: { IntegrationSetup },
    data(){
        return {
            open: false,
            getQuery: {},
            edit: true,
            saving: false,
            polling: false,
            pollAlert: false,
            pollReport: null,
            rules: formValidation.rules,
            ezee: {},
            form: {},
        }
    },
    computed: {
        lastPoll() {
            if(!this.ezee.last_poll) return null;
            return moment.unix(this.ezee.last_poll);
        }
    },

    props: {
        property: Object,
    },

    methods: {

      setForm() {
        const ezee = this.ezee || {}
        this.form = {
          enabled: ezee.enabled,
          auth_code: ezee.auth_code,
          hotel_code: ezee.hotel_code
        }
      },

      ezeeFetched(response) {
        this.ezee = response.getPropertyIntegrations.ezee || {};
        this.setForm();
      },

      save() {
        if(!this.$refs.form.validate()) return;
          this.saving = true;
          this.$store.dispatch('mutate', {
              mutation: gql`
                  mutation updatePropertyEzeeIntegration($property_id: ID!, $data: PropertyEzeeIntegrationInput!) {
                      updatePropertyEzeeIntegration(property_id: $property_id, data: $data) {
                          enabled
                          auth_code
                          hotel_code
                      }
                  }`,
              variables: {
                  property_id: this.property.id,
                  data: this.form
              }
          })
          .then(response => {
              this.ezee = response.data.updatePropertyEzeeIntegration;
              if(response.data.updatePropertyEzeeIntegration) {
                  this.$store.commit("SNACKBAR", {
                      status: true,
                      text: "Ezee updated",
                      color: "success"
                  });
                  this.edit = true;
                  this.$emit('updated');
              }
          })
          .catch(e => {
              this.$store.commit('TOAST_ERROR', {
                  show: true,
                  message: `Failed. `,
                  exception: e
              })
          })
          .finally(() => {
              this.saving = false;
          })
      },

      poll() {
            this.polling = true;
            this.$store.dispatch('mutate', {
                mutation: gql`
                    mutation ezeePoll($property_id: ID!) {
                        ezeePoll(property_id: $property_id) {
                            poll {
                                report
                            }
                        }
                    }
                `,
                variables: {
                    property_id: this.property.id,
                }
            })
            .then(response => {
                if(response.data.ezeePoll) {
                    this.$store.commit("SNACKBAR", {
                        status: true,
                        text: response.data.ezeePoll.poll.report,
                        color: "info"
                    });
                    this.ezee.last_report = response.data.ezeePoll.poll.report;
                    this.ezee.last_poll = helper.nowTimestamp()
                }
            })
            .catch(e => {
                this.$store.commit('TOAST_ERROR', {
                    show: true,
                    message: `Failed. `,
                    exception: e
                })
            })
            .finally(() => {
                this.polling = false;
            })
        }
    },

    watch: {
      property: {
        immediate: true,
        handler(property) {
          if(!property) this.getQuery = null;
          this.getQuery = {
            query: gql`
              query getPropertyIntegrations($property_id: ID!){
                getPropertyIntegrations(property_id: $property_id){
                  ezee {
                    enabled
                    auth_code
                    hotel_code
                    last_poll
                    last_report
                    }
                  }
                }`,
            variables: {
              property_id: property.id
            }
          }
        }
      }
    }
}
</script>