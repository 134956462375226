<template>
    <div>
        <slot name="heading" />
       <data-container v-if="property && property.active" :loading="loading">
            <template v-slot:loading>
                <div  v-for="i in 4" :key="i">
                    <v-skeleton-loader
                        type="card"
                        height="50"
                        class="my-2"
                    ></v-skeleton-loader>
                </div>
            </template>
            <div class="d-flex justify-end my-3">
                <v-btn color="primary"  title="New template" @click="createNewTemplate = true"><v-icon dark> mdi-plus</v-icon> Create New</v-btn>
            </div>

            <div v-if="createNewTemplate" class="my-1">
                <h2 class="headline">New Template</h2>
                <property-checkin-instruction-template-form
                class="my-2"
                :property="property" 
                @created="templateCreated"
                @cancel="createNewTemplate = false"  />
            </div>
            <template v-if="templates.length">
                <property-checkin-instruction-template 
                v-for="template in templates" :key="template.id"
                :property="property"
                :template="template"
                @deleted="templateDeleted"
                class="my-1" />
            </template>
            <template v-else>
                <div class="py-5 text-center">
                    <p class="grey--text">No instruction yet</p>
                </div>
            </template>
        </data-container>
    </div>

</template>
<script>
import DataContainer from '../../../components/DataContainer.vue';
import PropertyCheckinInstructionTemplateForm from '../Components/PropertyCheckinInstructionTemplateForm';
import PropertyCheckinInstructionTemplate from '../Components/PropertyCheckinInstructionTemplate';

import GET_PROPERTY_CHECKIN_INSTRUCTION_TEMPLATES from '../Queries/getPropertyCheckinInstructionTemplates';

export default {
    name: "PropertyCheckinInstructionTemplates",
    components: {
        DataContainer,
        PropertyCheckinInstructionTemplate,
        PropertyCheckinInstructionTemplateForm,
    },

    props: {
        property: Object
    },

    data(){
        return {
            loading: false,
            createSecurityDeposit: false,
            createNewTemplate: false,
            templates: [],
        }
    },  

    methods: {
        getTemplates(){
            this.loading = true;
           
             this.$store.dispatch('query', {
                query: GET_PROPERTY_CHECKIN_INSTRUCTION_TEMPLATES,
                variables: {
                    property_id: this.property.id
                }
            })
            .then(response => {
                this.templates = response.data.getPropertyCheckinInstructionTemplates
            })
            .catch(e => {
                this.$store.commit('TOAST_ERROR', {
                    show: true,
                    retry: () => this.getTemplates(),
                    message: 'Could not get property templates ',
                    exception: e
                })
            })
            .finally(() => {
                this.loading = false;
            })
        },

        templateCreated(template){
            this.templates.unshift(template);
            this.createNewTemplate = false;
        },

        templateDeleted(template) {
            const index = this.templates.findIndex(t => t.id === template.id);
            if(index >= 0) this.templates.splice(index, 1);
        }
    },

    watch: {
        property: {
            immediate: true,
            handler(property){
                if(property && property.active) this.getTemplates()
                else this.loading = false;
            }
        }
    }
}
</script>