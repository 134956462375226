<template>
  <div>
    <confirmation-dialog ref="confirmation" @confirmed="confirmation.action()">
      {{ confirmation.text }}
    </confirmation-dialog>
    <data-container :loading="loading">
      <div v-if="cards.length" >
        <v-radio-group
            v-model="creditCard"
            :rules="[(value) => !value && !addCard ? 'Select a credit card' : true]"
        >
          <v-radio
              v-for="(card, c) in cards" :key="c"
              :value="card"
          >
            <template #label>
              <div class="pr-2" style="width: 100%">
                <stripe-credit-card class="my-1" :card="card" >
                  <template #actions="attr">
                    <v-spacer></v-spacer>
                    <v-btn icon @click="confirmCardRemoval(attr.card)">
                      <v-icon small color="red">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </stripe-credit-card>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
        <div class="text-center">
          <a href="#" @click.prevent="addNewCard" class="text-decoration-none">Use another card </a>
        </div>
      </div>
      <stripe-credit-card-form
          v-if="addCard"
          ref="stripeCreditCard"
          :publishable-key="stripePublishableKey"
          :type="`create-token`"
          :token-callback="stripeTokenCallback"
          @token-callback="tokenCallbackSuccessfull"
          @error="tokenCallbackError"
          @abort="tokenCallbackAborted"
      >
        <template #submit-btn-text>Add card</template>
      </stripe-credit-card-form>
    </data-container>
  </div>
</template>
<script>
import ConfirmationDialog from '@/components/Utilities/ConfirmationDialog.vue'
import ADD_PROPERTY_CREDIT_CARD from "@/domain/Property/Mutations/addPropertyCreditCard";
import CREATE_PROPERTY_AS_CUSTOMER from "@/domain/Property/Mutations/createPropertyAsCustomer";
import REMOVE_PROPERTY_CREDIT_CARD from "@/domain/Property/Mutations/removePropertyCreditCard";
import GET_PROPERTY_AS_CUSTOMER from "@/domain/Property/Queries/getPropertyAsCustomer";
import DataContainer from "@/components/DataContainer";
import StripeCreditCard from '@/components/Utilities/StripeCreditCard.vue';
import StripeCreditCardForm from '@/components/Utilities/StripeCardGateway.vue';
import {mapActions} from "vuex";

export default {
  name: "PropertyCreditCardSelect",
  components: {DataContainer, ConfirmationDialog, StripeCreditCard, StripeCreditCardForm },
  data() {
    return {
      loading: false,
      creditCard: null,
      addCard: false,
      customer: null,
      confirmation: {
        text: String,
        action: Function
      }
    }
  },
  props: {
    property: Object
  },
  computed: {
    stripePublishableKey(){
      return process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
    },
    cards(){
      return this.customer && this.customer.sources ? this.customer.sources.data : []
    },
  },
  methods: {
    ...mapActions([
        'mutate', 'query'
    ]),
    stripeTokenCallback(token){
      if(this.customer.customer){
        return new Promise((resolve, reject) => {
          this.mutate({
            mutation: ADD_PROPERTY_CREDIT_CARD,
            variables: {
              property_id: this.property.id,
              customer_id: this.customer.customer.id,
              source: token.id
            }
          })
          .then(response => {
            resolve(response.data.addPropertyCreditCard)
          })
          .catch(e => {
            reject(e)
          })
        })
      }

      return new Promise((resolve, reject) => {
        this.subscribing = true
        this.mutate({
          mutation: CREATE_PROPERTY_AS_CUSTOMER,
          variables: {
            property_id: this.property.id,
            source: token.id
          }
        })
        .then(response => {
          resolve(response.data.createPropertyAsCustomer)
        })
        .catch(e => {
          reject(e)
        })
        .finally(() => {
          this.subscribing = false;
        })
      })

    },

    tokenCallbackSuccessfull(data){
      if(!data) return;
      if(data.object == 'card' && this.customer){
        this.customer.sources.data.push(data);
        this.creditCard = data;
      } else{
        this.customer = data;
      }
      this.$store.commit("SNACKBAR", {
        status: true,
        text: "Credit card added",
        color: "success"
      })
      this.addCard = false;
    },

    tokenCallbackError(e){
      if(!e) return;
      this.$store.commit('SNACKBAR', {
        status: true,
        color: 'error',
        text: `${e.message}`
      })
    },

    tokenCallbackAborted(card){

    },

    removeCreditCard(card){
      this.mutate({
        mutation: REMOVE_PROPERTY_CREDIT_CARD,
        variables: {
          property_id: this.property.id,
          customer_id: this.customer.customer.id,
          card_id: card.id
        }
      })
        .then(response => {
          if(response.data.removePropertyCreditCard == true){
            const index = this.customer.sources.data.findIndex(c => c.id == card.id);
            this.customer.sources.data.splice(index, 1);

            if(!this.cards.length) {
              this.creditCard = null;
              this.addCard = true;
            } else {
              this.creditCard = this.cards[0];
            }

            this.$store.commit("SNACKBAR", {
              status: true,
              text: "Credit card removed",
              color: "success"
            })
          }
          else{
            this.$store.commit("SNACKBAR", {
              status: true,
              text: "Credit card not removed",
              color: "error"
            })
          }
        })
        .catch(e => {
          this.$store.commit("SNACKBAR", {
            status: true,
            text: `Credit card not removed. ${e.message}`,
            color: "error"
          })
        })
    },

    addNewCard(){
      this.addCard = !this.addCard;
      this.creditCard = null;
    },

    confirmCardRemoval(card){
      this.confirmation = {
        text: `Are you sure you want to remove the card ending ${card.last4} `,
        action: () => {
          this.removeCreditCard(card)
        }
      }
      this.$refs.confirmation.open();
    },

    getPropertyAsCustomer() {
      this.loading = true;
      this.$store.dispatch('query', {
        query: GET_PROPERTY_AS_CUSTOMER,
        variables: {
          property_id: this.property.id
        }
      }).then(response => {
        this.customer = response.data.getPropertyAsCustomer;
        if(!this.cards.length) this.addCard = true;
        else this.creditCard = this.cards[0];
      })
      .catch(e => {
        this.$store.commit('TOAST_ERROR', {
          show: true,
          retry: () => this.getPropertyAsCustomer(),
          message: 'Could not get property payment information',
          exception: e
        })
      })
      .finally(() => {
        this.loading = false;
      })
    }
  },
  watch: {
    property: {
      immediate: true,
      handler() {
        this.getPropertyAsCustomer()
      }
    },
    creditCard: {
      immediate: true,
      handler(card) {
        this.$emit("input", card);
      }
    }
  }
}
</script>