var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',_vm._g(_vm._b({attrs:{"items":_vm.users,"item-text":"full_name","item-value":"id"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var user = ref.item;
var index = ref.index;
var select = ref.select;
var parent = ref.parent;
return [(parent.multiple)?_c('v-chip',{staticClass:"ma-1",attrs:{"pill":"","close":""},on:{"click:close":function($event){return parent.value.splice(index, 1)}}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-img',{attrs:{"src":user.image}})],1),_vm._v(" "+_vm._s(user.full_name)+" ")],1):[_c('v-avatar',{attrs:{"size":"30"}},[_c('v-img',{attrs:{"src":user.image}})],1),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(user.full_name))])]]}},{key:"item",fn:function(ref){
var user = ref.item;
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":user.image}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(user.full_name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s([user.email, user.phone ].filter(function (c) { return c && c.trim() !== ""; }).join(", ")))])],1)],1)]}}])},'v-autocomplete',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }