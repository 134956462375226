var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-layer',{ref:"app",attrs:{"breadcrumbs":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h4',{staticClass:"headline"},[_vm._v("Account")]),_c('v-chip',{attrs:{"small":"","color":"primary","outlined":""}},[_vm._v(" "+_vm._s(_vm.current_user.auth.uid)+" ")])],1)],1)]},proxy:true}])},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('responsive-tabs',{attrs:{"color":"primary","show-arrows":""},on:{"change":_vm.tabChanged},scopedSlots:_vm._u([{key:"tabs",fn:function(){return _vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.name,staticClass:"justify-start",attrs:{"disabled":tab.disabled}},[_c('v-icon',{attrs:{"flex":""}},[_vm._v(" "+_vm._s(tab.icon)+" ")]),_c('span',[_vm._v(_vm._s(tab.name)+" ")])],1)})},proxy:true},{key:"items",fn:function(){return [_c('v-tab-item',{staticClass:"pa-5"},[_c('user-profile',{key:_vm.tabsVersions.profile,attrs:{"form":_vm.profileForm},on:{"update":function (f) {
                                _vm.profileForm = f;
                                _vm.tabsVersions.profile++
                              },"saved":_vm.profileUpdated},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
                              var loading = ref.loading;
                              var submitting = ref.submitting;
                              var submit = ref.submit;
                              var canSubmit = ref.canSubmit;
return [_c('v-card-actions',{staticClass:"mt-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"loading":submitting,"disabled":loading || !canSubmit,"color":"primary"},on:{"click":function($event){return submit()}}},[_vm._v(" Save Changes ")])],1)]}}])})],1),_c('v-tab-item',{staticClass:"pa-5"},[_c('user-personal-info',{on:{"submitted":function($event){return _vm.accountReport({  message: 'Information updated', type: 'success' })}}})],1)]},proxy:true}]),model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }