var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('confirmation-dialog',{ref:"actionConfirmation",on:{"confirmed":function($event){return _vm.confirmation.confirmed()}}},[_c('div',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(_vm.confirmation.text)}})]),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.damageFormDialog),callback:function ($$v) {_vm.damageFormDialog=$$v},expression:"damageFormDialog"}},[_c('reservation-damage-form',_vm._g({attrs:{"reservation":_vm.reservation,"damage":_vm.damage,"is-host":true,"role":_vm.damageFormRole},on:{"cancel":function($event){_vm.damageFormDialog = false},"damage-updated":function($event){_vm.damageFormDialog = false}}},_vm.$listeners))],1),_c('v-menu',{attrs:{"origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',_vm.$attrs.activator,false),on),[_c('v-icon',_vm._b({},'v-icon',_vm.$attrs.activatorIcon,false),[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[(!_vm.damage.payment)?_c('v-list-item',{on:{"click":function () { _vm.damageFormRole = 'update'; _vm.damageFormDialog = true }}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pen")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Edit Damage")])],1)],1):_vm._e(),(!_vm.damage.resolution)?_c('v-list-item',{on:{"click":function (e) {
                    _vm.confirmation = {
                      text: ("Are you sure you want to resolve the damage <br/>  <strong>" + (_vm.damage.title) + "</strong>"),
                      confirmed: _vm.resolveDamage
                    }
                    _vm.$refs.actionConfirmation.open()
                  }}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check-circle")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Resolve Damage")])],1)],1):_vm._e(),_c('v-list-item',{on:{"click":function (e) {
                    _vm.confirmation = {
                      text: ("Are you sure you want to delete the damage <br/> <strong>" + (_vm.damage.title) + "</strong>"),
                      confirmed: _vm.deleteDamage
                    }
                    _vm.$refs.actionConfirmation.open()
                  }}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Delete Damage")])],1)],1),(!_vm.damage.resolution && !_vm.damage.amount)?_c('v-list-item',{on:{"click":function () { _vm.damageFormRole = 'attach-charge'; _vm.damageFormDialog = true }}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-credit-card-plus")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Attach a Charge")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }