<template>
    <div>
        <!-- <v-card v-if="info">
            <v-card-text>
                <v-list>
                    <v-list-item>
                        <v-list-item-title>
                            Country: {{ info.country }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card> -->
        <user-stripe-verification v-if="verification.stripe" :verification="verification.stripe" />
        <template v-if="verification.upload.url">
            <v-alert colored-border color="info" border="left" class="my-3">
                There is an uploaded ID
                <user-uploaded-id :upload="verification.upload">
                    <template #actions="{ close }">
                        <slot name="upload-actions" v-bind="{ closeID: close }" />
                    </template>
                </user-uploaded-id>
            </v-alert>
        </template>
        
        <smile-verification-result v-if="verification.smile" :result="verification.smile" />
    </div>
</template>

<script>
import UserUploadedId from './UploadedID.vue';
import UserStripeVerification from './StripeVerification.vue';
import SmileVerificationResult from './SmileVerificationResult.vue';

export default {
    name: "UserIdentityVerification",
    components: {
       UserStripeVerification, UserUploadedId, SmileVerificationResult
    },
    
    data(){
        return {
            loading: false,
            verifying: false,
            trial: 0,
        }
    },

    props: {
        verification: Object,
        canVerifyId: Boolean,
    },

    computed: {
        info() {
            return this.verification ? this.verification.info : null
        },

        isMine(){
            if(this.verification && this.verification.user_id) {
                return this.verification.user_id === this.$store.getters.current_user.profile.id
            } 
            return false;
        },
    }, 
}
</script>