var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("default",null,null,{ on: on })]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Submit Document ")]),_c('v-card-text',[_c('file-upload',{staticClass:"mb-4",attrs:{"multiple":"","path":"upload/reservation_documents"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var document = ref.document;
var selectNewFile = ref.selectNewFile;
var uploadFiles = ref.uploadFiles;
return [_c('files-preview',{staticClass:"ma-2",attrs:{"display-type":"list"},model:{value:(_vm.form.files),callback:function ($$v) {_vm.$set(_vm.form, "files", $$v)},expression:"form.files"}}),_c('file-selector',{attrs:{"loading":document.uploading,"accept":"image/*,video/*,application/pdf","multiple":""},on:{"files":uploadFiles},scopedSlots:_vm._u([(_vm.form.files && _vm.form.files.length)?{key:"default",fn:function(ref){
var selectNewFile = ref.selectNewFile;
return [_c('v-btn',{attrs:{"color":"primary","loading":document.uploading,"small":"","outlined":""},on:{"click":selectNewFile}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-plus")]),_vm._v(" Add files")],1)]}}:null],null,true)})]}}]),model:{value:(_vm.form.files),callback:function ($$v) {_vm.$set(_vm.form, "files", $$v)},expression:"form.files"}}),_c('v-textarea',{attrs:{"outlined":"","label":"Notes (optional)","placeholder":"Enter notes here","dense":"","rules":[],"hide-details":true},model:{value:(_vm.form.notes),callback:function ($$v) {_vm.$set(_vm.form, "notes", $$v)},expression:"form.notes"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.submitting,"depressed":""},on:{"click":_vm.submit}},[_vm._v("Submit")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }