<template>
      <v-card flat>
                <v-card-title v-if="$slots.heading">
                  <slot name="heading" />
                </v-card-title>
                <v-card-text>
                  <v-form ref="form" @submit.prevent class="pt-2">
                    <v-text-field
                        outlined dense
                        label="Name"
                        :rules="[rules.required]"
                        type="text"
                        name="name"
                        v-model="form.name"
                    ></v-text-field>
                    <component
                        :is="`${useGoogleLocation ? 'location' : 'v-text-field'}`"
                        flat
                        hide-no-data
                        hide-details
                        label="Address"
                        outlined dense
                        clearable
                        cache-items
                        class="mb-5"
                        v-model="form.full_address"
                        :rules="[rules.required]"
                        hint="Property address"
                    ></component>
                    <phone-number
                        dense
                        outlined
                        :value="phone.international"
                        @input="(v) => phone = v"
                        :rules="[rules.required]"
                    />
                    <v-text-field
                        outlined dense
                        label="Email"
                        :rules="[rules.required, rules.email]"
                        type="email"
                        name="email"
                        v-model="form.email"
                    ></v-text-field>
                    <currency-select :rules="[rules.required]" label="Default Currency" v-model="form.default_currency" outlined dense />
                    <v-textarea
                        outlined dense
                        label="Property rules"
                        :rules="[rules.required]"
                        v-model="form.rules"
                    ></v-textarea>
                    <v-text-field
                        outlined dense
                        label="Terms"
                        :rules="[rules.required, rules.url]"
                        type="text"
                        v-model="form.terms"
                        hint="URL to property terms and condition"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <slot name="actions" v-bind="{ submit, loading }">
                    <v-btn type="button" @click.prevent="submit" color="primary" :loading="loading">Save changes</v-btn>
                  </slot>
                </v-card-actions>
            </v-card>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import formValidation from '@/helper/formValidation';
import PhoneNumber from '@/components/Utilities/PhoneNumber.vue';
import Location from '@/components/Utilities/GooglePlaces.vue';
import CurrencySelect from './PropertyCurrencySelect.vue';
import config from '../../../config';

import CREATE_PROPERTY from '../Mutations/createProperty';
import UPDATE_PROPERTY from '../Mutations/updateProperty';
import CountrySelect from "@/components/Utilities/CountrySelect";

export default {
    name: "PropertyForm",
    components: {
      CountrySelect,
        PhoneNumber, Location, CurrencySelect
    },
    data(){
        return {
            dialog: false,
            mode: 'create',
            loading: false,
            rules: formValidation.rules,
            form: {},
            phone: {
                country_code: '',
                international: '',
                significant: '',
            },
        }
    },
    props: {
      property: Object,
      userId: String,
    },
    computed: {
        useGoogleLocation(){
            return config.google.api_key && config.google.api_key !== '';
        }
    },

    methods: {
        ...mapActions([
            'query',
            'mutate'
        ]),
        ...mapMutations([
            'TOAST_ERROR',
            'SET_ACTIVE_PROPERTY'
        ]),

        submit(){
            if(!this.phone.valid){
                this.TOAST_ERROR({
                    show: true,
                    message: 'Invalid phone number, check again',
                });
                return;
            }

            if(!this.form.full_address){
                this.TOAST_ERROR({
                    show: true,
                    message: 'Please enter the location',
                });
                return;
            }
            
            this.form.phone = this.phone.international;
            this.form.dial_code = this.phone.dialCode;
            this.form.country_code = this.phone.isoCode;
            this.form.phone_number = this.phone.significant;

            if(this.$refs.form.validate()){
                if(this.mode == 'edit') this.updateProperty();
                else this.createProperty();
            }else{
                this.$store.commit('SNACKBAR', {
                    status: true,
                    text: "Some fields are incorrectly filled",
                    color: "red"
                })
            }
        },

        createProperty(){
            this.loading = true;
            this.mutate({
                variables: {
                    user_id: this.userId ? this.userId : undefined,
                    data: this.form
                },
                mutation: CREATE_PROPERTY
            })
            .then(response => {
                const newProperty = response.data.createProperty;
                this.$store.commit("SNACKBAR", {
                    status: true,
                    text: `${newProperty.name} created successfully`,
                    color: "success"
                })
                if(newProperty.is_owner) {
                  this.$store.commit('ADD_USER_PROPERTY', newProperty)
                  this.SET_ACTIVE_PROPERTY(newProperty)
                }
                this.$emit('property-created', newProperty);
            })
            .catch(e => {
              this.$emit('error', e);
            })
            .finally(() => {
                this.loading = false;
            })
        },

        updateProperty(){

            this.loading = true;

            this.mutate({
                variables: {
                    id: this.property.id,
                    data: this.form
                },
                mutation: UPDATE_PROPERTY
            })
            .then(response => {
                const updatedProperty = response.data.updateProperty;
                this.$store.commit('UPDATE_USER_PROPERTY', updatedProperty);
                this.$store.commit("SNACKBAR", {
                    status: true,
                    text: `${updatedProperty.name} updated successfully`,
                    color: "success"
                })

                this.$emit('property-updated', updatedProperty);
            })
            .catch(e => {
                this.$emit('error', e);
            })
            .finally(() => {
                this.loading = false;
            })
        }
    },

    mounted(){
       
    },

    watch: {
        property: {
            immediate: true,
            handler(property){
                if(property){
                    this.mode = 'edit';
                    this.form = {
                      name: property.name,
                      email: property.email,
                      phone: property.phone,
                      country_code: property.country_code,
                      dial_code: property.phone_meta ? property.phone_meta.dial_code: null,
                      phone_number: property.phone_meta ? property.phone_meta.phone_number : null,
                      full_address: property.full_address,
                      street: property.address.street,
                      city: property.address.city,
                      state: property.address.state,
                      country: property.address.country,
                      postal_code: property.address.postal_code,
                      rules: property.rules,
                      terms: property.terms,
                      default_currency: property.default_currency
                    }
                    this.phone = {
                        country_code: property.phone_meta ? property.phone_meta.country_code : null,
                        international: property.phone,
                        significant: property.phone_meta ? property.phone_meta.phone_number : null,
                        valid: true,
                    }
                }else{
                    this.form = {
                        name: null, 
                        email: null, 
                        full_address: null,
                        street: null, 
                        city: null, 
                        state: null, 
                        country: null, 
                        postal_code: null,
                        rules: null,
                        terms: null,
                        default_currency: null
                    }
                }
            }
        },

      country: {
          immediate: true,
          handler(country) {
            if(!country) return;
            this.form.country = country.isoCode;
            this.phone
          }
      }
    }
}
</script>