<template>
    <v-btn v-bind="$attrs.btn" @click="signin" :loading="loading">
      <span><v-icon class="mr-5">mdi-google</v-icon> <slot>Sign in with Google</slot></span>
      <slot name="btn-append" />
    </v-btn>
</template>
<script>
import { fb, auth } from '../../../firebase';
import session from "@/domain/Reservation/Mixins/session";

export default {
    name: "GoogleSignin",
    mixins: [session],
    props: {

    },
    data(){
        return {
           loading: false,
        }
    },
    computed:{
    },
    methods: {
        signin(){
            this.createSessionActivity({
              title: "Authentication Attempt",
              description: "Attempted to authenticate via Google"
            })
          this.loading = true;
          const provider = new fb.auth.GoogleAuthProvider();
            auth.signInWithRedirect(provider)
            .catch((error) => {
                this.$emit('error', error);
            })
            .finally(() => {
                this.loading = false;
            });
        },
    },

    mounted() {
        auth.getRedirectResult()
        .then((result) => {
            if(result.user) this.$emit('authenticated', {
                user: result.user,
                provider: 'google'
            });
        }).catch((error) => {
            this.$emit('error', error);
        })

    }
}
</script>