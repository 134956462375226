var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-row',_vm._l((_vm.files),function(file,i){return _c('v-col',{key:i,staticClass:"pa-0",attrs:{"cols":_vm.displayType === 'grid' ? 12/_vm.grid : 12}},[_c('file-preview',{attrs:{"file":file},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var type = ref.type;
var name = ref.name;
var placeholder = ref.placeholder;
return [_vm._t("file-preview",[(_vm.displayType === 'list')?_c('div',{staticClass:"d-flex justify-space-between align-center my-1 py-1",staticStyle:{"background-color":"#eee","border-radius":"3px"}},[_c('div',{staticClass:"d-flex flex-nowrap text-truncate"},[_c('v-img',{staticClass:"ml-2",attrs:{"src":placeholder,"width":"20px","height":"20px"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(name))])],1),_c('div',{staticClass:"mr-1"},[_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.view(file)}}},[_c('v-icon',{attrs:{"color":"grey","small":""}},[_vm._v("mdi-eye")])],1),(_vm.allowRemove)?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.remove(i)}}},[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-delete")])],1):_vm._e()],1)]):_c('v-img',{attrs:{"src":placeholder,"height":_vm.itemHeight,"gradient":"to bottom, rgba(0,0,0,.5), rgba(0,0,0,.1)"}},[_vm._t("default",[_c('div',{staticClass:"d-flex justify-end pa-2"},[_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.view(file)}}},[_c('v-icon',[_vm._v("mdi-arrow-expand-all")])],1),(_vm.allowRemove)?_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.remove(i)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)],null,{ file: file, type: type, name: name, placeholder: placeholder, index: i, view: _vm.view, remove: _vm.remove })],2)],null,{ file: file, type: type, name: name, placeholder: placeholder, index: i, view: _vm.view, remove: _vm.remove })]}}],null,true)})],1)}),1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","fullscreen":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_vm._t("header",[_c('v-card-title',{staticClass:"d-flex justify-space-between flex-nowrap"},[(_vm.currentPreview)?_c('file-preview',{attrs:{"file":_vm.currentPreview},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var name = ref.name;
var placeholder = ref.placeholder;
return [_c('div',{staticClass:"d-flex align-center flex-nowrap"},[_c('v-img',{staticClass:"ml-2",attrs:{"src":placeholder,"width":"20px","height":"20px"}}),_c('span',{staticClass:"ml-2 text-truncate",style:(("max-width: " + _vm.previewNameWidth + "px"))},[_vm._v(_vm._s(name))]),_c('v-btn',{staticClass:"ml-2",attrs:{"href":_vm.currentPreview,"target":"_blank","color":"primary","download":"download","icon":""}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1)]}}],null,false,2489103692)}):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],null,{ closeDialog: _vm.closeDialog }),_c('v-carousel',{attrs:{"height":"100%"},model:{value:(_vm.carousel),callback:function ($$v) {_vm.carousel=$$v},expression:"carousel"}},_vm._l((_vm.files),function(file,i){return _c('v-carousel-item',{key:("file-" + i)},[_c('file-preview',{attrs:{"file":file}})],1)}),1),_vm._t("footer",null,null,{ remove: _vm.remove })],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }