<template>
    <integration-setup
        :open="open"
        title="Smile"
        :subtitle="`Smile integration for ${ property.name }`"
        :fetch-query="getQuery"
        @fetched="smileFetched"
        @close="open = false"
    >
      <template #header-action>
        <v-switch
            v-model="form.enabled"
            inset
        >
        </v-switch>
      </template>

      <v-alert v-if="error" type="error">
          {{  error.message }}
      </v-alert>
      <v-form ref="form">
          <v-text-field
              outlined dense
              label="Partner ID"
              :rules="[rules.required]"
              type="text"
              v-model="form.partner_id"
              :disabled="!edit"
          ></v-text-field>
          <v-text-field
              outlined dense
              label="API Key"
              :rules="[rules.required]"
              type="text"
              v-model="form.api_key"
              :disabled="!edit"
          ></v-text-field>
          <v-text-field
              outlined dense
              label="SID Server"
              :rules="[rules.required]"
              type="text"
              v-model="form.sid_server"
              :disabled="!edit"
          ></v-text-field>
      </v-form>
      <template #actions>
          <v-btn v-if="edit" color="primary" :loading="saving" @click="save" depressed>Save changes</v-btn>
      </template>
    </integration-setup>
</template>

<script>
import gql from 'graphql-tag';
import formValidation from '@/helper/formValidation';
import IntegrationSetup from "@/domain/Property/Integrations/Setup";


export default {
    name: "SmileGateway",
    components: {IntegrationSetup },
    data(){
        return {
            open: false,
            getQuery: {},
            edit: true,
            saving: false,
            smile: null,
            form: {},
            error: null,
            rules: formValidation.rules,
        }
    },
    props: {
        property: Object,
    },
    methods: {
      smileFetched(response) {
        this.smile = response.getPropertyIntegrations.smile;
        this.setForm();
      },
      setForm() {
        const credentials = this.smile || {};
        this.form = {
          enabled: credentials.enabled,
          partner_id: credentials.partner_id,
          api_key: credentials.api_key,
          sid_server: credentials.sid_server,
        }
      },
      save() {
          if(!this.$refs.form.validate()) return;
          this.saving = true;
          this.$store.dispatch('mutate', {
              mutation: gql`
              mutation updatePropertySmileIntegration($property_id: ID!, $data: PropertySmileIntegrationInput!) {
                  updatePropertySmileIntegration(property_id: $property_id, data: $data) {
                      enabled
                      partner_id
                      api_key
                      sid_server
                  }
              }
              `,
              variables: {
                  property_id: this.property.id,
                  data: this.form
              }
          })
          .then(response => {
              this.error = null;
              this.smile = response.data.updatePropertySmileIntegration;
              if(this.smile) {
                  this.$store.commit("SNACKBAR", {
                      status: true,
                      text: "Smile updated",
                      color: "success"
                  });
                  // this.edit = false;
                  this.$emit('updated');
              }
          })
          .catch(e => {
              this.error = e;
          })
          .finally(() => {
              this.saving = false;
          })
      },
    },

    watch: {
      property: {
        immediate: true,
        handler(property) {
          if(!property) this.getQuery = null;
          this.getQuery = {
            query: gql`
              query getPropertyIntegrations($property_id: ID!){
                getPropertyIntegrations(property_id: $property_id){
                   smile {
                         enabled
                         partner_id
                         api_key
                         sid_server
                     }
                   }
                }`,
            variables: {
              property_id: this.property.id
            }
          }
        }
      }
    }
}
</script>