<template>
    <app-layer ref="app" :breadcrumbs="breadcrumbs">
        <template #header>
          <v-row justify="center">
            <v-col cols="12" md="10">
              <div class="d-flex">
                <h4 class="headline">Properties</h4>
                <v-spacer></v-spacer>
                <v-btn
                    :to="{name: 'property.create'}"
                    class="mx-2"
                    dark elevation="0"
                    color="primary"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                  Create New
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </template>
        <v-container>
            <data-container :loading="loading">
                <template v-slot:loading>
                    <v-row>
                        <v-col cols="12" sm="6" md="4" v-for="i in 6" :key="i">
                            <property-card-skeleton class="ma-2" />
                        </v-col>
                    </v-row>
                </template>

                  <v-row v-if="properties.length">
                    <v-col v-for="property in properties" :key="property.id"
                    cols="12" sm="6" md="4" >
                        <property-card :_property="property">
                            <template #action>
                                <v-icon v-if="property.active" color="success" class="mx-2" title="Subscription active">mdi-check-circle</v-icon>
                                <v-icon v-else color="error"  class="mx-2" title="Subscription not active">mdi-close-circle</v-icon>
                                <property-link :to="{name: 'property.settings', params: {id: property.id}}" class="text-decoration-none">
                                    <v-btn icon color="white"><v-icon>mdi-cog</v-icon></v-btn>
                                </property-link >
                            </template>
                        </property-card>
                    </v-col>
                </v-row>
                  <v-sheet v-else class="pa-2" rounded>
                  <div class="text-center py-16">
                      <p class="grey--text">No property yet</p>
                  </div>
                </v-sheet>
            </data-container>
        </v-container>
    </app-layer>
</template>

<script>

// @ is an alias to /src
import { mapActions, mapGetters, mapMutations } from 'vuex'

import AppLayer from '@/AppLayer.vue';
import PropertyCard from '../Components/PropertyCard.vue';
import DataContainer from '../../../components/DataContainer.vue';
import PropertyCardSkeleton from '../Components/PropertyCardSkeleton.vue';
import PropertyLink from '../Components/PropertyLink.vue';

// graphql
import GET_USER_PROPERTIES from '../Queries/getUserProperties';

export default {
  name: 'PropertyList',
  components: {
    AppLayer, PropertyCard, DataContainer, PropertyCardSkeleton, PropertyLink
  },
  data(){
     return {
         loading: false,
         properties: [],
     }
  },
  computed: {
     ...mapGetters([
        'current_user'
     ]),
    breadcrumbs(){
      return [
        {
          text: "Properties",
          to: this.$route
        }
      ]
    }
  },

  methods:{
      ...mapActions([
          'query',
          'mutate',
      ]),

       ...mapMutations([
          'ADD_USER_PROPERTY'
      ]),

    getProperties(){
        this.loading = true;
        this.query({
            query: GET_USER_PROPERTIES
        })
        .then(response => {
            this.properties = response.data.getUserProperties ? response.data.getUserProperties : []
        })
        .catch(e => {
            this.$refs.app.toastError({
                message: `Couldn't get your properties.`,
                retry: () => this.getProperties(),
                exception: e
            })
        })
        .finally(() => {
            this.loading = false;
        })
      },

      propertyFormSuccess(property){
            this.$refs.app.alert(`New property ${property.name} created successfully`, 'success');
            this.properties.push(property);
            this.ADD_USER_PROPERTY(property);
            this.$refs.propertyFormDialog.close();
      },

      propertyFormError(e){
          
      }
  },
  mounted(){
    //   this.getProperties();
    this.properties = this.current_user.profile.properties 
    ? this.current_user.profile.properties.map(property => property.property)
    : [];
  }
}
</script>
