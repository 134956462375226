<template>
    <div>
        <v-btn v-if="!session"
               @click="createStripeVerificationSession"
               small text
               color="primary"
               :loading="loading"
               :disabled="useConnectedAccount && canUseConnectedAccount">
                <slot>Verify ID</slot>
        </v-btn>
        <stripe-id ref="stripeId" />
        <error-handler :error="error" @retry="createStripeVerificationSession" />
        <v-alert
            v-if="useConnectedAccount && !canUseConnectedAccount"
            class="mt-2"
            border="left"
            colored-border
            type="error">
            {{ property.name }} can not process ID verification at the moment
        </v-alert>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import StripeId from '@/components/Utilities/StripeIdGateway.vue';
import CREATE_STRIPE_VERIFICATION_SESSION from '../Mutations/createUserStripeVerificationSession';
import ErrorHandler from "@/components/ErrorHandler";

export default {
    name: "RunIdentityVerification",
    components: {
      ErrorHandler,
        StripeId
    },
    data(){
        return {
            loading: false,
            session: null,
            verificationOngoing: false,
            error: null,
        }
    },

    props: {
        property: Object,
        redirect: String,
        metadata: Object,
        useConnectedAccount: Boolean
    },

    computed: {

        canUseConnectedAccount() {
            return this.property && this.property.stripe_connected
        }
    },

    methods: {
        ...mapActions([
            'query',
            'mutate',
        ]),


        createStripeVerificationSession(){
            this.loading = true;
            this.error = null;
            this.mutate({
                mutation: CREATE_STRIPE_VERIFICATION_SESSION,
                variables: {
                    property_id: this.property ? this.property.id : undefined,
                    metadata: {
                        user_id: this.$store.getters.current_user.profile.id,
                        property_id: this.property ? this.property.id : undefined,
                        ...this.metadata
                    },
                    return_url: this.redirect ? this.redirect : this.url(this.$router.resolve({name: this.$router.currentRoute.name}).route.fullPath),
                    refresh_url: this.redirect ? this.redirect+'&vs_refresh=1' : this.url(this.$router.resolve({name:  this.$router.currentRoute.name, query: {vs_refresh: 1}}).route.fullPath)
                }
            })
            .then(response => {
                this.session = response.data.createUserStripeVerificationSession;
                this.$emit('created', response.data.createUserStripeVerificationSession);
                this.verificationOngoing = true;
                return this.$refs.stripeId.verify(this.session.client_secret);
            })
            .then(result => {
                this.$emit('result', result);
            })
            .catch(e => {
              this.error = e;
            })
            .finally(() => {
                this.loading = false;
                this.verificationOngoing = false;
            })
        }
    },
}
</script>