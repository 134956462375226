<template>
    <integration-setup
        :open="open"
        title="MessageBird"
        :subtitle="`MessageBird integration for ${ property.name }`"
        :fetch-query="getQuery"
        @fetched="messageBirdFetched"
        @close="open = false"
    >
      <template #header-action>
        <v-switch
            v-model="form.enabled"
            inset
        >
        </v-switch>
      </template>
      <v-alert v-if="error" type="error">
          {{  error.message }}
      </v-alert>
      <v-form ref="form" >
          <v-alert  v-if="account"
          colored-border
          border="left"
          type="success"
          >
            Connected as <strong>{{account.account.name}}</strong>
          </v-alert>
          <h6 class="headline text-h6">Channels</h6>
          <v-checkbox
              v-for="channel in channels" :key="channel"
              v-model="form.channels"
              :value="channel"
              dense
              class="my-0"
          >
            <template #label>
              {{ channel.charAt(0).toUpperCase()+channel.slice(1) }}
            </template>
          </v-checkbox>
      </v-form>
        <template #actions v-if="account" >
            <v-btn color="primary" :loading="saving" @click="updateIntegration" depressed>Save changes</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red" dark :loading="connecting" @click="disconnect" depressed>Disconnect</v-btn>
        </template>
        <template #actions v-else >
            <v-btn color="primary" :loading="connecting" @click="connect" depressed>Connect</v-btn>
        </template>
    </integration-setup>
</template>

<script>
import { mapActions } from 'vuex';
import gql from 'graphql-tag';
import IntegrationSetup from "@/domain/Property/Integrations/Setup";

export default {
    name: "MessageBird",
  components: {IntegrationSetup},
  data() {
        return {
            open: false,
            getQuery: {},
            account: null,
            form: {},
            channels: ['sms', 'whatsapp'],
            saving: false,
            connecting: false,
            error: null,
        }
    },
    props: {
        property: Object,
        credentials: Object
    },

    methods: {
      ...mapActions([
          'mutate'
      ]),

      messageBirdFetched(response) {
          this.account = response.getPropertyIntegrations.message_bird;
          this.setForm();
      },

      setForm() {
        const account = this.account || {};
        this.form = {
          name: this.property.name,
          enabled: account.enabled,
          channels: account.channels
        }
      },

      connect() {
          this.error = null;
          this.connecting = true;
          this.mutate({
              mutation: gql`
              mutation connectPropertyMessageBird($property_id: ID!, $data: PropertyMessageBirdIntegrationInput!) {
                  connectPropertyMessageBird(property_id: $property_id, data: $data) {
                      enabled
                      channels
                      account {
                          id
                          name
                      }
                  }
              }
              `,
              variables: {
                  property_id: this.property.id,
                  data: this.form
              }
          })
          .then(response => {
              this.account = response.data.connectPropertyMessageBird;
              if(this.account) {
                  this.$store.commit("SNACKBAR", {
                      status: true,
                      text: "Message Bird connected",
                      color: "success"
                  });
                  this.$emit('updated', {
                      message_bird: this.account
                  });
              }
          })
          .catch(e => {
              this.error = e;
          })
          .finally(() => {
              this.connecting = false;
          })
      },

      disconnect() {
          this.error = null;
          this.connecting = true;
          this.mutate({
              mutation: gql`
              mutation disconnectPropertyMessageBird($property_id: ID!) {
                  disconnectPropertyMessageBird(property_id: $property_id)
              }
              `,
              variables: {
                  property_id: this.property.id,
              }
          })
          .then(response => {
              if(response.data.disconnectPropertyMessageBird) {
                  this.account = null
                  this.$store.commit("SNACKBAR", {
                      status: true,
                      text: "Message Bird disconnected",
                      color: "success"
                  });
                  this.$emit('updated', {
                      message_bird: null
                  });
              }
          })
          .catch(e => {
              this.error = e;
          })
          .finally(() => {
              this.connecting = false;
          })
      },

      updateIntegration() {
            this.error = null;
            this.saving = true;

            this.mutate({
                mutation: gql`
                mutation updatePropertyMessageBirdIntegration($property_id: ID!, $data: PropertyMessageBirdIntegrationInput!) {
                    updatePropertyMessageBirdIntegration(property_id: $property_id, data: $data) {
                        enabled
                        channels
                    }
                }
                `,
                variables: {
                    property_id: this.property.id,
                    data: this.form
                }
            })
            .then(response => {
                const update = response.data.updatePropertyMessageBirdIntegration
               
                if(update) {
                    Object.assign(this.account, update);
                    this.$store.commit("SNACKBAR", {
                        status: true,
                        text: "Message Bird updated",
                        color: "success"
                    });

                    this.$emit('updated', {
                        message_bird: this.account
                    });
                }
            })
            .catch(e => {
                this.error = e;
            })
            .finally(() => {
                this.saving = false;
            })
        }
    },

  mounted() {
    this.getQuery = {
      query: gql`
            query getPropertyIntegrations($property_id: ID!){
              getPropertyIntegrations(property_id: $property_id){
                   message_bird {
                          enabled
                          channels
                          account {
                              id
                              name
                          }
                     }
                  }
              }`,
      variables: {
        property_id: this.property.id
      }
    }
  },

  watch: {
    property: {
      immediate: true,
      handler(property) {
        if(!property) this.getQuery = null;
        this.getQuery = {
          query: gql`
            query getPropertyIntegrations($property_id: ID!){
              getPropertyIntegrations(property_id: $property_id){
                 message_bird {
                    enabled
                    channels
                    account {
                        id
                        name
                    }
                   }
                 }
              }`,
          variables: {
            property_id: property.id
          }
        }
      }
    }
  }
}
</script>