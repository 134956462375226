<template>
    <div>
        <slot name="heading" />
        <data-container v-if="property && property.active" :loading="loading">
            <template v-slot:loading>
                <div  v-for="i in 4" :key="i">
                    <v-skeleton-loader
                        type="card"
                        height="50"
                        class="my-2"
                    ></v-skeleton-loader>
                </div>
            </template>

            <div class="d-flex justify-end my-3">
                <v-btn color="primary"  title="New agreement" @click="createNew = !createNew"><v-icon dark> mdi-plus</v-icon> Create New</v-btn>
            </div>

            <property-checkin-agreement-form
            v-if="createNew"
            :property="property"
            @created="agreementCreated"
            @cancel="createNew = false"
            class="my-1" />

            <div  v-if="agreements.length" class="mt-5">
                <property-checkin-agreement v-for="agreement in agreements" 
                :key="agreement.id"
                :agreement="agreement"
                :property="property"
                @removed="agreementRemoved"
                class="my-1" />
            </div>
            <div  v-else class="py-5 text-center">
                <p class="grey--text">No agreement</p>
            </div>
            
        </data-container>
    </div>

</template>
<script>
import DataContainer from '../../../components/DataContainer.vue';
import PropertyCheckinAgreement from '../Components/PropertyCheckinAgreement.vue';
import PropertyCheckinAgreementForm from '../Components/PropertyAgreementForm.vue';

import GET_PROPERTY_CHECKIN_AGREEMENTS from '../Queries/getPropertyCheckinAgreements';
import formValidation from '@/helper/formValidation';

export default {
    name: "PropertyCheckinAgreements",
    components: {
        DataContainer, PropertyCheckinAgreement,
        PropertyCheckinAgreementForm
    },

    props: {
        property: Object
    },

    data(){
        return {
            loading: false,
            saving: false,
            rules: formValidation.rules,
            agreements: [],
            collapseAll: false,
            createNew: false,
        }
    },  

    methods: {
        getAgreements(){
            this.loading = true;
             this.$store.dispatch('query', {
                query: GET_PROPERTY_CHECKIN_AGREEMENTS,
                variables: {
                    property_id: this.property.id
                }
            })
            .then(response => {
                this.agreements  = response.data.getPropertyCheckinAgreements;
            })
            .catch(e => {
                this.$store.commit('TOAST_ERROR', {
                    show: true,
                    retry: () => this.getAgreements(),
                    message: 'Could not get property checkin agreements ',
                    exception: e
                })
            })
            .finally(() => {
                this.loading = false;
            })
        },

        agreementCreated(agreement) {
            this.agreements.push(agreement);
            this.createNew = false;
        },

        agreementRemoved(agreement) {
            const index = this.agreements.findIndex(a => a.id === agreement.id);
            if(index >= 0) this.agreements.splice(index, 1);
        }
    },

    watch: {
        property: {
            immediate: true,
            handler(property){
               if(property && property.active) this.getAgreements()
                else this.loading = false;
            }
        }
    }
}
</script>