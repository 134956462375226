<template>
    <data-container :loading="loading">
        <v-form ref="idInfo">
            <v-autocomplete
            dense outlined
            label="Country"
            item-text="label"
            item-value="value"
            :items="countryList"
            v-model="info.country"
            @change="info.id_type = null"
            :rules="[rules.required]"
            >
            </v-autocomplete>
            <template v-if="info.country">
                <v-select
                dense outlined
                label="ID Type"
                item-text="label"
                item-value="value"
                :items="idTypesOption"
                v-model="info.id_type"
                :rules="[rules.required]"
                >
                </v-select>
                <template v-if="info.id_type">
                    <v-text-field
                    dense outlined
                    type="text"
                    :label="`ID number on ${idType.label}`"
                    v-model="info.id_number"
                    :rules="[rules.required, idType.validator]"
                    ></v-text-field>
                    <v-text-field
                    dense outlined
                    type="text"
                    :label="`First Name on ${idType.label}`"
                    v-model="info.first_name"
                    :rules="[rules.required]"
                    >
                    </v-text-field>
                    <v-text-field
                    dense outlined
                    type="text"
                    :label="`Last Name on ${idType.label}`"
                    v-model="info.last_name"
                    :rules="[rules.required]"
                    >
                    </v-text-field>
                    <v-text-field
                    dense outlined
                    type="tel"
                    label="Phone Number"
                    v-model="info.phone_number"
                    :rules="[rules.required]"
                    >
                    </v-text-field>
                    <v-alert v-if="stripe && stripe.status === 'verified'"
                    type="success"
                    colored-border
                    border="left"
                    >
                        Last Stripe verification {{ stripeLastUpdated }}
                    </v-alert>
                    <v-btn color="primary" :loading="submitting" @click="submitInfo" depressed>Save</v-btn>
                </template>
            </template>
        </v-form>
    </data-container>
</template>

<script>
import gql from 'graphql-tag';
import moment from 'moment';
import { mapActions } from 'vuex';
import formValidation from '@/helper/formValidation';
import countryHelper from '@/helper/country';
import DataContainer from '@/components/DataContainer.vue';

export default {
    name: "UserPersonalInfo",
    components: {
        DataContainer
    },
    data() {
        return {
            loading: false,
            submitting: false,
            rules: formValidation.rules,
            capture: false,
            allCountries: countryHelper.countries,
            smileSupportedCountries: [
                {
                    value: 'GH',
                    label: 'Ghana',
                    id_types: [
                        {
                            label: 'Drivers License',
                            value: 'DRIVERS_LICENSE',
                            validator: v => {
                                return /^[a-zA-Z0-9]{6,10}$/i.test(v) || 'Format should be *B0000000'
                            }
                        },
                        // {
                        //     label: 'Passport',
                        //     value: 'PASSPORT',
                        //     validator: v => {
                        //         // return /^(?i)G[a-zA-Z0-9]{7,9}$/i.test(v) || 'Format should be *G0000000'
                        //     }
                        // },
                        {
                            label: 'SSNIT',
                            value: 'SSNIT',
                            validator: v => {
                                return /^[a-zA-Z]{1}[a-zA-Z0-9]{12,14}$/i.test(v) || 'Format should be *C000000000000'
                            }
                        },
                        {
                            label: 'Voter ID',
                            value: 'VOTER_ID',
                            validator: v => {
                                return /^[0-9]{10,12}$/.test(v) || 'Format should be 0000000000' 
                            }
                        },
                        {
                            label: 'New Voter ID',
                            value: 'NEW_VOTER_ID',
                            validator: v => {
                                return /^[0-9]{10,12}$/.test(v) || 'Format should be 0000000000' 
                            }
                        },
                    ]
                },
                {
                    value: 'KE',
                    label: 'Kenya',
                    id_types: [
                        {
                            label: 'Alien Card',
                            value: 'ALIEN_CARD',
                            validator: v => {
                                return /^[0-9]{6,9}$/.test(v) || 'Format should be 000000'
                            }
                        },
                        {
                            label: 'National ID',
                            value: 'NATIONAL_ID',
                            validator: v => {
                                return /^[0-9]{1,9}$/.test(v) || 'Format should be 00000000'
                            }
                        },
                        {
                            label: 'Passport',
                            value: 'PASSPORT',
                            validator: v => {
                                return/^[A-Z0-9]{7,9}$/.test(v) || 'Format should be *A00000000'
                            }
                        },
                    ]
                },
                {
                    value: 'NG',
                    label: 'Nigeria',
                    id_types: [
                        {
                            label: 'BVN',
                            value: 'BVN',
                            validator: v => {
                                return /^[0-9]{11}$/.test(v) || 'Formmat should be 00000000000'
                            }
                        },
                        {
                            label: 'NIN',
                            value: 'NIN',
                            validator: v => {
                                return /^[0-9]{11}$/.test(v) || 'Format should be 00000000000'
                            }
                        },
                        {
                            label: 'NIN Slip',
                            value: 'NIN_SLIP',
                            validator: v => {
                                return /^[0-9]{11}$/.test(v) || 'Format should be 00000000000'
                            }
                        },
                        {
                            label: 'Drivers License',
                            value: 'DRIVERS_LICENSE',
                            validator: v => {
                                return /^[a-zA-Z]{3}([ -]{1})?[A-Z0-9]{6,12}$/i.test(v) || 'Format should be *ABC000000000';
                            }
                        },
                        {
                            label: 'Voter ID',
                            value: 'VOTER_ID',
                            validator: v => {
                                return /^[a-zA-Z0-9 ]{9,19}$/i.test(v) || 'Format should be 0000000000000000000'
                            }
                        },
                       
                    ]
                },
                {
                    value: 'ZA',
                    label: 'South Africa',
                    id_types: [
                        {
                            label: 'National ID',
                            value: 'NATIONAL_ID',
                            validator: v => {
                                return /^[0-9]{13}$/.test(v) || 'Format should be 0000000000000'
                            }
                        },
                        {
                            label: 'National ID, No Photo',
                            value: 'NATIONAL_ID_NO_PHOTO',
                            validator: v => {
                                return /^[0-9]{13}$/.test(v) || 'Format should be 0000000000000'
                            }
                        }
                    ]
                },
                {
                    value: 'UG',
                    label: 'Uganda',
                    id_types: [
                        {
                            label: 'National ID, No Photo',
                            value: 'NATIONAL_ID_NO_PHOTO',
                            validator: v => {
                                return /^[A-Z0-9]{14}$/i.test(v) || 'Format should be 00000000000000'
                            }
                        }
                    ]
                },
            ],
            verification: null,
            info: {
                first_name: null,
                last_name: null,
                country: null,
                id_type: null,
                id_number: null,
                dob: null,
                phone_number: null
            },
        }
    },
    props: {
        property: Object,
        existingVerification: Object,
    },

    computed: {
        countryList() {
            return this.allCountries.map(country => {
                const smileCountry = this.smileSupportedCountries.find(c => c.value === country.code);
                if(smileCountry) return smileCountry;

                return {
                    value: country.code,
                    label: country.name,
                    id_types: [
                        {
                            label: 'National ID',
                            value: 'NATIONAL_ID',
                            validator: v => true
                        },
                        {
                            label: 'Drivers License',
                            value: 'DRIVERS_LICENSE',
                            validator: v => true
                        },
                        {
                            label: 'Passport',
                            value: 'PASSPORT',
                            validator: v => true
                        },
                    ]
                }
            })
        },

        countriesOption() {
            return this.countryList.map(c => ({label: c.label, value: c.value}));
        },

        idTypesOption() {
            if(!this.info.country) return [];
            return this.countryList.find(c => c.value == this.info.country).id_types;
        },

        idType() {
            if(!this.info.id_type) return null;
            return this.idTypesOption.find(type => type.value === this.info.id_type);
        },

        infoEdited() {
            if(!this.verification) return true;
            if(!this.verification.info) return true;

            return this.verification.info.country !== this.info.country || this.verification.info.id_type !== this.info.id_type;
        },

        stripe() {
            if(!this.verification) return null;

            return this.infoEdited ? null : this.verification.stripe;
        },

        smile() {
            if(!this.verification) return null;
            return this.infoEdited ? null : this.verification.smile
        },

        stripeLastUpdated() {
            if(!this.stripe || !this.stripe.last_updated) return null;
            return moment.unix(this.stripe.last_updated).fromNow();
        }
    },

    methods: {
        ...mapActions([
            'query', 'mutate'
        ]),

        submitInfo() {
            if(this.$refs.idInfo.validate()) {
                this.submitting =  true;
                this.setUserIdVerification({ 
                    info: this.info,
                    property_id: this.property ? this.property.id : undefined
                })
                .then(response => {
                    const verification = response.data.setUserIdVerification;
                    if(verification) this.$emit('submitted', verification);
                })
                .finally(() => {
                    this.submitting =  false;
                })
            }
        },

        getUserIdVerification() {
            this.loading = true;
            this.query({
                query: gql `
                query getUserIdVerification($user_id: ID, $property_id: ID) {
                    getUserIdVerification(user_id: $user_id, property_id: $property_id) {
                        property_id
                        info {
                            first_name
                            last_name
                            country
                            id_type
                            id_number
                            dob
                            phone_number
                        }
                        stripe {
                            property_id
                            session
                            report
                            status 
                            url
                            type
                            metadata {
                                user_id
                                property_id
                                reservation_id
                                stripe_account
                            }
                            last_updated
                        }
                        upload {
                            url
                            verified_at
                            verified_by {
                              property {
                                  id
                                  name
                              }
                              user {
                                  id
                                  name {
                                      first_name
                                      last_name
                                  }
                              }
                            }
                        }
                        smile {
                            result_id
                            result_text
                            result_code
                            last_updated
                            result {
                                ResultCode
                                ResultText
                                Actions {
                                    Liveness_Check
                                    Register_Selfie
                                    Selfie_Provided
                                    Verify_ID_Number
                                    Human_Review_Compare
                                    Return_Personal_Info
                                    Selfie_To_ID_Card_Compare
                                    Human_Review_Update_Selfie
                                    Human_Review_Liveness_Check
                                    Selfie_To_ID_Authority_Compare
                                    Update_Registered_Selfie_On_File
                                    Selfie_To_Registered_Selfie_Compare
                                }
                            }
                        }
                    }
                }
                `,
                variables: {
                    user_id: this.$store.getters.current_user.profile.id,
                    property_id: this.property ? this.property.id : undefined
                }
            })
            .then(response => {
                const verification = response.data.getUserIdVerification;

                if(verification) {
                    this.verification = verification;
                    if(verification.info) this.info = verification.info;
                    this.$emit('verification', verification);
                }
            })
            .finally(() => {
                this.loading = false;
            })
        },

        setUserIdVerification(verification) {
            return this.mutate({
                mutation: gql `
                mutation setUserIdVerification($user_id: ID, $property_id: ID, $data: UserIdVerificationInput) {
                    setUserIdVerification(user_id: $user_id, property_id: $property_id, data: $data) {
                        property_id
                        info {
                            first_name
                            last_name
                            country
                            id_type
                            id_number
                            dob
                            phone_number
                        }
                        stripe {
                            property_id
                            session
                            report
                            status 
                            url
                            type
                            metadata {
                                user_id
                                property_id
                                reservation_id
                                stripe_account
                            }
                            last_updated
                        }
                        smile {
                            result_id
                            result_text
                            result_code
                            last_updated
                            result {
                                ResultCode
                                ResultText
                                Actions {
                                    Liveness_Check
                                    Register_Selfie
                                    Selfie_Provided
                                    Verify_ID_Number
                                    Human_Review_Compare
                                    Return_Personal_Info
                                    Selfie_To_ID_Card_Compare
                                    Human_Review_Update_Selfie
                                    Human_Review_Liveness_Check
                                    Selfie_To_ID_Authority_Compare
                                    Update_Registered_Selfie_On_File
                                    Selfie_To_Registered_Selfie_Compare
                                }
                            }
                        }
                    }
                }
                `,
                variables: {
                    user_id: this.$store.getters.current_user.profile.id,
                    property_id: this.property ? this.property.id : undefined,
                    data: verification
                }
            })
        },

    },

    mounted() {
        const verification = this.existingVerification;
        if(verification) {
            this.verification = verification;
            if(verification.info) this.info = verification.info;
        } else {
            this.getUserIdVerification();
        }
    },

    watch: {
        // verification: {
        //     immediate: true,
        //     handler(verification) {
        //         if(verification) this.info = verification.info;
        //     }
        // }
    }

}
</script>