<template>
    <div>
        <slot name="heading" />
          <data-container :loading="loading">
                <template v-slot:loading>
                    <div  v-for="i in 4" :key="i">
                        <v-skeleton-loader
                            type="card"
                            height="50"
                            class="my-2"
                        ></v-skeleton-loader>
                    </div>
                </template>

                <div class="d-flex justify-end my-3">
                    <v-btn color="primary"  title="New Room" @click="createNewRoom = true"><v-icon dark> mdi-plus</v-icon> Create New</v-btn>
                </div>
            
              <v-dialog width="350" v-model="createNewRoom">
                <property-room-form
                    :property="property"
                    @created="roomCreated"
                    @cancel="createNewRoom = false"
                >
                  <template #title>
                    <v-card-title>
                      Add Room
                    </v-card-title>
                  </template>
                </property-room-form>
              </v-dialog>
            
                <template v-if="rooms.length">
                    <property-room
                    v-for="room in rooms" :key="room.id" 
                    :property="property"
                    :room="room"
                    @deleted="roomDeleted"
                    class="my-1" />
                </template>
                <template v-else>
                    <div class="py-5 text-center">
                        <p class="grey--text">No room yet</p>
                    </div>
                </template>

            </data-container>
    </div>

</template>
<script>
import DataContainer from '../../../components/DataContainer.vue';
import PropertyRoomForm from "@/domain/Property/Components/PropertyRoomForm.vue";
import gql from "graphql-tag";
import PropertyRoom from "@/domain/Property/Components/PropertyRoom.vue";

export default {
    name: "PropertyRooms",
    components: {
      PropertyRoom,
      PropertyRoomForm,
      DataContainer
    },

    props: {
        property: Object
    },

    data(){
        return {
            loading: false,
            createNewRoom: false,
            rooms: [],
        }
    },

    methods: {
        getPropertyRooms(){
            this.loading = true;
             this.$store.dispatch('query', {
                query: gql`
                  query getProperty($id: ID!){
                      getProperty(id: $id){
                        rooms {
                            id
                            name
                            description
                            amount
                        }  
                      }
                  }`,
                variables: {
                    id: this.property.id
                }
            })
            .then(response => {
                this.rooms = response.data.getProperty?.rooms || []
            })
            .catch(e => {
                this.$store.commit('TOAST_ERROR', {
                    show: true,
                    retry: () =>  this.getPropertyRooms(),
                    message: 'Could not get property rooms ',
                    exception: e
                })
            })
            .finally(() => {
                this.loading = false;
            })
        },

        roomCreated(room){
            this.rooms.unshift(room);
            this.createNewRoom = false;
        },

       roomDeleted(room) {
            const index = this.rooms.findIndex(r => r.id === room.id);
            if(index >= 0) this.rooms.splice(index, 1);
        }

    },

    watch: {
        property: {
            immediate: true,
            handler(property){
                if(property) this.getPropertyRooms()
                else this.loading = false;
            }
        }
    }
}
</script>