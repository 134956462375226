<template>
    <div>
        <template>
            <div class="">
                <v-dialog
                v-model="upload"
                scrollable
                max-width="500"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                        color="primary"
                        dark
                        v-on="on"
                        small
                        >
                       <slot> Upload ID </slot>
                        </v-btn>
                    </template>

                    <v-card :loading="document.uploading" > 
                        <v-card-title
                        primary-title
                        >
                         Upload ID
                        </v-card-title>
                        <v-card-text style="max-height: 500px;">
                            <v-form ref="upload_identity" >
                                <div class="my-2">
                                    <div id="document-preview"></div>  
                                    <v-row justify="center">
                                        <v-col v-if="document.upload_progress > 0" cols="2">
                                            <v-progress-circular
                                            :rotate="-90"
                                            :size="50"
                                            :width="15"
                                            :value="document.upload_progress"
                                            color="primary"
                                            >
                                            </v-progress-circular>
                                            {{ Math.ceil(document.upload_progress) }}%
                                        </v-col>
                                        <v-col cols="10">
                                            <template>
                                                <v-file-input
                                                v-model="document.file"
                                                color="primary accent-4"
                                                counter
                                                label="File input"
                                                placeholder="Select document"
                                                prepend-icon="mdi-paperclip"
                                                outlined
                                                @change="uploadIdentity"
                                                :rules="[rules.required]"
                                                dense
                                                >
                                                    <template v-slot:selection="{ index, text }">
                                                        <v-chip
                                                        color="primary accent-4"
                                                        dark
                                                        label
                                                        small
                                                        :id="`file-${index}`">
                                                            {{ text }}
                                                        </v-chip>
                                                    </template>
                                                </v-file-input>
                                            </template>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-form>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="red"
                                text
                                @click="upload = false"
                                
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                color="primary"
                                text
                                :disabled="!document.uploaded"
                                :loading="document.uploading"
                                @click="saveID"
                                v-html="`${document.uploading ? 'Uploading ID...' : 'Save ID'}`"
                            >
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </template>
    </div>
</template>

<script>
import fileHelper from '@/helper/file';
import validation from '@/helper/formValidation';
import { mapGetters } from 'vuex';

export default {

    data(){
        return {
            upload: false,
            saving: false,
            document: {
                    file: null,
                    upload_progress: 0,
                    uploaded: false,
                    file_url: null,
                    uploading: false,
                },
            rules: validation.rules,
        }
    },
    computed:{
    },

    props: [],
    methods: {

        uploadIdentity(file){

                this.document.file = file
                fileHelper.previewSelectedImage(this.document.file, '#document-preview')
                const ext = file.name.split('.')[1];
                const saveAs = `private/identity/${this.$store.getters.current_user.auth.uid}.${ext}`;
                const uploadTask = fileHelper.uploadFile(this.document.file, saveAs);
                this.document.uploading = true;
                // Register three observers:
                // 1. 'state_changed' observer, called any time the state changes
                // 2. Error observer, called on failure
                // 3. Completion observer, called on successful completion
                uploadTask.on('state_changed', (snapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    this.document.upload_progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    }, (error) => {
                        this.TOAST_ERROR({
                            show: true,
                            message: `There was error uploading the file`,
                            retry: () =>  this.uploadIdentity(file)
                        });
                        this.document.uploading = false;
                    }, () => {
                    // Handle successful uploads on complete
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                            this.document.uploaded = true;
                            this.document.file_url = downloadURL;
                        });
                     this.document.uploading = false;

                });
        },

        saveID(){
            this.$emit('uploaded', this.document);
            this.upload = false;
        },
    }
}
</script>