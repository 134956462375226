<template>
    <app-layer ref="app" :breadcrumbs="breadcrumbs">
      <template #header>
        <v-row justify="center">
          <v-col cols="12" md="6">
            <div class="d-flex flex-wrap">
              <h4 class="headline">New Reservation</h4>
              <property-switch class="ml-md-5 mt-2 mt-md-0" />
            </div>
          </v-col>
        </v-row>
      </template>
      <v-row justify="center">
          <v-col cols="12" md="6">
              <reservation-form v-if="hasPermission" :property="property" />
              <v-alert v-else type="error" colored-border border="left">
                  You do not have permission to create reservation in {{ property.name }}
              </v-alert>
          </v-col>
      </v-row>
    </app-layer>
</template>

<script>
import AppLayer from '@/AppLayer';
import ReservationForm from '../Components/ReservationForm.vue';
import PropertySwitch from '../../Property/Components/PropertySwitch';


export default {
    name: 'CreateReservation',
    components: {
        AppLayer, ReservationForm, PropertySwitch
    }, 
    data(){
        return {
           
        }
    },

    computed:{
     property(){
          return this.$store.getters.current_user.property
      },
      hasPermission() {
          if(!this.property) return false;
          if(this.property.is_owner) return this.property.is_owner;
          if(!this.property.auth_access) return false;
          if(!this.property.auth_access.permissions) return false;
          return this.property.is_owner || this.property.auth_access.permissions.includes('reservation.create')
      },
      breadcrumbs(){
        return [
          {
            text: "Reservations",
            to: { path: '/' }
          },
          {
            text: "Create New",
            to: this.$route
          }
        ]
      }
    },
    
    methods: {
        
    },
}
</script>
