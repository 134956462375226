<template>
    <div>
        <v-btn v-bind="$attrs.btn" @click="start = true">
          <span><v-icon class="mr-5">mdi-phone</v-icon> <slot>Signin with phone number</slot></span>
          <slot name="btn-append"/>
        </v-btn>
        <v-dialog
        v-model="start" 
        max-width="600"
        persistent
        scrollable
        >
            <v-card :loading="loading">
                <v-card-text class="py-10">
                  <v-row justify="center">
                    <v-col cols="12" md="8">
                      <div class="text-center mb-10">
                        <h1 class="black--text">Sign in with Phone</h1>
                        <p class="grey--text mt-2" v-if="!verificationSent">Please provide your phone number below to continue.</p>
                      </div>
                      <template v-if="!verificationSent">
                        <phone-number v-model="phone" dense outlined />
                        <div id="recaptcha-container"></div>
                      </template>
                      <template v-else>
                        <div class="d-flex justify-center">
                          <v-icon color="primary">mdi-information-outline</v-icon>
                          <p class="ml-2">
                            We sent a one time validation code to {{ phone.international }}. Enter the 6 digit code below before it expires
                          </p>
                        </div>

                        <div class="my-5">
                          <v-form ref="confirmVerification" >
                            <v-text-field
                                dense
                                outlined
                                v-model="verificationCode"
                                label="Verification code"
                                :rules="[rules.required]"
                                hide-details="auto" ></v-text-field>
                          </v-form>
                        </div>
                        <div class="text-center">
                          <a href="#" @click.prevent="resendVerificationCode" class="text-decoration-none">Resend/change number</a>
                        </div>
                      </template>
                      <div class="mt-8">
                        <v-btn color="red" text @click="cancel">Cancel</v-btn>
                        <v-btn
                            v-if="!verificationSent"
                            color="primary"
                            class="ml-5"
                            @click="sendVerificationCode"
                            :loading="loading"
                            depressed
                        >Continue</v-btn>
                        <v-btn
                            v-else
                            color="primary"
                            class="ml-5"
                            @click="confirmVerificationCode"
                            :loading="loading"
                            depressed
                        >Verify</v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import {fb, auth} from '../../../firebase';
import validation from '@/helper/formValidation';
import PhoneNumber from '@/components/Utilities/PhoneNumber';
import session from "@/domain/Reservation/Mixins/session";

export default {
    name: "PhoneSignin",
    mixins: [session],
    components: {
        PhoneNumber
    },
    data(){
        return {
            start: false,
            loading: false,
            rules: validation.rules,
            phone: {},
            verificationSent: false,
            verificationCode: '',
            reCaptchaSolved: false,
            reCaptchaRendered: false,
            appVerifier: null,
        }
    },

    methods: {
        sendVerificationCode(){
            if(!this.phone.valid){
                this.$emit('error', {
                    message: 'Invalid phone',
                })
                return;
            }
            this.loading = true;
            this.createSessionActivity({
              title: "Authentication Attempt",
              description: `Attempted to authenticate via phone OTP using phone number: ${this.phone.international}`
            })
            auth.signInWithPhoneNumber(this.phone.international, this.getReCaptcha())
            .then(confirmationResult => {
                window.confirmationResult = confirmationResult;
                this.verificationSent = true;
                this.createSessionActivity({
                  title: "Verification code Sent",
                  description: `Phone number verification code was sent to ${this.phone.international}`
                })
            })
            .catch(e => {
              this.createSessionActivity({
                title: "Failed Phone verification",
                description: `Phone number verification failed, could not send verification code. Error: ${e.message}`
              })
              this.$emit('error', e)
            })
            .finally(() => {
                this.loading = false;
            })
        },

        resendVerificationCode(){
            window.confirmationResult = null;
            window.recaptchaVerifier = null;
            this.verificationSent = false;
            this.createSessionActivity({
              title: "Verification code Requested For Again",
              description: `User required verification be sent again`
            })
        },

        confirmVerificationCode(){
            if(!this.$refs.confirmVerification.validate()) {
                this.$emit('error', {
                    message: 'Input not filled correctly',
                });
                return;
            }
            this.loading = true;
            this.createSessionActivity({
              title: "Verify Phone Number",
              description: `Attempted to verify phone number with verification code ${this.verificationCode}`
            })
            window.confirmationResult.confirm(this.verificationCode)
            .then(result => {
              this.createSessionActivity({
                title: "Successful Phone Verification",
                description: `Phone number ${this.phone.international} was successfully verified with code ${this.verificationCode}`
              })
              this.$emit('authenticated', { user: result.user, provider: 'phone' });
            })
            .catch( e => {
                this.createSessionActivity({
                  title: "Failed Phone Verification",
                  description: `Verification of ${this.phone.international} failed. Error: ${e.code} - ${e.message}`
                })
                if(e.code == 'auth/credential-already-in-use'){
                    this.$emit('error', {
                        message: 'Phone number already in use by another user',
                    })
                }else{
                    this.$emit('error', {
                        message: e.message,
                    })
                }
            })
            .finally(() => {
                this.loading = false;
            })
        },

        getReCaptcha() {
            const container = document.createElement('div');
            document.body.append(container);
            if(container) {
                container.innerHTML = '';
                window.recaptchaVerifier = new fb.auth.RecaptchaVerifier(container, {
                    'size': 'invisible',
                    'callback': (response) => {
                        this.reCaptchaSolved = true;
                    },
                    'expired-callback': () => {
                        this.$emit('error', {
                            message: 'reCaptcha expired',
                        });
                    }
                });
                return window.recaptchaVerifier;
            }
            return null;
            
        },

        cancel() {
          window.confirmationResult = null;
          window.recaptchaVerifier = null;
          this.verificationSent = false;
          this.loading = false;
          this.phone = {};
          this.start = false;
        }
    },

    mounted() {
        
    },

}
</script>