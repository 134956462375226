<template>
    <app-layer ref="app" :breadcrumbs="breadcrumbs">
      <template #header>
        <v-row justify="center">
          <v-col cols="12" md="10">
            <div class="d-md-flex justify-md-space-between">
              <h4 class="headline">My Reservations</h4>
              <div>
                <div class="d-flex justify-md-end align-content-center">
                  <v-checkbox
                      v-for="archive in archives" :key="archive.value"
                      v-model="includes"
                      :value="archive.value"
                      multiple dense
                      :label="archive.label"
                      class="my-0 mx-2"
                  />
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </template>
        <v-row justify="center">
            <v-col cols="12" md="10">
<!--              <responsive-tabs v-if="hasAnyProperty"-->
<!--                :value="currentTab"-->
<!--                @change="tabChanged"-->
<!--              >-->
<!--                <template #tabs>-->
<!--                  <v-tab v-for="tab in tabs" :key="tab.name">-->
<!--                    <v-icon>{{tab.icon}}</v-icon> {{ tab.name }}-->
<!--                  </v-tab>-->
<!--                </template>-->
<!--                <template #items>-->
<!--                  <v-tab-item v-for="tab in tabs" :key="tab.name" class="pa-3" >-->
<!--                    <personal-reservations v-if="tab.route.params.reservation === 'personal'" />-->
<!--                    <property-reservations v-if="tab.route.params.reservation === 'property'" />-->
<!--                  </v-tab-item>-->
<!--                </template>-->
<!--              </responsive-tabs>-->

                <personal-reservations :includes="includes">
                  <template #no-reservations>
                    <v-sheet rounded class="py-8">
                      <p class="grey--text text-center">No reservation</p>
                    </v-sheet>
                  </template>
                </personal-reservations>
            </v-col>
        </v-row>
    </app-layer>
</template>

<script>
import AppLayer from '@/AppLayer';
// import PropertySwitch from '../../Property/Components/PropertySwitch';
// import ResponsiveTabs from "@/components/ResponsiveTabs";
// import PropertyReservations from '../Widgets/PropertyReservations.vue';
import PersonalReservations from '../Widgets/UserReservations.vue';

export default {
  name: 'Reservations',
  components: {
      AppLayer, PersonalReservations,
      // PropertyReservations, ResponsiveTabs, PropertySwitch,
  }, 
  data(){
      return {
        vertical: false,
        currentTab: 0,
        includes: [],
      }
  },

    computed:{
      hasAnyProperty(){
          return this.$store.getters.current_user.profile.properties && this.$store.getters.current_user.profile.properties.length
      },

      property() {
        return this.$store.getters.current_user.property
      },

      tabs() {
          const tabs = [];
          if(this.hasAnyProperty) {
            tabs.push({
              name: 'Property',
              route: {name: 'reservation.list', params: {reservation: 'property'}},
              icon: 'mdi-domain'
            })
          }
          tabs.push({
            name: 'My Trips',
            route: {name: 'reservation.list', params: {reservation: 'personal'}},
            icon: 'mdi-account'
          })
        return tabs;
      },

      breadcrumbs(){
        return [
          {
            text: "My Reservations",
            to: this.$route
          }
        ]
      },
      archives() {
        return [
          { value: 'checkedout', label: "checked out" },
          { value: 'cancelled', label: "cancelled" }
        ]
      }
    },

    methods:{

      tabChanged(tab){
        this.currentTab = tab;
        this.$router.push(this.tabs[tab].route)
      },

      propertySwitched(){
        this.currentTab = this.tabs.findIndex(t => t.route.params.reservation === 'property');
      }
    },
    watch: {
      $route: {
        immediate: true,
        handler(route) {
          if(route.query.includes) {
            this.includes = Array.isArray(route.query.includes) ? route.query.includes : [route.query.includes]
          }
        }
      },
  }
}
</script>
