<template>
    <div>
        <template v-if="runNewVerification">
            <smile-id @computed-images="smileComputedImages" />
            <div class="my-3">
                <v-alert
                v-if="submission"
                colored-border
                border="left"
                type="success"
                >
                    Verification has been submitted
                </v-alert>
                <v-alert
                v-if="submitting"
                colored-border
                border="left"
                type="info"
                >
                    Submitting for verification...
                </v-alert>
                <v-alert
                v-if="error"
                colored-border
                border="left"
                type="error"
                >
                    {{ error }}
                </v-alert>
            </div>
        </template>

        <template v-else-if="result">
            <smile-verification-result :result="result" flat />
            <v-btn small color="primary" @click="runNewVerification = true" class="mt-3">Redo Verification</v-btn>
        </template>

    </div>
</template>

<script>
import gql from 'graphql-tag';
import SmileId from '@/components/Utilities/SmileID.vue';
import SmileVerificationResult from './SmileVerificationResult.vue';

export default {
    name: "SmileVerification",
    components: {
        SmileId, SmileVerificationResult
    },
    data() {
        return {
            submission: null,
            error: null,
            submitting: false,
            images: null,
            runNewVerification: true,
        }
    },
    props: {
        reservation: Object,
        property: Object,
        result: Object,
        idInfo: Object
    },

    methods: {
       
        smileComputedImages(images) {
            this.images = images;
            this.submitSmileID();
        },

        submitSmileID(){
            this.submitting = true;
            this.submission = null;
            this.$store.dispatch('mutate', {
                mutation: gql `
                    mutation submitSmileVerification($property_id: ID!, $image_data: SmileVerificationInput!, $id_info: SmileIDInfoInput!, $metadata: SmileVerificationMetadataInput) {
                        submitSmileVerification(property_id: $property_id, image_data: $image_data, id_info: $id_info, metadata: $metadata) {
                            job_complete
                            job_success
                            result {
                                ResultCode
                                ResultText
                                Actions {
                                    Liveness_Check
                                    Register_Selfie
                                    Selfie_Provided
                                    Verify_ID_Number
                                    Human_Review_Compare
                                    Return_Personal_Info
                                    Selfie_To_ID_Card_Compare
                                    Human_Review_Update_Selfie
                                    Human_Review_Liveness_Check
                                    Selfie_To_ID_Authority_Compare
                                    Update_Registered_Selfie_On_File
                                    Selfie_To_Registered_Selfie_Compare
                                }
                            }
                        }
                    }
                `,
                variables: {
                    property_id: this.property.id,
                    image_data: this.images,
                    id_info: this.idInfo,
                    metadata: {
                        property_id: this.property.id,
                        reservation_id: this.reservation.id,
                        user_id: this.$store.getters.current_user.profile.id
                    },
                }
            })
            .then(response => {
                console.log(response);

                this.submission = response.data.submitSmileVerification;
                this.$emit('submitted',  this.submission);
                
            })
            .catch(e => {
                this.error = `Could not complete verification. ${e.message}`
            })
            .finally(() => {
                this.submitting = false
            })
        }
    },

    watch: {
        result: {
            immediate: true,
            handler(result){
                if(result) this.runNewVerification = false;
            }
        }
    }
}
</script>