var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.tableHeaders,"items":_vm.verifications,"search":_vm.search},scopedSlots:_vm._u([{key:"item.reference",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("nullable")(item.reference))+" ")]}},{key:"item.user.full_name",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{name: 'dashboard.user.show', params: {id: item.user.id}}}},[_c('v-avatar',{staticClass:"mr-1",attrs:{"size":"30"}},[_c('img',{attrs:{"alt":item.user.full_name,"src":item.user.image}})]),_vm._v(" "+_vm._s(item.user.full_name)+" ")],1):_vm._e()]}},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentFromTimestamp(item.timestamp).format('MMM D, YYYY'))+" ")]}},{key:"item.provider",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("nullable")(item.provider))+" ")]}},{key:"item.document_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("nullable")(_vm._f("ucfirstword")(_vm._f("replace_underscore")(item.document_type))))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-chip',{attrs:{"small":"","color":_vm.statusColor[item.status.toLowerCase()] ? _vm.statusColor[item.status.toLowerCase()] : 'warning',"dark":"","outlined":""}},[_vm._v(" "+_vm._s(_vm._f("ucfirstword")(item.status))+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("nullable")(item.status)))])]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }