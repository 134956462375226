var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.tableHeaders,"items":_vm.data,"search":_vm.search},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"mr-1",attrs:{"size":"40"}},[_c('img',{attrs:{"alt":item.name,"src":item.image}})]),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.contact",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_c('div',[_vm._v(_vm._s(item.phone))]),_c('div',[_vm._v(_vm._s(item.email))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusColor[item.status.toLowerCase()],"dark":"","small":"","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.timestamp.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentFromTimestamp(item.timestamp.created_at).format('MMM D, YYYY'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('properties-table-admin-table-options',{attrs:{"property":item}})]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }