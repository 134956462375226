<template>
    <v-select
        class="mx-2 text--darken-4"
        :items="propertiesSwitch"
        item-text="name"
        item-value="id"
        :item-disabled="property => !(property.is_owner || (property.auth_access && property.auth_access.active))"
        dense
        :value="current_user.property.id"
        @change="switchProperty"
        outlined
        label="select property"
        background-color="white"
        style="max-width: 350px"
    >
      <template #selection="{ item: property, index, select, parent }">
        <v-chip v-if="parent.multiple" pill class="ma-1" close @click:close="parent.value.splice(index, 1)">
          <v-avatar left>
            <v-img :src="property.image"></v-img>
          </v-avatar>
          {{ property.name }}
        </v-chip>
        <template v-else>
          <v-avatar size="30">
            <v-img :src="property.image"></v-img>
          </v-avatar>
          <span class="ml-2">{{ property.name }}</span>
        </template>
      </template>
      <template #item="{ item: property, on }">
        <v-list-item v-on="on">
          <v-list-item-avatar>
            <v-img :src="property.image"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{property.name}}</v-list-item-title>
            <v-list-item-subtitle>{{ property.full_address }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
    name: "PropertySwitch",
    computed: {
        ...mapGetters([
            'current_user',
            'profile_loaded'
        ]),
        
        propertiesSwitch(){
          return (this.profile_loaded && this.current_user.profile.properties ?
                this.current_user.profile.properties : []).map(property => (property.property));
                
        }
    },
    methods: {
    ...mapMutations([
      'SET_ACTIVE_PROPERTY'
    ]),

    switchProperty(property){
      const activeProperty = this.current_user.profile.properties.find(p => p.id == property);
      this.SET_ACTIVE_PROPERTY(activeProperty.property);
      this.$emit('change', activeProperty.property);
      this.$eventHub.$emit('property-switched', activeProperty.property);
    }

  },
}
</script>