<template>
  <div>
    <data-container :loading="loading" :error="error"/>
    <div v-show="!loading">
        <template v-if="!isAuthenticated">

            <slot name="before-authentication" />

            <div class="d-flex flex-wrap align-center justify-center">

                <google-signin v-if="authProviders.includes('google') && providers.includes('google')"
                  class="ma-2"
                 :btn="{
                    color: 'white',
                    depressed: true,
                  }"
                @authenticated="authenticated"
                @error="authError">
                    <slot name="google-btn">Continue with Google</slot>
                </google-signin>

                <email-signin v-if="authProviders.includes('email') && providers.includes('email')"
                class="ma-2"
                :btn="{
                    color: 'white',
                    depressed: true,
                  }"
                :redirect-path="continueTo"
                @authenticated="authenticated"
                @error="authError">
                    <slot name="email-btn">Continue with Email</slot>
                </email-signin>

                <phone-signin v-if="authProviders.includes('phone') && providers.includes('phone')"
                  class="ma-2"
                  :btn="{
                    color: 'white',
                    depressed: true,
                  }"
                  @authenticated="authenticated"
                  @error="authError">
                    <slot name="phone-btn">Continue with Phone</slot>
                </phone-signin>

            </div>
            
        </template>
        <template v-else-if="!isProfileSet">
            <slot name="before-profile-set" />

            <personal-info-form
              :key="profileComponentKey"
              :form="profileComponentForm"
              @update="f => {
                profileComponentForm = f;
                profileComponentKey++
              }"
              @saved="profileCompleted"
          >
            <template #actions="{ loading, submitting, submit, canSubmit }">
              <v-card-actions class="mt-5">
                <v-spacer></v-spacer>
                <v-btn
                    :loading="submitting"
                    :disabled="loading || !canSubmit"
                    color="primary"
                    @click="submit()"
                >
                  Continue
                </v-btn>
              </v-card-actions>
            </template>
          </personal-info-form>
            
<!--            <v-form ref="userProfile">-->
<!--                <div class="my-3">-->
<!--                    <v-text-field outlined dense v-model="profile.first_name" label="First name" :rules="[rules.required]" hide-details="auto"></v-text-field>-->
<!--                </div>-->
<!--                <div class="my-3">  -->
<!--                    <v-text-field outlined dense v-model="profile.last_name" label="Last name" :rules="[rules.required]" hide-details="auto"></v-text-field>-->
<!--                </div> -->
<!--                <div class="my-5">-->
<!--                    <v-btn-->
<!--                        text-->
<!--                        dark color="accent-4"-->
<!--                        class="primary"-->
<!--                        @click="saveProfile"-->
<!--                        id="sign-in-button"-->
<!--                        :loading="creating_profile"-->
<!--                    >-->
<!--                        Continue-->
<!--                    </v-btn>-->
<!--                </div>-->
<!--            </v-form>-->
        </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import authState from '../../../plugins/auth';

import GoogleSignin from '../Components/GoogleSignin.vue';
import EmailSignin from '../Components/EmailSignin.vue';
import PhoneSignin from '../Components/PhoneSignin.vue';

import profileMixin from '../../User/Mixins/profile2';
import formvalidation from '@/helper/formValidation'
import appHelper from '@/helper/app'
import PersonalInfoForm from "@/domain/User/Components/PersonalInfoForm";
import DataContainer from "@/components/DataContainer";
import session from "@/domain/Reservation/Mixins/session";

export default {
    name: 'MiniAuth',
    components: {
      PersonalInfoForm,
        GoogleSignin, EmailSignin, PhoneSignin, DataContainer
    },
    mixins: [profileMixin, session],
    props: {
        continueTo: String,
        providers: {
            type: Array,
            default: () => ['google', 'email', 'phone']
        }
    },

    computed: {
        authProviders() {
          return appHelper.getAuthProviders()
        }
    },

    data() {
        return {
            loading: false,
            isAuthenticated: false,
            isProfileSet: false,
            rules: formvalidation.rules,
            error: null,
            profileComponentKey: 1,
            profileComponentForm: null
        }
    },
    
    methods: {
        ...mapActions([
            'getIdToken',
            'getAuthUser',
        ]),
        ...mapMutations([
            'SET_USER_AUTH'
        ]),

        authenticated(result) {

            if(this.continueTo) {
                this.$router.replace(this.continueTo);
            }

            this.setAuthentication(result);
        },

        setAuthentication(result) {
            this.isAuthenticated = true;
            this.loading = true;
            this.getIdToken()
            .then(user =>  {
            if(user){
                    this.SET_USER_AUTH(result.user);
                    return this.getAuthUser();
                }
                return Promise.resolve(null)
            })
            .then(user => {
                if(user) {
                    this.isProfileSet = true;
                    this.$emit('completed', true);
                } else {
                  this.createSessionActivity({
                    title: `Profile Completion Required`,
                    description: `Profile did not exist yet, profile completion was required`
                  })
                }
            })
            .catch(e => {
                this.error = e.message;
            })
            .finally(() => {
                this.loading = false;
            })
        },

        authError(error) {
            this.$store.commit('SNACKBAR', {
                status: true,
                text: error.message, 
                color: 'error',
            })
        },

        profileCompleted() {
            this.createSessionActivity({
              title: `Profile Completed`,
              description: `Guest completed profile`
            })
            this.isProfileSet = true;
            this.$emit("completed", true);
        }
    },

    mounted() {
        this.loading = true;
        authState().then(user => {
            if(user) {
                this.isAuthenticated = true;
                this.setAuthentication({ user })
            } else {
                this.loading = false;
                this.$emit('completed', false);
            }
        })
    }
}
</script>