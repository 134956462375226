<template>
    <app-layer ref="app">
        <data-container :loading="loading">
            <template v-slot:loading>
                <property-skeleton />
            </template>
            <v-container v-if="!property || !invite">
                <div class="text-center">
                    <h1 class="red--text">Invalid invite URL</h1>
                </div>
            </v-container>

            <template v-else>
                <v-row
                align="center"
                justify="center"
                >
                    <v-col cols="12" md="6">
                        <v-sheet class="pa-3" rounded>
                            <div class="text-center">
                                <v-avatar color="primary" size="150">
                                    <v-img
                                    :src="property.image"
                                    ></v-img>

                                </v-avatar>
                                <div class="d-flex justify-center align-center">
                                    <h1>{{ property.name }}</h1>
                                </div>
                                <p v-if="property.full_address">{{ property.full_address }}</p>
                            </div>
                            <div v-if="invite">
                                <template v-if="invite.email === current_user.profile.email">
                                    <template v-if="invite.confirmed_at && !confirming">
                                        <v-alert type="success" colored-border border="left">
                                            Invitation confirmed
                                        </v-alert>
                                        <v-btn :to="{name: 'property.list'}" color="primary">Go to properties</v-btn>
                                    </template>
                                    <template v-else>
                                        <p class="text-center">Invitation to team up on the property as <strong>{{ invite.role }}</strong></p>
                                        <h4>Permissions granted</h4>
                                        <v-list v-if="grantedPermissions.length" dense three-line>
                                        <template v-for="(permission, index) in grantedPermissions">
                                            <v-list-item :key="permission.id">
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ permission.label }}</v-list-item-title>
                                                        <v-list-item-subtitle>{{ permission.description }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider :key="index" v-if="index < (grantedPermissions.length - 1)"></v-divider>
                                            </template>
                                        </v-list>
                                        <div v-else>
                                            <p>No permission granted</p>
                                        </div>
                                        <v-btn color="primary" @click="confirmInvite" :loading="confirming">Accept Invitation</v-btn>
                                    </template>

                                </template>
                                <template v-else>
                                    <v-alert type="info">
                                        This invitation is meant for <strong>{{ invite.email }}</strong>. If this email address belongs to you, please sign in with it.
                                    </v-alert>
                                    <!-- <div class="text-center">
                                        <v-btn color="primary">Signin as {{ invite.email }}</v-btn>
                                    </div> -->
                                </template>
                            </div>
                            <div v-else>
                                <v-alert type="error">
                                    Invalid invite
                                </v-alert>
                            </div>
                        </v-sheet>
                    </v-col>
                </v-row>

            </template>
        </data-container>
    </app-layer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AppLayer from '@/AppLayer';
import DataContainer from '../../../components/DataContainer.vue';
import PropertySkeleton from '../Components/PropertySkeleton.vue';

import GET_PROPERTY from '../Queries/getProperty';
import GET_PROPERTY_USER_INVITE  from '../Queries/getPropertyUserInvite';
import CONFIRM_PROPERTY_USER_INVITE from '../Mutations/confirmPropertyUserInvite';
import GET_PROPERTY_PERMISSIONS from '../Queries/getPropertyPermissions';

export default {
    name: 'PropertyUserInvite',
    components: {
        AppLayer, DataContainer, PropertySkeleton
    }, 
    data(){
        return {
            loading: false,
            confirming: false,
            property: null,
            invite: null,
            permissions: null,
        }
    },

    computed:{
        ...mapGetters([
            'current_user'
        ]),
        propertyId() {
            return this.$route.params.propertyId;
        },
        inviteId() {
            return this.$route.params.inviteId;
        }, 
        grantedPermissions() {
            if(!this.invite || !this.permissions) return []
            const allPermissions = [].concat(this.permissions.property, this.permissions.reservation);
            return this.invite.permissions.map(permission => allPermissions.find(p => p.id === permission));
        }
    },

    mounted(){
        this.getInvite();
    },
    
    methods:{
        ...mapActions([
            'query',
            'mutate',
        ]),

        getInvite() {
            this.loading = true;
            this.query({
                query: GET_PROPERTY,
                variables: {
                    id: this.propertyId
                }
            })
            .then(response => {
                this.property = response.data.getProperty;
                return  this.query({
                    query: GET_PROPERTY_USER_INVITE,
                    variables: {
                        property_id: this.propertyId,
                        invite_id: this.inviteId,
                    }
                });
            })
            .then(response => {
                this.invite = response.data.getPropertyUserInvite;
                 return  this.query({
                    query: GET_PROPERTY_PERMISSIONS,
                });
            })
            .then(response => {
                this.permissions = response.data.getPropertyPermissions;
            })
            .catch(e => {
                this.$refs.app.toastError({
                    message: `Could not get invite.`,
                    retry: () => this.getInvite(),
                    exception: e
                });
            })
            .finally(() => {
                this.loading = false;
            })
        },

        confirmInvite() {
            this.confirming = true;
            this.mutate({
                mutation: CONFIRM_PROPERTY_USER_INVITE,
                variables: {
                    property_id: this.propertyId,
                    invite_id: this.inviteId
                }
            })
            .then(response => {
                this.invite = response.data.confirmPropertyUserInvite;
                return this.$store.dispatch('syncAuthUser');
            })
            .then(() => {
                this.$store.commit("SNACKBAR", {
                    status: true,
                    text: "Property invitation confirmed.",
                    color: "success"
                });
            })
            .catch(e => {
                this.$store.commit('TOAST_ERROR', {
                    show: true,
                    retry: () => this.addUser(),
                    message: 'Could not confirm invite.',
                    exception: e
                });
            })
            .finally(() => {
                this.confirming = false;
            })
        }
    },
}
</script>
