import gql from 'graphql-tag';

export default gql`
    mutation createReservationGuest ($reservation_id: ID!, $data: ReservationGuestInput!) {
        createReservationGuest(reservation_id: $reservation_id, data: $data) {
            id
            name
            gender
            type
            user_id
            can_verify_id
            verification{
                info {
                    first_name
                    last_name
                    country
                    id_type
                    id_number
                    dob
                    phone_number
                }
                stripe {
                    type
                    status
                    session
                    report
                    metadata {
                        user_id
                        property_id
                        reservation_id
                    }
                }
                upload {
                    url
                    verified_at
                    verified_by {
                        property {
                            id
                            name
                        }
                        user {
                            id
                            name {
                                first_name
                                last_name
                            }
                        }
                    }
                }
                smile {
                    result_id
                    result_text
                    result_code
                    last_updated
                    result {
                        ResultCode
                        ResultText
                        Actions {
                            Liveness_Check
                            Register_Selfie
                            Selfie_Provided
                            Verify_ID_Number
                            Human_Review_Compare
                            Return_Personal_Info
                            Selfie_To_ID_Card_Compare
                            Human_Review_Update_Selfie
                            Human_Review_Liveness_Check
                            Selfie_To_ID_Authority_Compare
                            Update_Registered_Selfie_On_File
                            Selfie_To_Registered_Selfie_Compare
                        }
                    }
                }
            }
        }
    }`;