var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('reservation-page',{scopedSlots:_vm._u([{key:"checkedin",fn:function(ref){
var reservation = ref.reservation;
var property = ref.property;
return [_c('reservation-checkedin-page',{attrs:{"reservation":reservation,"property":property}},[_c('h2',{staticClass:"headline my-5"},[_vm._v("Damages")]),_c('reservation-damages',{attrs:{"reservation":reservation},scopedSlots:_vm._u([{key:"damage-actions",fn:function(ref){
var damage = ref.damage;
var damageUpdated = ref.damageUpdated;
return [(!damage.dispute)?_c('reservation-damage-dispute',{attrs:{"reservation":reservation,"damage":damage},on:{"damage-disputed":function (dmg) { return damageUpdated(dmg); }}}):_vm._e(),(damage.amount > 0 && !damage.payment)?_c('reservation-damage-payment',{attrs:{"reservation":reservation,"property":property,"damage":damage,"payment-provider":reservation.setting.payment_gateway},on:{"damage-payment":function (dmg) { return damageUpdated(dmg); }}}):_vm._e()]}},{key:"footer-actions",fn:function(ref){
var createDamageReport = ref.createDamageReport;
return [_c('v-card-actions',{staticClass:"mt-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","small":"","depressed":""},on:{"click":createDamageReport}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" New Damage Report ")],1)],1)]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }