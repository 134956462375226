var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.tableHeaders,"items":_vm.users,"search":_vm.search},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{name: 'dashboard.user.show', params:{ id: item.id }}}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"mr-1",attrs:{"size":"20"}},[_c('img',{attrs:{"alt":item.name,"src":item.image}})]),_vm._v(" "+_vm._s(item.full_name)+" ")]}},{key:"item.contact",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("nullable")(item.email))+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("nullable")((item.properties.length ? 'Host' : 'Guest')))+" ")]}},{key:"item.property",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.properties.length)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusColor[item.status.toLowerCase()],"dark":"","small":"","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.timestamp.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentFromTimestamp(item.timestamp.created_at).format('MMM D, YYYY'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('users-table-admin-options',{attrs:{"user":item}})]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("nullable")(item.roles.map(function (role) { return _vm.$options.filters.ucfirst(role); }).join(', ')))+" ")]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }