<template>
  <v-card :disabled="!active" flat>
    <v-img
      :src="property.image"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      height="150px"
    >
      <v-card-title>
        <div>
          <router-link :to="{name: 'property.show', params: {id: property.id, _property: property}}" class="text-decoration-none white--text">
            {{ property.name }}
          </router-link>
        </div>
        <v-spacer></v-spacer>
        <slot name="action" />
      </v-card-title>
    </v-img>

    <v-card-subtitle class="d-flex justify-space-between align-center">
      <div>
        <v-icon>mdi-map-marker</v-icon>  {{ property.full_address }}
        <div v-if="role" class="grey--text"><v-icon>mdi-account</v-icon> {{ role }}</div>
      </div>
      <div v-if="!active" class="red--text">Inactive</div>
    </v-card-subtitle>

  </v-card>
</template>

<script>
    export default {
        name: "PropertyCard",
        data(){
            return {
                property: {}
            }
        },
        props: {
            _property: Object
        },
        computed: {
          role() {
            if(this._property.is_owner) return 'Owner';
            if(this._property.auth_access) return this._property.auth_access.role;
            return null;
          },

          active() {
            if(this._property.is_owner) return true;
            return this._property.auth_access && this._property.auth_access.active;
          }
        },
       
        watch: {
            _property: {
                immediate: true,
                handler(property){
                    this.property = property;
                }
            }
        }
    }
</script>

