var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-container',{attrs:{"loading":_vm.loading,"error":_vm.error}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[(!_vm.isAuthenticated)?[_vm._t("before-authentication"),_c('div',{staticClass:"d-flex flex-wrap align-center justify-center"},[(_vm.authProviders.includes('google') && _vm.providers.includes('google'))?_c('google-signin',{staticClass:"ma-2",attrs:{"btn":{
                    color: 'white',
                    depressed: true,
                  }},on:{"authenticated":_vm.authenticated,"error":_vm.authError}},[_vm._t("google-btn",[_vm._v("Continue with Google")])],2):_vm._e(),(_vm.authProviders.includes('email') && _vm.providers.includes('email'))?_c('email-signin',{staticClass:"ma-2",attrs:{"btn":{
                    color: 'white',
                    depressed: true,
                  },"redirect-path":_vm.continueTo},on:{"authenticated":_vm.authenticated,"error":_vm.authError}},[_vm._t("email-btn",[_vm._v("Continue with Email")])],2):_vm._e(),(_vm.authProviders.includes('phone') && _vm.providers.includes('phone'))?_c('phone-signin',{staticClass:"ma-2",attrs:{"btn":{
                    color: 'white',
                    depressed: true,
                  }},on:{"authenticated":_vm.authenticated,"error":_vm.authError}},[_vm._t("phone-btn",[_vm._v("Continue with Phone")])],2):_vm._e()],1)]:(!_vm.isProfileSet)?[_vm._t("before-profile-set"),_c('personal-info-form',{key:_vm.profileComponentKey,attrs:{"form":_vm.profileComponentForm},on:{"update":function (f) {
                _vm.profileComponentForm = f;
                _vm.profileComponentKey++
              },"saved":_vm.profileCompleted},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
              var loading = ref.loading;
              var submitting = ref.submitting;
              var submit = ref.submit;
              var canSubmit = ref.canSubmit;
return [_c('v-card-actions',{staticClass:"mt-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"loading":submitting,"disabled":loading || !canSubmit,"color":"primary"},on:{"click":function($event){return submit()}}},[_vm._v(" Continue ")])],1)]}}])})]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }