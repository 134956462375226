<template>
  <v-card>
    <v-card-title class="flex-nowrap">
      <span class="text-truncate"><v-icon>mdi-bell</v-icon> {{ notification.title }}</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
    </v-card-title>
    <v-card-text>
      <data-container :loading="loading" :error="error" @retry="getNotification">
        <template v-if="notificationData">
          <h4 class="headline">{{ notificationData.subject }}</h4>
          <div class="mt-3" v-html="notificationData.body"></div>
        </template>
      </data-container>
    </v-card-text>
    <v-card-actions>
      <v-btn
          :to="{ name: 'reservation.show', params: { id: metadata.reservation_id }, query: trackQuery }"
          class="mx-1"
          color="primary"
          text
      >
        Go To Reservation
      </v-btn>
      <reservation-damage-payment
      v-if="damage && damage.amount > 0 && !damage.payment"
      :property="property"
      :reservation="reservation"
      :damage="damage"
      :payment-provider="reservation.setting.payment_gateway"
      @damage-payment="damagePaymentMade"
      >
        <template #default="{ on }">
          <v-btn color="primary" depressed v-on="on">
            Pay {{ damage.amount | money(reservation.currency) }}
          </v-btn>
        </template>
      </reservation-damage-payment>
    </v-card-actions>
  </v-card>
</template>

<script>
  import gql from 'graphql-tag';
  import DataContainer from "@/components/DataContainer.vue";
  import ReservationDamagePayment from "@/domain/Reservation/Widgets/ReservationDamagePayment.vue";

    export default {
        name: "UserDamageChargeNotification",
      components: {ReservationDamagePayment, DataContainer},
        data(){
            return {
              error: null,
              dialog: false,
              loading: false,
              damage: null,
              notificationData: null
            }
        },
        props: {
            notification: Object,
        },
        computed: {
            metadata() {
              return this.convertMetaKeyValueToObj(this.notification.metadata || [])
            },
            trackQuery() {
              return {
                from: 'notifications',
                notification: this.notification.type,
                ...this.metadata
              }
            },
            reservation() {
              return this.notificationData?.reservation
            },
            property() {
              return this.reservation?.property?.property
            }
        },
        methods: {
          queryNotification(markAsRead = true) {
            return new Promise((resolve, reject) => {
              this.$store.dispatch('query', {
                query: gql`
                query getUserNotification($id: ID!, $mark_as_read: Boolean){
                    getUserNotification(id: $id, mark_as_read: $mark_as_read){
                        id
                        type
                        title
                        subject
                        body
                        text
                        time
                        timestamp
                        read
                        read_at
                        reservation {
                          id
                          user_id
                          property_id
                          currency
                          user {
                            email
                          }
                          damages {
                            id
                            title
                            description
                            amount
                            payment {
                              currency
                              amount
                              gateway
                              reference
                              payment_data
                            }
                          }
                          property {
                            id
                            property {
                              id
                              name
                              integrations
                              stripe_connected
                            }
                          }
                          setting {
                              payment_gateway
                          }
                        }
                        metadata {
                            key
                            value
                        }
                    }
                }`,
                variables: {
                  id: this.notification.id,
                  mark_as_read: markAsRead
                }
              }).then(response => {
                const notification = response.data.getUserNotification
                    ? response.data.getUserNotification
                    : this.notification;
                this.$emit('update-notification', notification);
                return resolve(notification)
              }).catch(e => reject(e))
            })
          },

          getNotification(){
            this.error = null;
            this.loading = true;
            this.notificationData = null;
            this.queryNotification(false).then(notification => {
              this.notificationData = notification;
              this.damage = this.metadata.damage_id
                  ? (notification.reservation?.damages || []).find(damage => damage.id === this.metadata.damage_id)
                  : null
            })
            .catch(e => {
              this.error = new Error("Notification could not be fetched");
            })
            .finally(() => {
              this.loading = false
            })
          },

          damagePaymentMade(damage) {
            Object.assign(this.damage, damage);
            this.$emit('mark-as-read', this.notification);
            this.$emit('close');
          }

        },
        mounted() {
          this.getNotification();
        },
      }
</script>