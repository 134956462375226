<template>
    <v-dialog
    v-model="verify"
    width="300"
    >
        <template v-slot:activator="{ on }">
            <v-btn
                v-on="on"
                v-bind="$attrs.btn || {}"
            >
              <slot>Verify ID</slot>
            </v-btn>
        </template>

        <v-card :loading="verifying">
            <v-card-title class="headline">Verify uploaded ID</v-card-title>
            <v-card-text>
                <div class="my-3">
                    <p>Is the ID and the information valid ? </p>
                </div>
                <v-alert
                v-if="error"
                colored-border
                border="left"
                color="error"
                class="my-3"
                >
                    Verification failed. {{ error.message }}
                </v-alert>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text
                    @click="verify = false">
                    Cancel
                </v-btn>
                <v-btn
                    color="primary"                        
                    @click="verifyID"
                    :loading="verifying"
                >
                    Yes
                </v-btn>
            </v-card-actions>
        </v-card>

    </v-dialog>
</template>

<script>
import gql from 'graphql-tag';
import { mapActions } from 'vuex';
export default {

    data(){
        return {
            verify: false,
            verifying: false,
            error: null
        }
    },
    computed:{
        upload() {
            return this.verification && this.verification.upload ? this.verification.upload : null
        }
    },

    props: {
        user: Object,
        property: Object,
        reservation: Object,
        verification: Object
    },

    methods: {
        ...mapActions(['mutate']),

        verifyID(){
            this.error = null;
            this.verifying = true;
            this.mutate({
                mutation: gql `
                mutation verifyUserIdUpload($user_id: ID!, $property_id: ID, $reservation_id: ID, $global: Boolean) {
                    verifyUserIdUpload(user_id: $user_id, property_id: $property_id, reservation_id: $reservation_id, global: $global) {
                        upload {
                            verified_by {
                                property {
                                  id
                                  name
                              }
                              user {
                                  id
                                  name {
                                      first_name
                                      last_name
                                  }
                              }
                            }
                            verified_at
                        }
                    }
                }
                `,
                variables: {
                    user_id: this.user.id,
                    property_id: this.property ? this.property.id : null,
                    reservation_id: this.reservation ? this.reservation.id : null,
                    global: this.verification.property_id ? false : true,
                }
            })
            .then(response => {
                const id =  response.data.verifyUserIdUpload;
                if(id && id.upload) {
                    this.$store.commit('SNACKBAR',{
                      status: true,
                      text: "ID verified",
                      color: 'success'
                    })
                    this.$emit('verified', id.upload)
                } else {
                    this.$store.commit('SNACKBAR',{
                      status: true,
                      text: "ID verification failed",
                      color: 'error'
                    })
                }
              this.verify = false;
            })
            .catch((e) => {
                this.error = e
            })
            .finally(() => {
                this.verifying = false;
            })

        }
    }
}

</script>