var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('reservation-page',{scopedSlots:_vm._u([{key:"checkedin",fn:function(ref){
var reservation = ref.reservation;
var property = ref.property;
return [_c('reservation-checkedin-page',{attrs:{"reservation":reservation,"property":property}},[_c('h2',{staticClass:"headline my-5"},[_vm._v("Documents")]),_c('reservation-document-requests',{attrs:{"reservation":reservation},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var request = ref.request;
var requestUpdated = ref.requestUpdated;
return [_c('reservation-document-request-submission',{attrs:{"property":property,"reservation":reservation,"request":request},on:{"document-request-updated":function (req) { return requestUpdated(req); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":""}},on),[_vm._v("Submit Document")])]}}],null,true)})]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }