<template>
    <integration-setup
        :open="open"
        title="Paystack"
        :subtitle="`Paystack integration for ${ property.name }`"
        :fetch-query="getQuery"
        @fetched="paystackFetched"
        @close="open = false"
    >
      <template #header-action>
        <v-switch
            v-model="form.enabled"
            inset
        >
        </v-switch>
      </template>
        <v-alert v-if="error" type="error">
            {{  error.message }}
        </v-alert>
        <v-form ref="form">
            <v-text-field
                outlined dense
                label="Public key"
                :rules="[rules.required]"
                type="text"
                v-model="form.public_key"
                :disabled="!edit"
            ></v-text-field>
            <v-text-field
                outlined dense
                label="Secret key"
                :rules="[rules.required]"
                type="text"
                v-model="form.secret_key"
                :disabled="!edit"
            ></v-text-field>
        </v-form>
        <template v-if="edit"  #actions>
            <v-btn color="primary" :loading="saving" @click="save" depressed>Save changes</v-btn>
        </template>
    </integration-setup>
</template>

<script>
import gql from 'graphql-tag';
import formValidation from '@/helper/formValidation';
import IntegrationSetup from "@/domain/Property/Integrations/Setup";


export default {
    name: "PaystackGateway",
    components: { IntegrationSetup },
    data(){
        return {
            open: false,
            edit: true,
            saving: false,
            paystack: null,
            form: {},
            error: null,
            rules: formValidation.rules,
            getQuery: {}
        }
    },
    props: {
        property: Object,
    },
    methods: {
      paystackFetched(response) {
        this.paystack = response.getPropertyIntegrations.paystack;
        this.setForm();
      },

      setForm() {
        const credentials = this.paystack || {};
        this.form = {
          enabled: credentials.enabled,
          public_key: credentials.public_key,
          secret_key: credentials.secret_key
        }
      },
      save() {
            if(!this.$refs.form.validate()) return;
            this.saving = true;
            this.$store.dispatch('mutate', {
                mutation: gql`
                mutation updatePropertyPaystackIntegration($property_id: ID!, $data: PropertyPaystackIntegrationInput!) {
                    updatePropertyPaystackIntegration(property_id: $property_id, data: $data) {
                        enabled
                        public_key
                        secret_key
                    }
                }
                `,
                variables: {
                    property_id: this.property.id,
                    data: this.form
                }
            })
            .then(response => {
                this.error = null;
                this.paystack = response.data.updatePropertyPaystackIntegration;
                if(this.paystack) {
                    this.$store.commit("SNACKBAR", {
                        status: true,
                        text: "Paystack updated",
                        color: "success"
                    });
                    // this.edit = false;
                    this.$emit('updated');
                }
            })
            .catch(e => {
                this.error = e;
            })
            .finally(() => {
                this.saving = false;
            })
        },
    },
    watch: {
      property: {
        immediate: true,
        handler(property) {
          if(!property) this.getQuery = null;
          this.getQuery = {
            query: gql`
            query getPropertyIntegrations($property_id: ID!){
              getPropertyIntegrations(property_id: $property_id){
                  paystack {
                         enabled
                         public_key
                         secret_key
                     }
                  }
              }`,
            variables: {
              property_id: this.property.id
            }
          }
        }
      }
    }
}
</script>