var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('reservation-page',{scopedSlots:_vm._u([{key:"checkedin",fn:function(ref){
var reservation = ref.reservation;
var property = ref.property;
return [_c('reservation-checkedin-page',{attrs:{"reservation":reservation,"property":property},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var checkin = ref.checkin;
var getReservationCheckin = ref.getReservationCheckin;
return [_c('h2',{staticClass:"headline my-5"},[_vm._v("Charges")]),_c('reservation-charges',{attrs:{"reservation":reservation,"property":property,"_payments":checkin.payments}}),_c('reservation-payment-requests',{staticClass:"mx-n6",attrs:{"reservation":reservation},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var request = ref.request;
var requestUpdated = ref.requestUpdated;
return [(!request.payment)?_c('reservation-payment-request-payment',{attrs:{"reservation":reservation,"property":property,"request":request,"payment-provider":reservation.setting.payment_gateway},on:{"payment-request-payment":function (req) { return requestUpdated(req); }}}):_vm._e()]}}],null,true)}),_c('h2',{staticClass:"headline my-5"},[_vm._v("All Payments")]),_c('reservation-payments',{staticClass:"mt-3",attrs:{"outlined":"","property":property,"reservation":checkin.reservation,"payments":checkin.payments,"header":false}}),_c('h2',{staticClass:"headline"},[_vm._v("Credit Card")]),(checkin.checkin.credit_card)?[(checkin.checkin.credit_card.stripe)?[(checkin.checkin.credit_card.stripe.card)?_c('stripe-credit-card',{attrs:{"card":checkin.checkin.credit_card.stripe.card}}):_vm._e(),(checkin.checkin.credit_card.stripe.payment_method)?_c('stripe-payment-method',{attrs:{"method":checkin.checkin.credit_card.stripe.payment_method}}):_vm._e()]:_vm._e(),(checkin.checkin.credit_card.paystack)?[(checkin.checkin.credit_card.paystack)?_c('paystack-credit-card',{attrs:{"card":checkin.checkin.credit_card.paystack}}):_vm._e()]:_vm._e()]:[_c('p',{staticClass:"text-center grey--text mt-5"},[_vm._v("No credit card")])]]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }