<template>
  <app-layer  ref="app">
    <template #notice>
      <property-low-checkin-point-notice />
    </template>
    <template #header>
      <v-row justify="center">
        <v-col cols="12" md="10">
          <div class="d-flex flex-wrap">
            <h4 class="headline">Reservations</h4>
            <div class="d-flex flex-grow-1 ml-md-5 mt-2 mt-md-0" >
              <property-switch />
              <v-btn
                  :disabled="!$store.getters.current_user.property.active"
                  class="mx-2"
                  color="primary"
                  :to="{ name: 'reservation.create' }"
                  elevation="0"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
                <span class="d-none d-md-inline"> Create Reservation</span>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </template>
    <v-row justify="center">
      <v-col cols="12" md="10" class="py-0">
        <property-reservations  />
      </v-col>
    </v-row>
  </app-layer>
</template>

<script>
import { mapGetters } from 'vuex';

import AppLayer from '@/AppLayer.vue';
import ReservationCard  from '@/domain/Reservation/Components/Reservation';
import PropertyCard from '@/domain/Property/Components/PropertyCard';
import PropertyLink from '@/domain/Property/Components/PropertyLink';
import PropertyReservations from "@/domain/Reservation/Widgets/PropertyReservations";
import PropertySwitch from "@/domain/Property/Components/PropertySwitch";
import DashboardHome from "@/domain/Dashboard/Views/Home";
import PropertyLowCheckinPointNotice from "@/domain/Property/Components/LowCheckinPointNotice.vue";

export default {
  name: 'HostHome',
  components: {
    PropertyLowCheckinPointNotice,
    PropertySwitch,
    PropertyReservations,
    AppLayer
  },
  computed: {
    ...mapGetters([
      'current_user'
    ])
  }
}
</script>
