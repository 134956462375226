<template>          
    <integration-setup
        :open="open"
        title="Stripe"
        :subtitle="`Stripe for ${ property.name }`"
        :fetch-query="getQuery"
        @fetched="stripeFetched"
        @close="open = false"
    >
      <error-handler :error="error" :can-retry="false" />
      <template v-if="auth">
          <div class="text-center">
              <div v-if="account" class="">
                  <p class="grey--text">Stripe connected as</p>
                  <h2 class="headline"> {{ account.name }} </h2>
                  <a v-if="account.url" :href="account.url" class="text-decoration-none" target="_blank">{{ account.url }}</a>
              </div>
              <v-alert
                  v-else
                  border="left"
                  colored-border
                  type="success">
                  Stripe connected
              </v-alert>
          </div>
         <v-switch
              label="Always use 3D secure"
              inset dense
              v-model="setting.payment.always_use_3d_secure"
          >
          </v-switch>
      </template>
      <template v-else>
            <div class="text-center">
              <v-alert
                  border="left"
                  colored-border
                  type="error">
                  Stripe not connected
              </v-alert>
              <div class="text-center my-5">
                  <v-btn
                      dark
                      color="primary"
                      :loading="connecting"
                      @click="connect" depressed
                      >
                      Connect Stripe
                  </v-btn>
              </div>
            </div>
          </template>

      <template v-if="auth" #actions>
        <v-btn @click="save" color="primary" :loading="saving" depressed>Save changes</v-btn>
        <v-spacer></v-spacer>
        <v-btn
            v-if="auth"
            dark
            color="error"
            :loading="disconnecting"
            @click="disconnect" depressed
        >
          <v-icon class="mr-2">mdi-cancel</v-icon> Disconnect
        </v-btn>
      </template>
    </integration-setup>
</template>

<script>
import gql from 'graphql-tag';
import config from '../../../config';

import UNSET_PROPERTY_STRIPE_AUTHORIZATION from '../Mutations/unsetPropertyStripeAuthorization';
import IntegrationSetup from "@/domain/Property/Integrations/Setup";
import ErrorHandler from "@/components/ErrorHandler.vue";

export default {
    name: "PropertyStripeGateway",
    components: {
      ErrorHandler,
      IntegrationSetup
    },
    props: {
        property: Object,
    },
    data(){
        return {
            open: false,
            getQuery: {},
            auth: null,
            setting: {
                payment: {
                    always_use_3d_secure: false,
                }
            },
            saving: false,
            error: null,
            connecting: false,
            disconnecting: false,
            disconnected: false,
        }
    }, 

    computed: {
        connected(){
            return this.auth && this.auth.stripe_user_id ? true : false
        },
        account() {
            return this.auth && this.auth.account && this.auth.account.business_profile
                ? this.auth.account.business_profile : null
        }
    },

    methods: {
      stripeFetched(response) {
        this.auth = response.getPropertyIntegrations.stripe;
        this.setForm();
      },

      setForm() {
        this.setting.payment.always_use_3d_secure = this.auth?.setting?.payment?.always_use_3d_secure || false
      },

      connect(){
          this.connecting = true;
          const clientId = config.stripe.client_id;
          const return_uri = this.url(this.$router.resolve({name: 'property.stripe.connect.callback'}).route.fullPath);
          window.localStorage.setItem('property-stripe-connect', this.property.id)
          window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write&redirect_uri=${return_uri}`;
      },

      disconnect(){
          this.disconnecting = true;
          this.$store.dispatch('mutate', {
              mutation: UNSET_PROPERTY_STRIPE_AUTHORIZATION,
              variables: {
                  property_id: this.property.id,
              }
          })
          .then(response => {
              this.disconnected = true;
              this.auth = null;
              this.$emit('updated');
          })
          .catch(e => {
              this.$store.commit('TOAST_ERROR', {
                  show: true,
                  message: `Disconnection failed. `,
                  retry: () => this.disconnect(),
                  exception: e
              })
          })
          .finally(() => {
              this.disconnecting = false;
          })
      },

      refresh() {
          this.$store.commit('SET_APP_STATE', false);
          this.$store.commit('SET_APP_PROCESS', 'Hold on...');
          setTimeout(()=> {
              window.location.reload();
          }, 5000);
      },

      save() {
          this.saving = true;
          this.error = null;
          this.$store.dispatch('mutate', {
              mutation: gql`
              mutation updatePropertyStripeAuthorizationSetting($property_id: ID!, $data: StripeAuthorizationSettingInput!) {
                  updatePropertyStripeAuthorizationSetting(property_id: $property_id, data: $data) {
                      payment {
                          always_use_3d_secure
                      }
                  }
              }
              `,
              variables: {
                  property_id: this.property.id,
                  data: this.setting
              }
          })
          .then(response => {
              const setting = response.data.updatePropertyStripeAuthorizationSetting;
              if(setting) {
                  this.setting = setting;
                  this.$store.commit("SNACKBAR", {
                      status: true,
                      text: "Stripe updated",
                      color: "success"
                  });
              }
          })
          .catch(e => {
              this.error = e;
          })
          .finally(() => {
              this.saving = false;
          })
      },
    },

    mounted() {
      if(this.$route.params.refresh) {
        this.$emit('updated');
      }
    },
  watch: {
      property: {
        immediate: true,
        handler(property) {
          if(!property) this.getQuery = null;
          this.getQuery = {
            query: gql`
          query getPropertyIntegrations($property_id: ID!){
            getPropertyIntegrations(property_id: $property_id){
                stripe {
                    access_token
                    scope
                    livemode
                    token_type
                    refresh_token
                    stripe_user_id
                    stripe_publishable_key
                    account {
                        business_profile {
                            name
                            url
                        }
                    }
                    setting {
                        payment {
                            always_use_3d_secure
                        }
                    }
               }
            }
         }`,
            variables: {
              property_id: this.property.id
            }
          }
        }
      }
  }
}
</script>