var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.preview)?_c('property-charge-list',{attrs:{"charges":_vm.selected},scopedSlots:_vm._u([{key:"list-item-action",fn:function(charge){return [(charge.type === 'pre-authorize' && _vm.stayDuration > 7)?_c('v-icon',_vm._g({attrs:{"color":"warning"}},_vm.on),[_vm._v(" mdi-alert ")]):_vm._e(),_vm._t("list-item-action",null,null,{ charge: charge })]}},{key:"list-item-content",fn:function(ref){
var charge = ref.charge;
return [_vm._t("list-item-content",null,null,{ charge: charge })]}}],null,true)}):_vm._e(),_vm._t("after-selected",null,null,{ selected: _vm.selected }),(_vm.changeable)?_c('div',[_c('v-autocomplete',_vm._g(_vm._b({attrs:{"loading":_vm.loading,"items":_vm.filterdItems,"item-text":"title","no-data-text":("" + (_vm.loading ? 'Please wait...' : ("" + (_vm.allowedCurrency ? ("No charge in " + _vm.allowedCurrency) : "Select a currency first"))))},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"disabled":!item.enable || ( item.type === 'pre-authorize' && _vm.disallowPreauthorized)}},on),[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":_vm.selected.map(function (s) { return s.id; }).includes(item.id)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.title)+" - "+_vm._s(item.type)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.optional),expression:"item.optional"}]},[_vm._v(" (optional) ")])]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.description))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.getUnit(item)))]),_vm._t("dropdown-list-item-content",null,null,{ item: item })],2),_c('h4',{staticClass:"mt-2"},[_vm._v(_vm._s(item.currency)+_vm._s(item.amount))])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},'v-autocomplete',_vm.$attrs,false),_vm.$listeners)),(_vm.createNew)?_c('div',{staticClass:"mt-n5 d-flex justify-end"},[_c('v-dialog',{attrs:{"max-width":"550","scrollable":"","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","title":"create new charge","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add New Charge ")],1)]}}],null,false,170695249),model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_c('h4',[_vm._v("New Charge")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.createDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('property-charge-form',{staticClass:"mt-2",attrs:{"property":_vm.property,"prefill":{
                        type: 'instant',
                        enable: true,
                        currency: _vm.allowedCurrency ? _vm.allowedCurrency : _vm.property.default_currency,
                        },"read-only":_vm.newChargeReadOnly},on:{"created":_vm.chargeCreated,"cancel":function($event){_vm.createDialog = false}}})],1)],1)],1)],1):_vm._e()],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }